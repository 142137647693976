<template>
<div>
  <address-checkmark v-if="order.is_verified_address" ></address-checkmark>
  <div class="address-verified-warning" v-else>
    <pg-button color="warning" @click="validateAnAddress">Verify Address</pg-button>
  </div>
  <vs-popup
    title="Adddress Suggestions"
    style="z-index: 9999999"
    :active.sync="showAddressSuggestions"
  >
    <div class="checkbox-cards">
      <address-checkbox-card title="Original Address" original :address="address_suggestions.original_address"></address-checkbox-card>
      <address-checkbox-card title="Matched Address" :submit-address="submitAddress" :matched="!!address_suggestions.matched_address" :address="address_suggestions.matched_address"></address-checkbox-card>
      <address-checkbox-card :title="`Candidate Address ${key +1}`" v-for="(address, key) in address_suggestions.candidate_addresses" :key="key" :submit-address="submitAddress" :address="address"></address-checkbox-card>
    </div>
  </vs-popup>
</div>
</template>

<script>
import AddressCheckmark from "./partials/address-checkmark"
import AddressCheckboxCard from "./partials/address-checkbox-card"
export default {
  name: "address-validation",
  props:{
    order:Object,
    orderSelectData: {
      type: Function,
      default: () => {},
    },
  },
  components:{
    AddressCheckmark,
    AddressCheckboxCard,
  },
  data(){
    return {
      showAddressSuggestions:false,
      address_suggestions: {
        original_address:null,
        matched_address:null,
        candidate_addresses:[]
      }
    }
  },
  methods:{

    submitAddress(address){

      let orderData = {
        customer_name : address.name,
        address1 : address.address_line1,
        address2 : address.address_line2,
        address3 : address.address_line3,
        city : address.city,
        state : address.state_province,
        zipcode : address.postal_code,
        country : address.country_code,
        is_residential : address.residential_indicator === 'yes',
        is_verified_address:true
      }


      this.$store
        .dispatch("orders/updateOrder", {
          orderId: this.order.id,
          formData: orderData,
        })
        .then(() => {
          this.$swal({
            icon: "success",
            title: "Order Address Validation",
            text: "Succesfully Updated",
          }).then(() => {
            this.showAddressSuggestions = false;
            this.orderSelectData();
          });
        });

    },
    validateAnAddress() {
      this.$spinner.fingerprint()
      this.$store
        .dispatch("orders/validateAnAddress", { ids: [this.order.id] })
        .then((response) => {
          this.address_suggestions = response.data.results[0]
          this.showAddressSuggestions = true
          this.$spinner.close()
        })
        .catch((error) => {
          this.$swal({
            icon: "error",
            title: "Validate an address request",
            text: "error",
          }).then(()=>{
            this.$spinner.close()
          })
        });
    },
  }
}
</script>

<style lang="scss" scoped>
.checkbox-cards{
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 15px;
}
.address-verified-warning{
  display: flex;
  justify-content: center;
  padding: 15px;
  text-align: center;
  color: #eb9316;
}

.button-container{
  display: flex;
  justify-content: center;
  margin-top: auto;
  margin-bottom: 10px;
}
</style>
