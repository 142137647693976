import axios from "@/axios";
import requestsHelper from "@/services/helpers/requestsHelper";

export default {

  getInventoryTypeByTenant(ignore, tenant) {
    return new Promise((resolve, reject) => {
      axios
        .post("/item_inventory/adjustments/get_inventory_type/", {
          tenant:tenant
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  addItem(ignore, item) {
    return new Promise((resolve, reject) => {
      axios
        .post("/item_inventory/inventories/", item)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  fetchItems({ commit }, options) {
    return new Promise((resolve, reject) => {

      let helpers = new requestsHelper(options);
      let queryset = helpers.createQueryset();

      axios
        .get("/item_inventory/inventories/" + queryset)
        .then(response => {
          commit("SET_ITEMS", response.data.results);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  fetchInventoryHistory({ commit }, options) {
    return new Promise((resolve, reject) => {
      let helpers = new requestsHelper(options);
      let queryset = helpers.createQueryset();

      axios
        .get("/item_inventory/inventories/get_item_inventory_history/" + queryset)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  fetchAdjustmentLines(ignore, options) {
    return new Promise((resolve, reject) => {

      let helpers = new requestsHelper(options);
      let queryset = helpers.createQueryset();

      axios
        .get("/item_inventory/adjustment_lines/" + queryset)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  fetchSearchItems({ commit }, options) {
    return new Promise((resolve, reject) => {

      let helpers = new requestsHelper(options);
      let queryset = helpers.createQueryset();

      axios
        .get("/item_inventory/inventory/" + queryset)
        .then(response => {
          commit("SET_ITEMS", response.data.results);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  fetchSelectData({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get("/item_inventory/inventories/" + id + "/")
        .then(response => {
          commit("SET_SELECT_DATA", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  updateItem(ignore, item) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/item_inventory/inventories/` + item.id + "/", item)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  removeItem(ignore, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/item_inventory/inventories/` + id + "/")
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }


};
