<template>
  <span class="filter-label">{{label | title }}</span>
</template>

<script>
export default {
    
    props:{
        label:{
            required:false,
            type:String
        }
    }

}
</script>

<style lang="scss">
    .filter-label{
        display: inherit;
        color: #6E6B7B;
        font-weight: 500;
        line-height: 35px;
        margin-bottom: 0;
    }
</style>