import Vue from 'vue'
import Vuex from 'vuex'

/* globally vuex structures */
import state from "./state"
import getters from "./getters"
import mutations from "./mutations"
import actions from "./actions"

Vue.use(Vuex)

/* vuex modules */
import auth from '../views/auth/store'
import websocket from './websocket'
import warehouse from '../views/warehouse/store'
import items from '../views/items/store'
import vendor from '../views/vendor/store'
import marketplace from '../views/marketplace/store'
import packgroup from '../views/packgroup/store'
import shippingCompanies from '../views/shippingCompanies/store'
import shippingAccount from '../views/shippingAccounts/store'
import profile from '../views/profile/store'
import orders from '../views/orders/store'
import adjustment from '../views/adjustment/store'
import packages from '../views/packages/store'
import purchase from '../views/purchase/store'
import inventory from '../views/inventory/store'
import dashboard from "../views/dashboard/store"
import users from "../views/user/store"
import integrations from "../views/integrations/store"
import printer from "../views/printer/store"
import jobs from "../views/jobs/store"
import schedule from "../views/schedule/store"

export default new Vuex.Store({
    strict: false,
    namespaced: true,
    getters,
    mutations,
    state,
    actions,
    modules: {
      auth,
      websocket,
      warehouse,
      items,
      vendor,
      marketplace,
      packgroup,
      shippingCompanies,
      shippingAccount,
      profile,
      orders,
      adjustment,
      packages,
      purchase,
      inventory,
      dashboard,
      users,
      integrations,
      printer,
      jobs,
      schedule
    }
})
