<template>
  <div class="pg-right-bar-container-mask" v-if="show"  :style="styles">
    <div class="pg-right-bar-container" @click.stop >
      <div class="pg-right-bar-header">
        <span>{{title}}</span>
        <svg @click.stop="close" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 512 512"><title>Close</title><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M368 368L144 144M368 144L144 368"/></svg>
      </div>
      <div class="pg-right-bar-body">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "pg-right-bar",
  props:{
    show:{
      type:Boolean,
      default:false
    },
    title:{
      type:String,
      default:"Sidebar Title"
    },
    width:{
      type:[String, Number],
      default:400
    },
  },
  computed:{
    styles(){
      return {
        "--right-bar-width": `${this.width}px`
      }
    }
  },
  methods:{
    close(){
        this.$emit('update:show',false)
    }
  }
}
</script>

<style lang="scss" scoped>
  .pg-right-bar-container-mask{
    position: fixed;
    inset: 0;
    z-index: 12100;
    background-color: rgba(0,0,0,0.3);

    .pg-right-bar-container{
      height: 100vh;
      width: var(--right-bar-width);
      background-color: #fff;
      margin-left: auto;
      margin-right: 0;
    }
  }
  .pg-right-bar-header{
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(0,0,0,.1);
    border-top: 1px solid rgba(0,0,0,.1);
    span{
      font-size: 1.1em;
      font-weight: 600;
    }
    svg{
      cursor: pointer;
      &:hover{
        background-color: rgba(0,0,0,.1);
        border-radius: 50%;
        border: 3px solid transparent;
      }
    }
  }
  .pg-right-bar-body{
    height: max-content;
    max-height: 90vh;
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: thin;
    scrollbar-color: #ccc;


    &::-webkit-scrollbar {
      width: 7px;
    }

    &::-webkit-scrollbar-thumb {
      background: #ccc;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #ddd;
    }
  }
</style>
