import token from "@/http/requests/auth/token";
import router from "@/router";
import axios from "@/axios";

export default {


  loginToken({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      token
        .login(payload.userDetails.username, payload.userDetails.password)
        .then(response => {
          let token = response.data.access;

          if (!token) {
            reject({ message: "Error occured" });
            return;
          }

          // If there's user data in response
          if (token) {
            // Set accessToken
            localStorage.setItem("accessToken", token);
            commit("SET_BEARER", token);
            // Set accessTokenExpiry
            // Navigate User to homepage
            router.push(router.currentRoute.query.to || "/");

            dispatch("getUser", token);

            resolve(response);
          } else {
            reject({ message: "Wrong Username or Password" });
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  
  getUser({ commit }, token) {
    return new Promise((resolve, reject) => {
      axios
        .get("/account/accounts/get_user/?token=" + token)
        .then(response => {
          commit("UPDATE_USER_INFO", response.data, { root: true });
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};
