import axios from "@/axios";
import requestsHelper from "@/services/helpers/requestsHelper";

export default {


  addItem(ignore, item) {
    return new Promise((resolve, reject) => {
      axios
        .post("/carrier/carrier_companies/", item)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  bulkUpdateShipMethods(ignore, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/carrier/carrier_company_services/bulk_update/`, payload)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  fetchShippingCompanies({ commit }, options) {
    let helpers = new requestsHelper(options);
    let queryset = helpers.createQueryset();
    return new Promise((resolve, reject) => {
      axios
        .get("/carrier/carrier_companies/" + queryset)
        .then(response => {
          commit("SET_CARRIER_COMPANIES", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  fetchSingleShippingCompanies({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get("/carrier/carrier_companies/" + id + "/")
        .then(response => {
          commit("SET_EDIT_DATA", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  updateItem(ignore, item) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/carrier/carrier_companies/` + item.id + "/", item)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  removeItem(ignore, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/carrier/carrier_companies/` + id + "/")
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  fetchShipping(ignore, payload) {
    let helpers = new requestsHelper(payload);
    let queryset = helpers.createQueryset();
    return new Promise((resolve, reject) => {
      axios
        .get("/carrier/carrier_company_services/"+queryset)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


};
