<template>
  <div class="package-form-container">
    <efsane-form v-if="formData" v-model="formData">
      <select name="status.status" :col-span="isCanceledPackage ? 6 : 12" placeholder="Please Choose Status" label="Status" :options="visibleStatuses"
              option-label="name" option-value="value"></select>
      <select v-if="isCanceledPackage" col-span="6" placeholder="Please Choose Cancel Reason" name="status.cancel_reason" label="Canceled Reason" :options="cancelReasons" option-label="name" option-value="value" ></select>

      <input name="length" col-span="3" type="number" step="0.01" label="Length"/>
      <input name="width" col-span="3" type="number" step="0.01" label="Width"/>
      <input name="height" col-span="3" type="number" step="0.01" label="Height"/>
      <input name="weight" col-span="3" type="number" step="0.01" label="Weight"/>
      <select name="carrier" placeholder="Please Choose Carrier" col-span="6" label="Carrier"
                    :options="$store.state.shippingCompanies.shippingCompanies" option-label="name"
                    option-value="code"></select>
      <select name="ship_method_code" placeholder="Please Choose Ship Method" label="Ship Method" col-span="6"
              :options="shipMethods" option-label="label" option-value="name"></select>
      <input name="tracking_number" :col-span="formData.tracking_number ? 3 : 6" type="text" label="Tracking Number"/>
      <button v-if="formData.tracking_number" label="Show Label" col-span="3" color="info" @click="showLabel"></button>
      <select name="delivery_confirmation_type" placeholder="Please Choose Delivery Confirmation Type"
              :options="deliveryConfirmationTypes" option-label="label" option-value="name" col-span="6" type="text"
              label="Delivery Confirmation Type"/>
      <input name="expected_ship_date" col-span="6" type="date" label="Expected Ship Date"/>
      <input name="required_delivery_date" col-span="6" type="date" label="Required Delivery Date"/>
      <input name="shipping_cost" col-span="6" type="number" step="0.01" label="Shipping Cost"/>
      <select name="insurance_provider" placeholder="Please Select Insurance Provider"
              :options="visibleInsuranceProviders" option-label="label" option-value="value" :col-span="formData.insurance_provider ? 4 : 6" type="text"
              label="Insurance Provider"/>
      <input name="insured_value" v-if="formData.insurance_provider" col-span="2" type="number" step="0.01" label="Insured Value"/>
    </efsane-form>

    <image-zoom-modal v-if="labelVisibility" :show.sync="labelVisibility"  :image-src="formData.label_src"></image-zoom-modal>
    <pg-button-group
      justify="right"
      gap="20"
      style="width: 100%; padding-right: 20px; margin-top: 15px;"
    >
      <pg-button
        color="info"
        size="small"
        @click="getRate(formData.id)"
      >Get Rate
      </pg-button>

      <pg-button
        v-if="['stamps', 'purolator'].includes(formData.carrier) && formData.tracking_number"
        color="danger"
        size="small"
        @click="voidALabel(formData.id)"
      >Void A Label
      </pg-button>

      <pg-button
        color="success"
        size="small"
        @click="updatePackage(formData.id)"
      >Update Package
      </pg-button>
    </pg-button-group>
  </div>
</template>

<script>
export default {
  name: "package_form",
  props: {
    order:Object,
    accept: String,
    type: String,
    packageData: {
      type: Object,
    },
    orderSelectData: {
      type: Function,
      default: () => {
      },
    },
  },
  data() {
    return {
      isCanceledPackage:false,
      formKey: 0,
      labelVisibility:false,
      formData: {
        carrier: null,
        height: 0,
        label: null,
        id: null,
        length: 0,
        ship_method: null,
        ship_method_code: null,
        shipping_cost: 0,
        insurance_provider:null,
        insured_value:null,
        status: {status: null, cancel_reason: null},
        delivery_confirmation_type: null,
        tracking_number: null,
        weight: 0,
        width: 0,
      },
      insuranceProviders: [
        {value: "parcel_guard", label: "Parcel Guard Insurance", carrier: "stamps"},
      ],
      statuses: [
        //{value: "label_created", name: "Label Created"},
        {value: "printed", name: "Printed"},
        {value: "awaiting_shipments", name: "Awaiting Shipments"},
        {value: "put_on_hold", name: "Put On Hold"},
        {value: "canceled", name: "Canceled"},
        {value: "in_transit", name: "In Transit"},
        {value: "delivered", name: "Delivered"},
        {value: "needs_attention", name: "Needs Attention"},
      ],
      cancelReasons: []
    }
  },
  created() {
    this.formData = this.packageData;
    this.fetchCancelReasons();
  },
  watch: {
    status(){
      this.isCanceledPackage = this.formData.status.status === 'canceled'
    },
    packageData() {
      this.formData = this.packageData
    }
  },

  computed: {
    visibleInsuranceProviders(){
      return this.insuranceProviders.filter(v => v.carrier === this.formData.carrier)
    },
    status() {
      let status = null;
      if (Object.keys(this.formData).includes("status")) {
        status = this.formData?.status?.status;
      }
      return status;
    },
    shipMethods() {
      let companies = this.$store.state.shippingCompanies.shippingCompanies || []
      return companies.find(
        (v) => v.code?.toLowerCase() === this.formData?.carrier?.toLowerCase()
      )?.services;
    },
    visibleStatuses(){
      if(this.packageData?.status?.status === "canceled"){
        return this.statuses
      }
      return this.statuses.filter(v => v.value !== "canceled")
    },
    deliveryConfirmationTypes() {
      let companies = this.$store.state.shippingCompanies.shippingCompanies || []
      return companies.find(
        (v) => v.code?.toLowerCase() === this.formData?.carrier?.toLowerCase()
      )?.delivery_confirmation_types;
    },
  },
  methods: {
    showLabel(){
      this.labelVisibility = true
    },
    updatePackage() {
      if (!Object.keys(this.formData).length) {
        return;
      }
      let {order, package_lines, ...data} = this.formData;
      data.cancel_reason = data?.status?.cancel_reason;
      data.status = data?.status?.status;
      data.tracking_number = data?.tracking_number || null
      data.ship_method = this.shipMethods?.find(v => v.name === data?.ship_method_code)?.label;
      this.$store
        .dispatch("packages/partialUpdatePackage", {
          package_Id: this.formData.id,
          formData: data,
        })
        .then(() => {
          this.$swal({
            icon: "success",
            title: "Package Updated",
            text: "Successfully Sent",
          }).then(() => {
            this.orderSelectData();
          });
        });
    },
    voidALabel() {
      if (!Object.keys(this.formData).length) {
        return;
      }
      this.$swal({
        title: "Confirm",
        icon: "info",
        text: "Do you want to void a label?",
        buttons: {
          catch: {
            text: "Yes",
            value: true,
            className: "text-center",
          },
          cancel: "No",
        },
      }).then((value)=>{
          if(!value) return

          this.$store
            .dispatch("packages/voidALabel", {
              package_id: this.formData.id
            })
            .then(() => {
              this.$swal({
                icon: "success",
                title: "Voided A Label",
                text: "Request Successfully Sent",
              }).then(() => {
                this.orderSelectData();
              });
            });
          })
    },
    getRate() {
      if (!Object.keys(this.formData).length) {
        return;
      }
      this.$store
        .dispatch("packages/getRate", {
          package_id: this.formData.id,
          carrier: this.formData.carrier
        })
        .then((response) => {
          this.$swal({
            icon: "success",
            title: response.data.carrier_name,
            text: `Ship Method: ${response.data.ship_method}, Shipping Cost: ${response.data.shipping_cost}`,
          })
        }).catch(()=>{
        this.$swal({
          icon: "warning",
          title: `Something Went Wrong, Please make sure to fill out the carrier settings`
        })
      })
    },
    async fetchCancelReasons() {
      let obj = {marketplace: this.order.marketplace};
      await this.$store
        .dispatch("orders/fetchCancelReasons", obj)
        .then((response) => {
          this.cancelReasons = response.data.reasons;
        })
    },
  },
};
</script>

<style lang="scss" scoped>
.package-actions-container {
  margin-top: 1.25rem;
  margin-left: 1.25rem
}
</style>
