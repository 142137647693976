<template>
  <div id="app" >
      <router-view  />
  </div>
</template>

<script>
import websocket from "@/websocket";
export default {
  data(){
    return {
      internalCount:0,
      webSocketInterval: setInterval(()=>{
        if(this.internalCount > 20){
           clearInterval(this.webSocketInterval)
          return
        }
        if(!websocket.isOpen()){
          websocket.connect()
          this.internalCount +=1
          this.$store.state.websocket.serverStatus = false
          return
        }
        this.internalCount = 0
        this.$store.state.websocket.serverStatus = true
      },5000)

    }
  },
  async mounted() {
        document.documentElement.setAttribute("dir", 'ltr');
        document.addEventListener('visibilitychange', ()=>{
          this.$store.dispatch('changePageVisibility', document.visibilityState)
        })
      websocket.connect()
      this.$store.state.websocket.serverStatus = true
    },
};
</script>
<style lang="scss">
@import url("../pg-thema.scss");
@import "./assets/css/swal.scss";
.vs-popup--content{
  scrollbar-width: thin;
  scrollbar-color: #ccc;

  &::-webkit-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #ddd;
  }
}
</style>
