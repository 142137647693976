export default {
  importFetchLogs({ commit }, data) {
    commit('SET_IMPORT_FETCH_LOGS', data)
  },
  uploadOrderWizard({ commit }, data) {
    commit("SET_UPLOAD_ORDER_WIZARD_STATUS", data)
  },
  lastMessage({ commit }, data) {
    commit('SET_LAST_MESSAGE', data)
  },
};
