import axios from "@/axios";
import requestsHelper from "@/services/helpers/requestsHelper";

export default {
  fetchPackages({ commit }, options) {
    let helpers = new requestsHelper(options);
    let queryset = helpers.createQueryset();

    if (queryset.toString().search("package_status") > -1) {
      queryset.replace("package_status", "ship_status");
    }

    if (queryset.toString().search("marketplaces") == -1) {
      queryset += "&marketplace=0";
    }

    return new Promise((resolve, reject) => {
      axios
        .get("/package/packages/" + queryset)
        .then(response => {
          commit("SET_PACKAGES", response.data.results);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  fetchSelectPackage({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get("/package/packages/" + id + "/")
        .then(response => {
          commit("SET_SELECT_PACKAGE", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  updateItem(ignore, payload) {
    return new Promise((resolve, reject) => {
      axios
        .patch("/package/packages/" + payload.id + "/", payload)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  fetchStatuses() {
    return new Promise((resolve, reject) => {
      axios
        .get("/package/packages/statuses/")
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  addItem(ignore, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/package/packages/", payload)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  removeItem(ignore, payload) {
    return new Promise((resolve, reject) => {
      axios
        .delete("/package/packages/" + payload + "/")
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  getTrackingNumber(ignore, item) {
    return new Promise((resolve, reject) => {
      axios
        .post("/package/packages/get_tracking_numbers/", item)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  confirmTrackingNumber(ignore, item) {
    return new Promise((resolve, reject) => {
      axios
        .post("/package/packages/confirmation_tracking_numbers/", item)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  downloadShippingLabel(ignore, item) {
    return new Promise((resolve, reject) => {
      axios
        .post("/package/packages/get_labels_zip/", item)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  trackingNumberExport(ignore, item) {
    return new Promise((resolve, reject) => {
      axios
        .post("/package/packages/tracking_number_export/", item)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  partialUpdatePackageline(ignore, {package_line_Id, formData}){
    return new Promise((resolve, reject) => {
      axios.patch("/package/package_lines/" + package_line_Id + "/", formData).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  },

  partialUpdatePackage({commit}, {package_Id, formData}){
    return new Promise((resolve, reject) => {
      axios.patch("/package/packages/" + package_Id + "/", formData).then(response => {
        commit("SET_SELECT_PACKAGE", response.data);
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  },

  voidALabel({commit}, data){
    return new Promise((resolve, reject) => {
      axios.put("/package/packages/void_a_label/", data).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  },

  getRate({commit}, options){
    let helpers = new requestsHelper(options);
    let queryset = helpers.createQueryset();
    return new Promise((resolve, reject) => {
      axios.get("/package/packages/get_rate/" + queryset).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  },

  async getPackage(ignore, {id}){
    const response = await axios.get("/package/packages/" + id + "/");
    return response.data;
  },

  trackingFileUpload(ignore, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/package/packages/tracking_file_upload/", payload, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

};
