<template>
    <span :style="styles" class="efsane-form-tooltip" :data-tooltip="tooltip" v-if="tooltip">&nbsp;</span>
</template>

<script>
export default {
  name: "efsane-description-tooltip",
  props:{
    tooltip:String,
    type: {
      type: String,
      default: "info"
    }
  },
  computed:{
    iconData(){
      if(this.type === "question") return `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB10lEQVR4nI1USy9DQRSesPP4AdZmvBYSiZUFC6wkngsLvwHpDxBrETMlRIhXIiSKjXfCitiR6J1LW680jYaoR2lK6nXkDKq9vVNOcpJ5nPlyHt83hNjY3khelhSsWXLab3C6aHC2oNZ9rAnvyF/mcpFMN6cOg9MbKRjYuSFoSHLaibG2IJ6ewlzJ6ZoOQKY4XcE3SSDQTTIMzpYTAz2jFRDan4CnywN4vvbAnbkAJ9N1ydlxtpGUGZaTGOCbqIbX6A2gvTwGIXbvV+uPtxiczbVaSmUdCuSgtzTb2pOwb1U9DHuXQToL1Rlmhxbxb6f0TA1AOmmLtf5ocA8+3l/BN1kTPwusORTQc8hrM4CCRiIFHbBeeMcq4XSm6Xc/XgWxcEABXe322QAxJ0GepJvO8VQtvEQu46WaziI7oHmiyJYG6OlKKpBb92y8XynOmYsgY3UgR8PlX5N7uLDNJIEGgiDtdQHmQAk8nG5CcKsrLTlNkV+vdKVorwkKrHbC+XybHoiz67j+UDt2QTi5HyKa/cW6stqTJSLYkjXocKgMIv4duJMujYDZeop4lWgFXfmvaFGb5mBJjvYbQe2k6xneYTlYxZ//0tcAChpxrMizbxdunt+g+9g+AZ5lwJq+Z+4wAAAAAElFTkSuQmCC`
      if(this.type === "checked") return `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAABY0lEQVR4nGNgGAVEAkYmdpZ0Fn7uRVQxjJWPu1OnK/6GQrLrMQYGBm2KTGPh5izQ7Um6ZbW55r/52opvLLychZSYZyOf5HIYZBgMC5iqrGVgYBAmxzB+fgOlzciGGc3OfsIhJjCfgYGBC5cmayYmpkhsEqwCPF3my0o+wgxTrww5y8rL2QYKBZxOYOXnmqBaFnCWiYs9DU3KSjHN/TjMMMV0j2NMrEzoajABn57iBpgGBmYGd6gwE4+KxCqYYcp5PieZmJhyGYgAnNyqUjstN1X/AWkUdzfcycDAoM7EzpSoPyHlLkgMlFSY2FhKGEgAatzqUmstNlb/sdpU9ZdTWnilkIU6OCJMlxd/5xAXmM5ABlAUNFXbAo7FmVlPzJYVf4Amj60MDAw8DGQCK8kA892wcNOoi7jAwMzgx0ARYGb2V8ryPAoKU05pYVBaoxwwcbGm8ajL7AFFDgMVgSo1DRt4AADBZ30XpB6UkgAAAABJRU5ErkJggg==`
      if(this.type === "warning") return `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAABMUlEQVR4nL2UMU4DMRBFN1wg4Qop7NkNKEJUZOkoSI5AwSGouEAC3CFtWk4BRcZZKRJXgIRwAmjQoJli2Wg9dsJKWPqSC/tpPH++k+Q/F1FyQJj2yJmRiPeUtPYHPR0fkrP3hHZNDmhLCCtCe0fLfmc3WGHOycFHDVST3dDC5HEYwlccVuqTnB34Yct+R6ts+pCLlErfqei260DumVLJ1fVIpFaKMPG46TFgd+Bqy31ycBTqVRToGJr2KkCes6ZAM/wFohk2B9rLCpDToB9+eTwRBYHzLKua0iIHr9rhb0xFAeBbLZISJ+XC7c2FKAAcK4NtN74Lz7NTkdK7tXewBbow+d7RQzjzwiqJGUicYjCuLAYroUW3zXGSBPgMcDBWnxkEs/vzLCs/WN7/5YNtsn4AxGu7tYObpZYAAAAASUVORK5CYII=`
      return `data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4Igp3aWR0aD0iMTgiIGhlaWdodD0iMTgiCnZpZXdCb3g9IjAgMCA1MTIgNTEyIj4KPHBhdGggZmlsbD0iIzI1QjdEMyIgZD0iTTUwNC4xLDI1NkM1MDQuMSwxMTksMzkzLDcuOSwyNTYsNy45QzExOSw3LjksNy45LDExOSw3LjksMjU2QzcuOSwzOTMsMTE5LDUwNC4xLDI1Niw1MDQuMUMzOTMsNTA0LjEsNTA0LjEsMzkzLDUwNC4xLDI1NnoiPjwvcGF0aD48cGF0aCBmaWxsPSIjRkZGIiBkPSJNMzIzLjIgMzY3LjVjLTEuNC0yLTQtMi44LTYuMy0xLjctMjQuNiAxMS42LTUyLjUgMjMuOS01OCAyNS0uMS0uMS0uNC0uMy0uNi0uNy0uNy0xLTEuMS0yLjMtMS4xLTQgMC0xMy45IDEwLjUtNTYuMiAzMS4yLTEyNS43IDE3LjUtNTguNCAxOS41LTcwLjUgMTkuNS03NC41IDAtNi4yLTIuNC0xMS40LTYuOS0xNS4xLTQuMy0zLjUtMTAuMi01LjMtMTcuNy01LjMtMTIuNSAwLTI2LjkgNC43LTQ0LjEgMTQuNS0xNi43IDkuNC0zNS40IDI1LjQtNTUuNCA0Ny41LTEuNiAxLjctMS43IDQuMy0uNCA2LjIgMS4zIDEuOSAzLjggMi42IDYgMS44IDctMi45IDQyLjQtMTcuNCA0Ny42LTIwLjYgNC4yLTIuNiA3LjktNCAxMC45LTQgLjEgMCAuMiAwIC4zIDAgMCAuMi4xLjUuMS45IDAgMy0uNiA2LjctMS45IDEwLjctMzAuMSA5Ny42LTQ0LjggMTU3LjUtNDQuOCAxODMgMCA5IDIuNSAxNi4yIDcuNCAyMS41IDUgNS40IDExLjggOC4xIDIwLjEgOC4xIDguOSAwIDE5LjctMy43IDMzLjEtMTEuNCAxMi45LTcuNCAzMi43LTIzLjcgNjAuNC00OS43QzMyNC4zIDM3Mi4yIDMyNC42IDM2OS41IDMyMy4yIDM2Ny41ek0zMjIuMiA4NC42Yy00LjktNS0xMS4yLTcuNi0xOC43LTcuNi05LjMgMC0xNy41IDMuNy0yNC4yIDExLTYuNiA3LjItOS45IDE1LjktOS45IDI2LjEgMCA4IDIuNSAxNC43IDcuMyAxOS44IDQuOSA1LjIgMTEuMSA3LjggMTguNSA3LjggOSAwIDE3LTMuOSAyNC0xMS42IDYuOS03LjYgMTAuNC0xNi40IDEwLjQtMjYuNEMzMjkuNiA5NiAzMjcuMSA4OS42IDMyMi4yIDg0LjZ6Ij48L3BhdGg+Cjwvc3ZnPg==`    },
    styles(){
      return {
        "--tooltip-icon-data": `url("${this.iconData}")`
      }
    }
  }
}
</script>

<style lang="scss" >

:root{
  --tooltip-height:1.3em;
  --triangle-height:.5em;
  --text-height:1em
}

.efsane-form-tooltip {
  display: inline;
  position: relative;
  width: 2em;
  height: var(--tooltip-height);
  cursor: help;
  transition: all 0.3s ease-in-out;
  background-position: center;
  background-image: var(--tooltip-icon-data);
  background-repeat: no-repeat;
  margin-bottom: .2em;
}
.efsane-form-tooltip:hover:after{
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  background: #343A40;
  border-radius: .5em;
  color: #fdfdfd;
  content: attr(data-tooltip);
  font-size: var(--text-height);
  padding: .5em var(--text-height);
  line-height: var(--text-height);
  box-shadow: 0 5px 10px rgba(0,0,0,.1);
  min-height: var(--text-height);
  min-width: 4em;
  width: max-content;
  max-width: 25vw;
  animation: .4s fadeIn;
  z-index: 99999999999;
  animation-fill-mode: forwards;
  position: absolute;
  left: 0;
  bottom: calc(var(--tooltip-height) + var(--triangle-height));
}
.efsane-form-tooltip:hover:before{
  border: solid;
  border-color: #444 transparent;
  border-width: var(--triangle-height) calc(var(--triangle-height) / 2) 0 calc(var(--triangle-height) / 2) ;
  content: "";
  left: 50%;
  bottom: var(--tooltip-height);
  position: absolute;
  animation: .4s fadeIn;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  99% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}
</style>
