import themeConfig from "@/../themeConfig.js"
import colors from "@/../themeConfig.js"

// *From Auth - Data will be received from auth provider
const userDefaults = {
  id         : 0,          // From Auth
  firstname : "No Seller Name",// From Auth
  lastname: null,
  photoURL    : require("@/assets/images/portrait/small/avatar-s-27.jpg"), // From Auth
  company_name : "No Company Name",
  type: "",
  email: "",
  phone: null,
  tax_nr: null,
  registration_nr: null,
  is_active: false,
  created_at: "",
  updated_at: "",
  last_purchase_at: null,
  type_id: 0
}


// Check if device is touch device
// This is used to remove perfect scrollbar from touch devices
// Using Dynamic partials
const is_touch_device = () => {
  var prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
  var mq = function(query) {
    return window.matchMedia(query).matches;
  }

  if (('ontouchstart' in window) || window.DocumentTouch) {
    return true;
  }

  // include the 'heartz' as a way to have a non matching MQ to help terminate the join
  // https://git.io/vznFH
  var query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
  return mq(query);
}

const active_user_control = () => {

  if(JSON.parse(localStorage.getItem("userInfo"))){
    let myLocalStorage = JSON.parse(localStorage.getItem("userInfo"))
    for (const property of Object.keys(myLocalStorage)) {

      if (userDefaults[property] != null) {
        userDefaults[property] = myLocalStorage[property]
      }
    }
    return userDefaults;

  }else
  {
    return userDefaults;
  }
}


// /////////////////////////////////////////////
// State
// /////////////////////////////////////////////

const state = {
  AppActiveUser           : active_user_control(),
  bodyOverlay             : false,
  isVerticalNavMenuActive : true,
  is_touch_device         : is_touch_device(),
  mainLayoutType          : themeConfig.mainLayoutType || "vertical",
  reduceButton            : themeConfig.sidebarCollapsed,
  verticalNavMenuWidth    : "default",
  verticalNavMenuItemsMin : false,
  scrollY                 : 0,
  theme                   : themeConfig.theme || "light",
  activeJobs               : true,

    // Can be used to get current window with
    // Note: Above breakpoint state is for internal use of sidebar & navbar component
    windowWidth: null,
    pageVisibility:true
}

export default state
