<template>
  <div>
    <efsane-form v-model="formData" gap="25">
            <input disabled name="po_number" col-span="4" type="text" label="PO Number"/>
            <input disabled col-span="4" name="marketplace_customer_order_number" type="text" label="Customer Order Number"/>

            <select name="warehouse" placeholder="Please select a warehouse" col-span="4" label="Warehouse"
                    :options="$store.state.warehouse.warehouses" option-label="name"
                    option-value="id">
                    </select>

            <fieldset padding-top="5" padding-bottom="15">
              <legend label="Customer"></legend>
              <input name="customer_name" col-span="6" type="text" label="Customer Name"/>
              <input name="phone" col-span="6" type="tel" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" label="Phone"/>

              <input name="address1" col-span="4" type="text" label="Address 1"/>
              <input name="address2" col-span="4" type="text" label="Address 2"/>
              <input name="address3" col-span="4" type="text" label="Address 3"/>

              <input name="city" col-span="6" type="text" label="City"/>
              <select name="state" placeholder="Please Choose State" col-span="6" label="State"
                      :options="getStates" option-label="name"
                      option-value="id"></select>
              <input name="zipcode" col-span="6" type="text" label="Zipcode"/>

              <select name="country" placeholder="Please Choose Country" col-span="6" label="Country"
                      :options="getCountries" option-label="name"
                      option-value="value"></select>
              <input name="is_residential" col-span="6" type="switch" justify="flex-start" label="Is Residential"/>

            </fieldset>

            <fieldset padding-top="5" padding-bottom="15">
              <legend label="Shipping"></legend>
              <select name="carrier" placeholder="Please Choose Carrier" col-span="6" label="Carrier"
                      :options="$store.state.shippingCompanies.shippingCompanies" option-label="name"
                      option-value="code"></select>
              <select name="ship_method_code" placeholder="Please Choose Ship Method" label="Ship Method" col-span="6"
                      :options="shipMethods" option-label="label" option-value="name"></select>
              <input name="expected_ship_date" col-span="6" type="date" label="Expected Ship Date"/>
              <input name="required_delivery_date" col-span="6" type="date" label="Required Delivery Date"/>
              <input name="is_signature_required" col-span="6" type="switch" justify="flex-start" label="Is Signature Required"/>
            </fieldset>

            <fieldset padding-top="5" padding-bottom="15" v-if="isloadMore">
              <legend label="Marketplace Order Data"> </legend>
              <input disabled name="marketplace_carrier" col-span="6" type="text" label="Carrier From Marketplace "/>
              <input disabled name="marketplace_ship_method" col-span="6" type="text" label="Ship Method from Marketplace"/>
              <input name="marketplace_supplier_id" col-span="6" type="text" label="Marketplace Supplier ID"/>
              <input name="marketplace_retail_id" col-span="6" type="text" label="Marketplace Retail ID"/>
              <input name="marketplace_order_id" col-span="6" type="text" label="Marketplace Order ID"/>
              <input name="receipt_id" col-span="6" type="text" label="Receipt ID"/>
              <input name="ship_from_id" col-span="6" type="text" label="Ship From ID"/>
              <input name="offer_currency" col-span="6" type="text" label="Offer Currency"/>
              <input name="is_prime" col-span="6" type="switch" justify="flex-start" label="Is Prime"/>
            </fieldset>
            <fieldset padding-top="5" padding-bottom="15" v-if="isloadMore">
              <legend label="Marketplace Warehouse"> </legend>
              <input disabled name="marketplace_warehouse_id" col-span="6" type="text" label="Marketplace Warehouse ID"/>
              <input disabled name="marketplace_warehouse_name" col-span="6" type="text" label="Marketplace Warehouse Name"/>
              <input disabled name="marketplace_warehouse_address1" col-span="6" type="text" label="Marketplace Warehouse Address 1"/>
              <input disabled name="marketplace_warehouse_address2" col-span="6" type="text" label="Marketplace Warehouse Address 2"/>
              <input disabled name="marketplace_warehouse_city" col-span="6" type="text" label="Marketplace Warehouse City"/>
              <input disabled name="marketplace_warehouse_state" col-span="6" type="text" label="Marketplace Warehouse State"/>
              <input disabled name="marketplace_warehouse_country" col-span="6" type="text" label="Marketplace Warehouse Country"/>
              <input disabled name="marketplace_warehouse_postal_code" col-span="6" type="text" label="Marketplace Warehouse Postal Code"/>
            </fieldset>
            <fieldset padding-top="5" padding-bottom="15" v-if="isloadMore">
              <legend label="Tax"></legend>
              <input disabled name="tax_type_code_1" col-span="4" type="text" label="Tax Type Code 1"/>
              <input disabled name="tax_percentage_1" col-span="4" type="number" step="0.01" label="Tax Percentage 1"/>
              <input disabled name="tax_amount_1" col-span="4" type="number" step="0.01" label="Tax Amount 1"/>
              <input disabled name="tax_type_code_2" col-span="4" type="text" label="Tax Type Code 2"/>
              <input disabled name="tax_percentage_2" col-span="4" type="number" step="0.01" label="Tax Percentage 2"/>
              <input disabled name="tax_amount_2" col-span="4" type="number" step="0.01" label="Tax Amount 2"/>
              <input disabled name="tax_type_code_3" col-span="4" type="text" label="Tax Type Code 3"/>
              <input disabled name="tax_percentage_3" col-span="4" type="number" step="0.01" label="Tax Percentage 3"/>
              <input disabled name="tax_amount_3" col-span="4" type="number" step="0.01" label="Tax Amount 3"/>
            </fieldset>
            <fieldset padding-top="5" padding-bottom="15" v-if="isloadMore">
              <legend label="Payment Method"> </legend>
              <input disabled name="payment_method_1" col-span="6" type="text" label="Payment Method 1"/>
              <input disabled name="payment_method_id_1" col-span="6" type="text" label="Payment Method ID 1"/>
              <input disabled name="payment_method_2" col-span="6" type="text" label="Payment Method 2"/>
              <input disabled name="payment_method_id_2" col-span="6" type="text" label="Payment Method ID 2"/>
              <input disabled name="payment_method_3" col-span="6" type="text" label="Payment Method 3"/>
              <input disabled name="payment_method_id_3" col-span="6" type="text" label="Payment Method ID 3"/>
            </fieldset>>
            <fieldset padding-top="5" padding-bottom="15" v-if="isloadMore">
              <legend label="Bill"></legend>
              <input disabled name="bill_to_attention" col-span="6" type="text" label="Bill To Attention"/>
              <input disabled name="bill_to_first_name" col-span="6" type="text" label="Bill To First Name"/>
              <input disabled name="bill_to_last_name" col-span="6" type="text" label="Bill To Last Name"/>
              <input disabled name="bill_to_company" col-span="6" type="text" label="Bill To Company"/>
              <input disabled name="bill_to_address_1" col-span="6" type="text" label="Bill To Address 1"/>
              <input disabled name="bill_to_address_2" col-span="6" type="text" label="Bill To Address 2"/>
              <input disabled name="bill_to_city" col-span="6" type="text" label="Bill To City"/>
              <input disabled name="bill_to_region" col-span="6" type="text" label="Bill To Region"/>
              <input disabled name="bill_to_postal" col-span="6" type="text" label="Bill To Postal Code"/>
              <input disabled name="bill_to_country" col-span="6" type="text" label="Bill To Country"/>
              <input disabled name="bill_to_phone" col-span="6" type="tel" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" label="Bill To Phone"/>
              <input disabled name="bill_to_email" col-span="6" type="email" label="Bill To Email"/>
            </fieldset>
            <fieldset padding-top="5" padding-bottom="15" v-if="isloadMore">
              <legend label="Gift"></legend>
              <input disabled name="gift_message" col-span="6" type="text" label="Gift Message"/>
              <input disabled name="gift_from_name" col-span="6" type="text" label="Gift From Name"/>
              <input disabled name="gift_to_name" col-span="6" type="text" label="Gift To Name"/>
              <input disabled name="gift_receipt_id" col-span="6" type="text" label="Gift Receipt ID"/>
              <input disabled name="is_gift" col-span="6" type="switch" justify="flex-start" label="Is Gift"/>
            </fieldset>
            <fieldset padding-top="5" padding-bottom="15" v-if="isloadMore">
              <legend label="Coupon"></legend>
              <input disabled name="coupon_amount_1" col-span="6" type="number" step="0.01" label="Coupon Amount 1"/>
              <input disabled name="coupon_percentage_1" col-span="6" type="number" step="0.01" label="Coupon Percentage 1"/>
              <input disabled name="coupon_amount_2" col-span="6" type="number" step="0.01" label="Coupon Amount 2"/>
              <input disabled name="coupon_percentage_2" col-span="6" type="number" step="0.01" label="Coupon Percentage 2"/>
              <input disabled name="coupon_amount_3" col-span="6" type="number" step="0.01" label="Coupon Amount 3"/>
              <input disabled name="coupon_percentage_3" col-span="6" type="number" step="0.01" label="Coupon Percentage 3"/>
            </fieldset>
    </efsane-form>

    <pg-button-group
      justify="right"
      gap="20"
      style="width: 100%; padding-right: 20px"
    >
      <pg-button id="loadButton" color="info" size="small" @click="loadMore"
        >Load more</pg-button
      >
      <pg-button color="success" size="small" @click="updateDetails"
        >Update Order</pg-button
      >
    </pg-button-group>
  </div>
</template>


<script>

import requestsHelper from "@/services/helpers/requestsHelper";

export default {
  name: "order-details",
  props: {
    order: Object,
    tag: String,
    multiple: Boolean,
    disabled: Boolean,
    step: Number,
    orderSelectData: {
      type: Function,
      default: () => {},
    },
  },
  data(){
    return {
      isloadMore:false,
      formData:{},
      selected: {}
    }
  },
  mounted() {
    this.setOrderData()
  },
  computed:{
    shipMethods() {
      let companies = this.$store.state.shippingCompanies.shippingCompanies || []
      return companies.find(
        (v) => v.code?.toLowerCase() === this.formData?.carrier?.toLowerCase()
      )?.services;
    },
  },
  methods:{
    setOrderData(){
      Object.entries(this.order).forEach((item)=>{
        if (item[0] === "warehouse"){
          this.selected = this.$store.state.warehouse.warehouses.find(f => f.id == item[1].id)
          this.$set(this.formData, item[0], this.selected.id);
        }else{
          this.$set(this.formData, item[0], item[1]);
        }

      })
    },
    loadMore(){
      this.isloadMore = true
    },
    updateDetails() {
      if (!Object.keys(this.formData).length) {
        return;
      }
      let diffData = {}

      Object.entries(this.order).forEach((item)=>{
        if(this.formData[item[0]] !== item[1]) {
          diffData[item[0]] = this.formData[item[0]]
        }
      })

      if("ship_method_code" in diffData && diffData["ship_method_code"]){
        diffData["ship_method"] = this.shipMethods.find(v => v.name === diffData["ship_method_code"]).label
      }

      this.$store
        .dispatch("orders/updateOrder", {
          orderId: this.order.id,
          formData: diffData,
        })
        .then(() => {
          this.$swal({
            icon: "success",
            title: "Partial update",
            text: "Succesfully Sent",
          }).then(() => {
            this.orderSelectData();
          });
        });
    }
  },
  watch:{
    order:{
      handler(){
        this.setOrderData()
      },
      deep:true
    }
  }
};
</script>


