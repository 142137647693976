export default {
  async created(){
    this.currentId = this.value
  },
  mounted(){

  },
  destroyed(){
  }
}
