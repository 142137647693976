<template>
<div v-if="this.order.error" class="errors-timeline-container">
  <sticky-header title="Errors" class="stickyheader-yellow">
    <template slot="icon">
      <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" viewBox="0 0 512 512"><title>Errors</title><path d="M85.57 446.25h340.86a32 32 0 0028.17-47.17L284.18 82.58c-12.09-22.44-44.27-22.44-56.36 0L57.4 399.08a32 32 0 0028.17 47.17z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/><path d="M250.26 195.39l5.74 122 5.73-121.95a5.74 5.74 0 00-5.79-6h0a5.74 5.74 0 00-5.68 5.95z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/><path d="M256 397.25a20 20 0 1120-20 20 20 0 01-20 20z"/></svg>
    </template>
  </sticky-header>
  <span class="error-timeline-text">
  {{ this.order.error }}
  </span>
</div>
</template>

<script>
import StickyHeader from "@/components/pg-order-detail/partials/sticky-header";
export default {
  name: "errors-timeline",
  components:{
    StickyHeader
  },
  props:{
    order: Object
  }
}
</script>

<style lang="scss" scoped>
  .errors-timeline-container{
    margin-bottom: 50px;
    padding-block: 5px;
    scroll-margin-top: 50px;
    background-color: #fffbeb; 
    padding-inline: 30px;
    margin-inline: 50px;
    border-radius: 5px;
    border: 1px dashed rgba(0,0,0,.1);
  }
  .error-timeline-text{
    color: #941b00;
  }
</style>
