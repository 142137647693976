<template>
  <div class="flex flex-col gap-6 p-5">
    <soludio-input class="w-2/3 mt-5" v-model="itemData.sku" label="Sku Number" data-cy="single-sku" placeholder="Please Enter Sku Number"></soludio-input>
    <div class="flex flex-col gap-5">
      <div class="flex flex-col gap-4">
        <h3 class="text-xl font-light my-5 flex font-semibold text-gray-700 items-center gap-2">
          <img height="24"
               src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yMyA2LjA2NnYxMi4wNjVsLTExLjAwMSA1Ljg2OS0xMS01Ljg2OXYtMTIuMTMxbDExLTYgMTEuMDAxIDYuMDY2em0tMjEuMDAxIDExLjQ2NWw5LjUgNS4wNjl2LTEwLjU3bC05LjUtNC45NDZ2MTAuNDQ3em0yMC4wMDEtMTAuMzg4bC05LjUwMSA0Ljg4OXYxMC41NjhsOS41MDEtNS4wNjl2LTEwLjM4OHptLTUuNTIgMS43MTZsLTkuNTM0LTQuOTY0LTQuMzQ5IDIuMzczIDkuNDA0IDQuODk2IDQuNDc5LTIuMzA1em0tOC40NzYtNS41NDFsOS41NjUgNC45OCAzLjgzMi0xLjk3Mi05LjQwNS01LjE4NS0zLjk5MiAyLjE3N3oiLz48L3N2Zz4="/>
          Item Information</h3>
        <soludio-input v-model="itemData.upc" class="w-2/3" label="UPC (optional)" data-cy="single-upc" placeholder="Please Enter UPC"></soludio-input>
        <soludio-input v-model="itemData.description" @input="changeDescription" class="w-2/3" data-cy="single-description" label="Description (optional)" placeholder="Please Enter Description"></soludio-input>
        <soludio-input v-model="itemData.external_sku_ref_list" class="w-2/3" type="text-area" data-cy="single-external-sku" tooltip="Enter external SKU typos. Use comma to separate values."
                       label="External SKU Ref List (optional)" placeholder="Please Enter Description"></soludio-input>
      </div>
      <div class="flex flex-col gap-4">
        <h3 class="text-xl font-light my-5 font-semibold text-gray-700 flex items-center gap-2">
          <img height="24"
               src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTkgMGgtMTRjLTIuNzYyIDAtNSAyLjIzOS01IDV2MTRjMCAyLjc2MSAyLjIzOCA1IDUgNWgxNGMyLjc2MiAwIDUtMi4yMzkgNS01di0xNGMwLTIuNzYxLTIuMjM4LTUtNS01em0zIDE5YzAgMS4zMDItLjgzOSAyLjQwMS0yIDIuODE2di0yLjgxNmgtMXYzaC0ydi0zaC0xdjNoLTJ2LTNoLTF2M2gtMnYtM2gtMXYzaC0ydi0zaC0xdjNoLTJ2LTNoLTF2Mi44MTVjLTEuMTYxLS40MTQtMi0xLjUxMy0yLTIuODE1di0xNGMwLTEuMzAyLjgzOS0yLjQwMSAyLTIuODE2djIuODE2aDF2LTNoMnY2aDF2LTZoMnYzaDF2LTNoMnYzaDF2LTNoMnY2aDF2LTZoMnYzaDF2LTIuODE2YzEuMTYxLjQxNSAyIDEuNTE0IDIgMi44MTZ2MTR6bS0xNC4xNDEtOWgxLjE0MXY2aC0xLjM1NHYtMy44ODloLTEuNDg4di0xYy44OTMtLjA0MSAxLjcwMS0uMjAxIDEuNzAxLTEuMTExem04LjMxNSA0LjY2N2gxLjgyNnYxLjMwNmgtMy45MjJ2LS45NThjMS41MjktMS40OTEgMi4zNzktMi4yNDQgMi4zODktMy4wMTggMC0uNDYyLS4yNjgtLjcxNy0uNzU0LS43MTctLjQ1MyAwLS44MTYuMjQ1LTEuMTY4LjUyNGwtLjQyNC0xLjE0OGMuNTQ1LS40NTIgMS4xOTEtLjY1NiAxLjgzMy0uNjU2IDEuMTc5IDAgMS45NDEuNzQgMS45NDEgMS44ODUuMDAxIDEuMTU1LS44OTkgMi4wNDMtMS43MjEgMi43ODJ6Ii8+PC9zdmc+"/>
          Shipment Details</h3>
        <div class="flex gap-2 w-2/3">
          <soludio-input v-model="itemData.length" class="w-1/3" type="number" tooltip="Length can not be less than width and height."
                         label="Length(In.)" data-cy="single-length" placeholder="Please Enter Length"></soludio-input>
          <soludio-input v-model="itemData.width" class="w-1/3" type="number" label="Width(In.)" data-cy="single-width"
                         placeholder="Please Enter Width"></soludio-input>
          <soludio-input v-model="itemData.height" class="w-1/3" type="number" label="Height(In.)" data-cy="single-height"
                         placeholder="Please Enter Height"></soludio-input>
        </div>
        <soludio-input v-model="itemData.weight" class="w-2/3" type="number" label="Weight(In.)" data-cy="single-weight"
                       placeholder="Please Enter Weight"></soludio-input>
        <soludio-input v-model="itemData.pack_group.id"
                       @input="searchPackGroups"
                       option-value="id"
                       option-label="name" :options="packGroups" type="search" class="w-2/3" data-cy="single-packgroup" label="Packgroup" tooltip="Maximum number of items can be packed together."
                       placeholder="Please Enter Packgroup"></soludio-input>
        <soludio-input v-model="itemData.print_folder" class="w-2/3" label="Print Folder" data-cy="single-print-folder" tooltip="Assign labels to designated folders."
                       placeholder="Please Enter Print Folder"></soludio-input>
        <soludio-input v-model="itemData.shipment_type" type="select" :options="shipmentTypes" option-value="value" option-label="label" class="w-2/3" data-cy="single-shipment-type" label="Shipment Type" placeholder="Please Enter Shipment Type"></soludio-input>
        <soludio-input v-model="itemData.sku_on_label" disabled type="select" tooltip-type="question" data-cy="single-sku-on-label" tooltip="SKU on label can only be selected as itself for the single product." :options="skuOnLabels" option-value="value" option-label="label" class="w-2/3" label="SKU On Label" placeholder="Please Enter SKU On Label"></soludio-input>
        <soludio-input v-model="itemData.shipping_label_sort_order_prefix" class="w-2/3" data-cy="single-sort-order" label="Shipping Label Sort Order Prefix"
                       placeholder="Please Enter Shipping Label Sort Order Prefix"></soludio-input>
      </div>
    </div>
    <div class="flex">
      <pg-button @click="save" :disabled="!formValidation" color="green" data-cy="single-save-button" class="w-32">Save</pg-button>
    </div>
  </div>
</template>

<script>
import Data from "@/views/items/item/forms/data";
import Methods from "@/views/items/item/forms/methods";

export default {
  name: "AddSingle",
  mixins: [Data, Methods],
  props: {
    close: {
      type: Function,
      default: ()=> {}
    },
    createItem: String,
  },
  data(){
    return {
      itemData: {
        type: 'single',
        sku: this.createItem,
        upc: null,
        description: null,
        external_sku_ref_list: "",
        assembly_packaging_type: null,
        length: 0,
        width: 0,
        height: 0,
        weight: 0,
        pack_group: {
          id: null
        },
        sku_on_label: "SELF",
        print_folder: null,
        shipping_label_sort_order_prefix: null,
        shipment_type: null
      },

    }
  },
  computed: {
    dimensionValidation(){
      if(!this.itemData) return false;
      return parseFloat(this.itemData.length) >= parseFloat(this.itemData.height) &&
      parseFloat(this.itemData.length) >= parseFloat(this.itemData.width)
    },

    formValidation(){
      return !!this.itemData?.sku &&
      !!this.itemData?.length &&
      !!this.itemData?.height &&
      !!this.itemData?.width &&
      !!this.itemData?.weight &&
      !!this.itemData?.pack_group &&
      !!this.itemData?.print_folder &&
        !!this.itemData?.shipment_type &&
        !!this.itemData?.sku_on_label &&
        !!this.itemData?.shipping_label_sort_order_prefix &&
      this.dimensionValidation
    }
  },

  methods: {
    save(){
      let {pack_group, ...data} = this.itemData
      data["pack_group_id"] = pack_group.id
      data["type"] = "single"
      data["assembly_packaging_type"] = null

      this.$store
        .dispatch("items/addItem", data)
        .then(() => {
          this.$swal({
            icon: "success",
            title: "Item Created Successfully",
            text: "Successfully Created",
          }).then(()=> {
            this.close()
          })
        })
        .catch((error) => {
          if (error.status && error.status === 205) {
            error = "Item Already Exist";
          }
          this.$swal({
            icon: "error",
            title: "Failed to create product",
            text: error || "An error occured",
          });
        });
    },
    },
}
</script>

<style scoped>

</style>
