import axios from "@/axios";
import requestsHelper from "@/services/helpers/requestsHelper";

export default {


  addItem(ignore, item) {
    return new Promise((resolve, reject) => {
      axios
        .post("/item/items/", item)
        .then(response => {
          if (response.status == 205) {
            reject(response);
          }
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  addItemFromSku(ignore, data) {
    let id = data.id;
    delete data.id;
    return new Promise((resolve, reject) => {
      axios
        .put("/order/order_lines/update_item/?id=" + id, data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  updateItemFromSku(ignore, data) {
    let id = data.id;
    delete data.id;
    return new Promise((resolve, reject) => {
      axios
        .put("/order/order_lines/update_item/?id=" + id, data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  fetchItems({ commit }, options) {
    return new Promise((resolve, reject) => {
      let helpers = new requestsHelper(options);
      let queryset = helpers.createQueryset();

      axios
        .get("/item/items/" + queryset)
        .then(response => {
          commit("SET_ITEMS", response.data.results);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  fetchSearchOriginalAndPartItems({ commit }, options) {
    return new Promise((resolve, reject) => {
      let helpers = new requestsHelper(options);
      let queryset = helpers.createQueryset();

      axios
        .get("/item/items/original_item_and_part_search/" + queryset)
        .then(response => {
          commit("SET_ITEMS", response.data.results);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  fetchSelectData({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get("/item/items/" + id + "/")
        .then(response => {
          commit("SET_SELECT_DATA", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  updateItem(ignore, item) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/item/items/` + item.id + "/", item)
        .then(response => {
          if (response.status == 205) {
            reject(response);
          }
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  removeItem(ignore, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/item/items/` + id + "/")
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  uploadItemFile(ignore, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/item/items/upload_item_file/", payload)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  get_item_inventory_detail(ignore, options) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          "/item_inventory/inventories/get_item_inventory_detail/?item=" +
          options.id
        )
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  checkExportItems() {
    return new Promise((resolve, reject) => {
      axios
        .get("/item/items/download_export_items/")
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  clearItems() {
    return new Promise((resolve, reject) => {
      axios
        .get("/item/items/clear_export_items/")
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  checkImportItemLogs() {
    return new Promise((resolve, reject) => {
      axios
        .get("/item/items/download_import_items/")
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  clearImportItemLogs() {
    return new Promise((resolve, reject) => {
      axios
        .get("/item/items/clear_import_items/")
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async searchItems(ignore, { value }) {
    const res = await axios.get("/item/items?search=" + value);
    return res.data;
  },

  uploadItemFileToMap(ignore, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("item/matching_tool_items/upload_item_matching_tool_file/", payload)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  uploadInventoryFile(ignore, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("item_inventory/inventories/ottomanson_stock_level/", payload)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchRules() {
    return new Promise((resolve, reject) => {
      axios
        .get("/item/matching_tool_items/list_item_matching_tool_rules/")
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  fetchMatchingItems(ignore, options) {
    return new Promise((resolve, reject) => {
      let {rules, ...filters} = options
      let helpers = new requestsHelper(filters);
      let queryset = helpers.createQueryset();
      axios
        .post("/item/matching_tool_items/list_matching_tool_items/" + queryset, {rules:rules})
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  sendFinalData(ignore, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/item/matching_tool_items/items_marked_as_matched/", payload)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  getRelatedMarketplaces(ignore, payload){
    return new Promise((resolve, reject) => {
      axios
        .post("/item/items/fetch_marketplace_item_info_marketplaces/", payload)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  getMarketplaceReferences(ignore, payload){
    return new Promise((resolve, reject) => {
      axios
        .post("/item/items/fetch_marketplace_references/", payload)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};
