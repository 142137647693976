import Spinners from './components'

export default {
  install (Vue, options = {}) {
    // Create component instance
    let Constr = Vue.extend(Spinners)
    let Spinner = new Constr()

    // Apply configuration
    Spinner.options = Object.assign(Spinner.options, options)

    // Mount it
    let vm = Spinner.$mount()

    // Add it to the Vue application
    document.querySelector('body').appendChild(vm.$el)

    // Create generic method
    const spinner = {
        flower: (options = {}) => {
          Spinner.reactivity("flower", options)
        },
        pixel: (options = {}) => {
          Spinner.reactivity("pixel", options)
        },
        hollowDots: (options = {}) => {
          Spinner.reactivity("hollow-dots", options)
        },
        intersectingCircles: (options = {}) => {
          Spinner.reactivity("intersecting-circles", options)
        },
        orbit: (options = {}) => {
          Spinner.reactivity("orbit", options)
        },
        radar: (options = {}) => {
          Spinner.reactivity("radar", options)
        },
        scalingSquares: (options = {}) => {
          Spinner.reactivity("scaling-squares", options)
        },
        halfCircle: (options = {}) => {
          Spinner.reactivity("half-circle", options)
        },
        trinityRings: (options = {}) => {
          Spinner.reactivity("trinity-rings", options)
        },
        fulfillingSquare: (options = {}) => {
          Spinner.reactivity("fulfilling-square", options)
        },
        circlesToRhombuses: (options = {}) => {
          Spinner.reactivity("circles-to-rhombuses", options)
        },
        semipolar: (options = {}) => {
          Spinner.reactivity("semipolar", options)
        },
        selfBuildingSquare: (options = {}) => {
          Spinner.reactivity("self-building-square", options)
        },
        swappingSquares: (options = {}) => {
          Spinner.reactivity("swapping-squares", options)
        },
        fulfillingBouncingCircle: (options = {}) => {
          Spinner.reactivity("fulfilling-bouncing-circle", options)
        },
        fingerprint: (options = {}) => {
          Spinner.reactivity("fingerprint", options)
        },
        spring: (options = {}) => {
          Spinner.reactivity("spring", options)
        },
        atom: (options = {}) => {
          Spinner.reactivity("atom", options)
        },
        loopingRhombuses: (options = {}) => {
          Spinner.reactivity("looping-rhombuses", options)
        },
        breedingRhombus: (options = {}) => {
          Spinner.reactivity("breeding-rhombus", options)
        },
        tableTop: (options = {}) => {
          Spinner.reactivity("table-top", options)
        },
        close: (duration = null) => {
          Spinner.close(duration)
        }
    }

    Vue.$spinner = Vue.prototype.$spinner = spinner
  }
}