<template>
  <div class="filters-container" :style="cssVariables">
      <slot name="filters" :direction="direction"></slot>
      <button  @click="filterAction" data-cy="apply" class="apply-button">{{applyText}}</button>
      <button v-if="!hideReset" @click="resetAction"  class="reset-button">Reset</button>
  </div>
</template>

<script>
export default {
    props:{
        filters:Object,
        applyText:{
            default:"Apply",
            type:String
        },
        direction:{
          default:"horizontal",
          type:String
        },
        dontSendRequestAtStart:Boolean,
        hideReset:Boolean,
        filterAction:{
            default: function (){console.log("Applied")},
            type:Function
        },
        resetAction:{
          default: function (){console.log("Reset")},
          type:Function
        },
        applyColor:{
            required:false,
            type:String,
        },
    },
    created(){
      if(!this.dontSendRequestAtStart){
        this.filterAction()
      }
    },
    computed:{
        cssVariables(){
            let applyColor = "#eb9316"
            if(this.applyColor == "success"){
                applyColor = "#33ad27"
            }

            let direction = "flex"
            if(this.direction == "vertical"){
              direction = "block"
            }

            return{
                "--apply-button-color":applyColor,
                "--reset-button-color":"rgb(98,98,98)",
                "--display-filters":direction,
                "--filter-width": this.direction === "vertical" ? "100%" : "none",
                "--filter-daterange-width": this.direction === "vertical" ? "100%" : "290px",
                "--filter-datepicker-padding": this.direction === "vertical" ? "7px" : "none",
                "--filter-button-padding": this.direction === "vertical" ? "15px" : "none"
            }
        }
    },
    methods:{
    }
}
</script>

<style lang="scss" scoped>

    .filters-container{
        display: var(--display-filters);
    }

    .apply-button{
        width: var(--filter-width); //bu ayar pg-filters-containers'dan geliyor
        display: block;
        float: left;
        min-width: 80px;
        padding-inline: 5px;
        height: 32px;
        color: #fff;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        font-size: 1.2em;
        font-weight: 500;
        cursor: pointer;
        border: none;
        background-color:var(--apply-button-color);
        border-radius: .35rem;
        outline: none;
        &:hover{
            opacity: .8;
        }
    }
    .reset-button{
      width: var(--filter-width); //bu ayar pg-filters-containers'dan geliyor
      display: block;
      float: left;
      min-width: 80px;
      margin-inline: 10px;
      height: 32px;
      color: #fff;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
      font-size: 1.2em;
      font-weight: 500;
      cursor: pointer;
      border: none;
      background-color:var(--reset-button-color);
      border-radius: .35rem;
      outline: none;
      &:hover{
        opacity: .8;
      }
    }
</style>
