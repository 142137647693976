<template>
  <div class="buttons-container">
    <div class="left-location">
        <template v-for="(button,key) in leftButtons">
                <process-button :key="key" v-if="button.type == 'multi'" :button="button" :buttons="buttons" @process="processData" @change="changeData"></process-button>
                <action-button :key="key" v-else :button="button" @process="processData" @change="changeData"></action-button>
        </template>
    </div>
    <div class="right-location">
        <template v-for="(button,key) in rightButtons">
                <process-button :key="key" v-if="button.type == 'multi'" :button="button" :buttons="buttons" @process="processData" @change="changeData"></process-button>
                <action-button :key="key" v-else :button="button" @process="processData" @change="changeData"></action-button>
        </template>
    </div>
  </div>
</template>

<script>
import ActionButton from "./components/action-button";
import ProcessButton from "./components/process-button";
export default {
  components:{
    ActionButton,
    ProcessButton
  },
  props: {
    buttons: Array
  },
  computed: {
    leftButtons() {
      return this.buttons && this.buttons.length > 0
        ? this.buttons.filter(v => v.location == "left")
        : [];
    },
    rightButtons() {
      return this.buttons && this.buttons.length > 0
        ? this.buttons.filter(v => v.location != "left")
        : [];
    }
  },
  methods:{
    processData(e){
      this.$emit('process',e)
    },
    changeData(e){
      this.$emit('change',e)
    }
  }
}
</script>

<style lang="scss" scoped>
.buttons-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom:15px;
}
.left-location {
  display: inline-flex;
}
.right-location {
  display: inline-flex;
}
</style>