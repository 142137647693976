<template>
  <div class="package-actions-container">
    <pg-button-dropdown color="#fdfdfd" text-color="dark" border title="Select an action" width="180px">
      <pg-button click-lock dropdown-button :disabled="createPackagesDisabled" @click.stop="createPackages">Create packages</pg-button>
      <pg-button click-lock dropdown-button :disabled="createLabelDisabled" @click.stop="createLabel">Create Label</pg-button>
      <pg-button click-lock dropdown-button :disabled="regeneratePackagesDisabled"  @click="openRegeneratePackagesModal">Regenerate packages</pg-button>
      <pg-button click-lock dropdown-button disabled>Download labels</pg-button>
    </pg-button-dropdown>


    <vs-prompt
      style="z-index:9999999999999999;"
      title="Regenerate Packages"
      @accept="regeneratePackages"
      :is-valid="regerateFormValid"
      :accept-text="'Apply'"
      :active.sync="regeneratePackagesModalShow"
    >
      <efsane-form v-model="pack_lines">
        <template v-for="(item,key) in pack_lines" >
          <select :key="key" :array-line="key" name="pack_group" :label="item.sku" type="search" placeholder="Please Choice Pack group" @input="changeValue" :options="filterPackgroups(item)" option-value="id" option-label="name" required/>
        </template>
      </efsane-form>
    </vs-prompt>
  </div>
</template>

<script>
import requestsHelper from "@/services/helpers/requestsHelper";

export default {
  name: "package-actions",
  data(){
      return{
        regeneratePackagesModalShow:false,
        packgroups:[],
        pack_lines:[...this.lines],
        fetchCount:0,
      }
  },
  props:{
    order:Object,
    orderSelectData:{
      type:Function,
      default:()=>{}
    },
    packages: {
      type:Array,
      default:function(){return []}
    },
    lines: {
      type:Array,
      default:function(){return []}
    },

  },
  computed:{
    regerateFormValid(){
      return !!this.pack_lines.every(v => v.pack_group)
    },
    createPackagesDisabled(){
      let statuses = this.packages.map((item) => item?.status?.status)
      return !this.lines.every(v => v.item) || !!this.packages.length || !statuses.every(v => v === 'awaiting_shipments')
    },
    createLabelDisabled(){
      let statuses = this.packages.map((item) => item?.status?.status)
      return !!this.packages.length && !statuses.every(v => v === 'awaiting_shipments')
    },
    regeneratePackagesDisabled(){
      let statuses = this.packages.map((item) => item?.status?.status)
      return !statuses.every(v => v === 'awaiting_shipments') || !this.packages.length
    },
    packgroupSearch(){
      let defaultItems = []
      if(this.pack_lines){
        this.pack_lines.forEach((item)=>{
          defaultItems.push([item.pack_group, item.name])
        })
      }
      return defaultItems.flat().join(",")
    }
  },
  created() {
    this.fetchPackGroups()
  },
  watch:{
    lines:{
      handler(newValue, oldValue){
        if(!newValue || !newValue.length){
          this.pack_lines = []
          return
        }
        if(newValue[0].id !== oldValue[0].id) {
          this.pack_lines = [...newValue]
          this.fetchPackGroups()
        }
      },
      deep:true
    }
  },
  methods:{
    changeValue(){
      this.fetchCount +=1
      if(this.fetchCount > 1){
        clearTimeout(this.timeout)
      }
      this.timeout = setTimeout(()=>{
        this.fetchPackGroups()
        this.fetchCount = 0
      },400)
    },
    fetchPackGroups() {
      let limit = 15 + (this.pack_lines.length || 0)
      this.$store.dispatch("packgroup/fetchItems", {search:this.packgroupSearch, offset: 0, limit}).then((response)=>{
        this.packgroups = response.data.results;
      })
    },
    filterPackgroups(item){
      // buraya kullanma amacımız ise packgroupSearch methoduna benziyor bu kullanıcıya
      // itemsları listelemek istiyoruz ama en alakalı sonucu listelemek istiyoruz.
      // bu yüzden alakalı resultları itemsın en başına alıyoruz
      return [...this.packgroups.filter(v => v.id.toString().indexOf(item.pack_group) > -1 ||
        v.name.toString().indexOf(item.pack_group) > -1), ...(item.pack_group && this.packgroups || [] )].filter((v,i,a)=>a.findIndex(v2=>(v2.id===v.id))===i)
    },
    openRegeneratePackagesModal(){
        this.regeneratePackagesModalShow = true
    },
    regeneratePackages(){
      this.$store
        .dispatch("orders/regeneratePackages", {lines:this.pack_lines,order_id:this.order.id})
        .then(() => {
          this.$swal({
            icon: "success",
            title: "Regenerate Packages Request",
            text: "Succesfully Sent",
          }).then(()=>{
            this.orderSelectData()
          })
        })
        .catch((error) => {
          if(error.status && error.status === 205){
            error = "This order is protected for a time. After a while, do the Create Packages operation."
          }

          this.$swal({
            icon: "error",
            title: "Regenerate Packages Request",
            text: error,
          });
        });

    },
    async createPackages() {
      let obj = {ids:[this.order.id]};
      await this.$store
        .dispatch("orders/createPackages", obj)
        .then(() => {
          this.$swal({
            icon: "success",
            title: "Create Packages Request",
            text: "Succesfully Sent",
          }).then(()=>{
            this.orderSelectData()
          })
        })
        .catch((error) => {
          if(error.status && error.status === 205){
            error = "This order is protected for a time. After a while, do the Create Packages operation."
          }
          this.$swal({
            icon: "error",
            title: "Create Packages Request",
            text: error,
          });
        });
    },
    async createLabel() {
      this.$store
        .dispatch("orders/getTrackingNumber", { ids: [this.order.id] })
        .then(() => {
          this.$swal({
            icon: "success",
            title: "Create Label",
            text: "Started to Get Tracking Number Process",
          }).then(()=>{
            this.orderSelectData()
          })
        })
        .catch(() => {
          this.$swal({
            icon: "error",
            title: "Get Tracking Number Error",
            text: "",
          });
        });
    },
  }
}
</script>

<style lang="scss" scoped>

.package-actions-container{
  margin-inline: 50px;
  display: flex;
  padding-block: 10px;
  gap: 10px;
}
</style>
