import axios from '@/axios'
import requestsHelper from "@/services/helpers/requestsHelper";

export default {
  fetchOrdersChartData(ignore, options) {
    let helpers = new requestsHelper(options);
    let queryset = helpers.createQueryset();

    return new Promise((resolve, reject) => {
      axios
        .get("/order/orders/chart_data_list/"+ queryset)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
}