<template>
  <div @click="$emit('click')" class="h-20 w-80 bg-white w-full rounded select-none shadow flex justify-evenly hover:bg-gray-100 items-center cursor-pointer">
    <div class="w-1/2 flex flex-col items-center " >

      <div class="flex items-center justify-center">
        <soludio-image name="warehouse" class="h-8 w-8"></soludio-image>
      </div>
      <div class=" flex flex-col items-center justify-center truncate ">
        <span class="font-middle text-sm w-32 text-center">{{ warehouse | title }}</span>
      </div>
    </div>

    <div class="w-1/2 flex flex-col items-center justify-center" >
      <div><span class="font-semibold px-1">{{ quantity }}</span> <span class="text-xs">Quantity</span></div>
    </div>
    <pg-icon class="arrow-up opacity-50 mr-2" style="transform: rotate(90deg);" name="arrow-up" height="18"></pg-icon>

  </div>

</template>

<script>
export default {
  name: "DefaultItemInventory",
  props: {
    warehouse: String,
    quantity: [Number, String]
  },
}
</script>

<style scoped>

</style>

