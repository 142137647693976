import Vue from 'vue'
import VxTooltip from './components/vx-tooltip/VxTooltip'
import FeatherIcon  from './components/FeatherIcon'
import VsUpload  from './components/vs-upload/VSUpload'
import VxCard  from './components/vx-card/VxCard'
import VueApexCharts from 'vue-apexcharts'
import PgActions from "./components/pg-actions"
import TagInput from "./components/pg-tag-input/tag-input"
import Search from "./components/pg-search"
import PgSelectSearch from "./components/pg-elements/pg-select-search"
import Accordion from "./components/pg-accordion"
import PgInput from "./components/pg-elements/pg-input"
import PgSelect from "./components/pg-elements/pg-select"
import PgToastExport from "./components/pg-toast-export"
import PgFiltersContainer from "./components/pg-filters-container"
import PgFilterCheckbox from "./components/pg-filter-checkbox"
import PgFilterSelectbox from "./components/pg-filter-selectbox"
import PgFilterDatepicker from "./components/pg-filter-datepicker"
import PgFilterDaterange from "./components/pg-filter-daterange"
import PgFilterSearch from "./components/pg-filter-search"
import EfsaneTable2 from "./components/efsane-table"
import PgSwitch from "./components/pg-elements/pg-switch"
import PgRightBar from "./components/pg-rightbar"
import PgOrderDetail from "./components/pg-order-detail"
import PgItemDetail from "./components/pg-item-detail"
import PgButton from "./components/pg-elements/pg-button"
import PgButtonDropdown from "./components/pg-elements/pg-button-dropdown"
import PgButtonGroup from "./components/pg-elements/pg-button-group"
import EfsaneForm from "./components/efsane-form-elements/efsane-form"
import EfsaneInput from "./components/efsane-form-elements/efsane-input"
import EfsaneSelect from "./components/efsane-form-elements/efsane-select"
import EfsaneSwitch from "./components/efsane-form-elements/efsane-switch"
import EfsaneSearchSelect from "./components/efsane-form-elements/efsane-search-select"
import EfsaneLegend from "./components/efsane-form-elements/efsane-legend"
import EfsaneTextarea from "./components/efsane-form-elements/efsane-textarea"
import EfsaneButton from "./components/efsane-form-elements/efsane-button"
import PgRadioSwitch from "./components/pg-radio-switch"
import PgRadioSwitchIntegration from "./components/pg-radio-switch-integration"
import PgDragDropUpload from "./components/pg-drag-drop-upload"
import EfsaneTable from "efsane-table"
import Tab from "./components/tabs/tab"
import Tabs from "./components/tabs/tabs"
import PgIcon from "./components/pg-icon"
import ImageZoomModal from "./components/image-zoom-modal"
import SoludioInput from "./components/soludio-input/Index.vue"
import SoludioImage from "./components/soludio-image/Index.vue"

Vue.component(VxTooltip.name, VxTooltip)
Vue.component(FeatherIcon.name, FeatherIcon)
Vue.component(VsUpload.name, VsUpload)
Vue.component('TagInput', TagInput)
Vue.component(VxCard.name, VxCard)
Vue.component('PgSearch',Search)
Vue.component('PgSelectSearch',PgSelectSearch)
Vue.component('PgAccordion',Accordion)
Vue.component('PgActions',PgActions)
Vue.component('apexchart', VueApexCharts)
Vue.component('PgInput',PgInput)
Vue.component('PgSelect',PgSelect)
Vue.component('PgToastExport',PgToastExport)
Vue.component('PgFiltersContainer',PgFiltersContainer)
Vue.component('PgFilterCheckbox',PgFilterCheckbox)
Vue.component('PgFilterSelectbox',PgFilterSelectbox)
Vue.component('PgFilterDatepicker',PgFilterDatepicker)
Vue.component('PgFilterDaterange',PgFilterDaterange)
Vue.component('PgFilterSearch',PgFilterSearch)
Vue.component('PgSwitch',PgSwitch)
Vue.component('PgRightBar',PgRightBar)
Vue.component('PgOrderDetail',PgOrderDetail)
Vue.component('PgItemDetail',PgItemDetail)
Vue.component('EfsaneTable',EfsaneTable)
Vue.component('PgButton',PgButton)
Vue.component('PgButtonDropdown',PgButtonDropdown)
Vue.component('PgButtonGroup',PgButtonGroup)
Vue.component('EfsaneForm',EfsaneForm)
Vue.component('EfsaneInput',EfsaneInput)
Vue.component('PgRadioSwitch',PgRadioSwitch)
Vue.component('PgRadioSwitchIntegration', PgRadioSwitchIntegration)
Vue.component('PgDragDropUpload',PgDragDropUpload)
Vue.component('EfsaneSelect',EfsaneSelect)
Vue.component('EfsaneSwitch',EfsaneSwitch)
Vue.component('EfsaneSearchSelect',EfsaneSearchSelect)
Vue.component('EfsaneLegend',EfsaneLegend)
Vue.component('EfsaneTextarea',EfsaneTextarea)
Vue.component('EfsaneButton',EfsaneButton)
Vue.component('EfsaneTable2',EfsaneTable2)
Vue.component('Tab', Tab)
Vue.component('Tabs', Tabs)
Vue.component('PgIcon', PgIcon)
Vue.component('ImageZoomModal', ImageZoomModal)
Vue.component('SoludioInput', SoludioInput)
Vue.component('SoludioImage', SoludioImage)


// v-select component
import vSelect from 'vue-select'

// Set the partials prop default to return our fresh partials
vSelect.props.components.default = () => ({
  Deselect: {
    render: createElement => createElement('feather-icon', {
      props: {
        icon: 'XIcon',
        svgClasses: 'w-4 h-4 mt-1'
      }
    }),
  },
  OpenIndicator: {
    render: createElement => createElement('feather-icon', {
      props: {
        icon: 'ChevronDownIcon',
        svgClasses: 'w-5 h-5'
      }
    }),
  },
});

Vue.component(vSelect)
