<template>
  <div>
    <package-form
      :package-data="packageObj"
      :order-select-data="orderSelectData"
      :order="order"
    ></package-form>
    <h4 class="package-lines-title">Package Lines</h4>
    <efsane-table
      class="package-lines-table"
      :data="pkg.lines"
      :default-columns="linesColumns"
      table-name="order-detail-packages-lines"
      accordion-mechanic="only-one"
      accordion
    >
      <template slot="__dimensions" slot-scope="slotData">
        <span v-if="slotData.slotScope.depth"
          >{{ slotData.slotScope.length }}x{{ slotData.slotScope.width }}x{{
            slotData.slotScope.depth
          }}
        </span>
        <span v-else
          >{{ slotData.slotScope.length }}x{{ slotData.slotScope.width }}x{{
            slotData.slotScope.height
          }}
        </span>
      </template>
      <template slot="__item" slot-scope="slotData">
        <span>{{
          slotData.slotScope.item && slotData.slotScope.item.sku
        }}</span>
      </template>
      <template slot="__more" slot-scope="slotData">
        <PackageLine :slotData="slotData" :pkg="pkg"></PackageLine>
      </template>
    </efsane-table>
  </div>
</template>

<script>
import PackageLine from "@/components/pg-order-detail/partials/package-line";
import packageForm from "@/components/pg-order-detail/partials/package-form";
import {getShippingCompanies} from "@/views/shippingCompanies/store/getters";

export default {
  name: "PackageDetail",
  props: {
    order: Object,
    pkg: {
      type: Object,
    },
    orderSelectData: {
      type: Function,
      default: () => {},
    },
  },
  components: {
    PackageLine,
    packageForm,
  },
  data() {
    return {
      shippingCompanies:[],
      packageObj: {},
      linesColumns: [
        {
          type: "more",
          header: "Update",
          size: "50",
        },
        {
          type: "row_number",
          header: "Line",
          size: "50",
        },
        {
          type: "data",
          header: "SKU",
          name: "sku",
          copyable: true,
          size: "2fr",
        },
        {
          type: "data",
          header: "Quantity",
          name: "quantity",
          size: "1fr",
        },
        {
          type: "data",
          header: "Pack Group",
          name: "pack_group_name",
          size: "1fr",
        },
        {
          type: "slot",
          header: "Dimensions(LxWxH)",
          name: "__dimensions",
          size: "1fr",
        },
        {
          type: "data",
          header: "Weight",
          name: "weight",
          size: "1fr",
        },
        {
          type: "data",
          header: "Unit Cost",
          name: "unit_cost",
          size: "1fr",
        },
        {
          type: "slot",
          header: "Item",
          name: "__item",
          size: "1fr",
        },
      ],
      packageColumns: [
        {
          type: "more",
          header: "Update",
          size: "50",
        },
        {
          type: "row_number",
          header: "Line",
          size: "50",
        },
        {
          type: "data",
          header: "Tracking Number",
          name: "tracking_number",
          copyable: true,
          size: "2fr",
        },
        {
          type: "data",
          header: "Carrier",
          name: "carrier",
          copyable: true,
          size: "2fr",
        },
        {
          type: "data",
          header: "Ship Method",
          name: "ship_method",
          copyable: true,
          size: "2fr",
        },
        {
          type: "data",
          header: "Shipping Cost",
          name: "shipping_cost",
          copyable: true,
          size: "2fr",
        },
        {
          type: "data",
          header: "Shipping Rate",
          name: "shipping_rate",
          copyable: true,
          size: "2fr",
        },
      ],
    };
  },
  created() {
    this.getPackage();
  },
  methods: {
    async getPackage() {
      const res = await this.$store.dispatch("packages/getPackage", {
        id: this.pkg.id,
      });
      this.packageObj = res;
    },
  },
};
</script>

<style lang="scss">
.package-lines-title{
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  margin-left: 1.8rem;
}
.package-lines-table{
  margin-left: 1.5rem;
}
</style>
