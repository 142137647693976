<template>
  <div class="packages-timeline-container">
    <sticky-header title="Packages">
      <template slot="icon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24"
          width="24"
          viewBox="0 0 512 512"
        >
          <title>Packages</title>
          <path
            d="M448 341.37V170.61A32 32 0 00432.11 143l-152-88.46a47.94 47.94 0 00-48.24 0L79.89 143A32 32 0 0064 170.61v170.76A32 32 0 0079.89 369l152 88.46a48 48 0 0048.24 0l152-88.46A32 32 0 00448 341.37z"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="32"
          />
          <path
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="32"
            d="M69 153.99l187 110 187-110M256 463.99v-200"
          />
        </svg>
      </template>
      <template slot="dropdown">
        <package-actions :order="order" :packages="order.packages" :lines="order.lines"></package-actions>
      </template>
      <template slot="progress">
        <package-progress
          :create-packages-done="!!packages.length"
          :create-label-done="
            !!packages.every((v) => v.tracking_number) && !!packages.length
          "
          :create-label-warning="
            !!packages.some((v) => v.tracking_number) && !!packages.length
          "
        ></package-progress>
      </template>
    </sticky-header>

    <tabs v-if="packages.length">
      <tab name="Overview" :selected="true" @package="packageData($event)">
        <efsane-table
          :data="packages"
          :default-columns="columns"
          table-name="order-detail-packages"
        >
          <template slot="__dimensions" slot-scope="slotData">
            <span
              >{{ slotData.slotScope.length }}x{{ slotData.slotScope.width }}x{{
                slotData.slotScope.height
              }}</span
            >
          </template>
          <template slot="__expected_ship_date" slot-scope="slotData">
            <span>{{
              slotData.slotScope.expected_ship_date | formatDate
            }}</span>
          </template>
          <template slot="__required_delivery_date" slot-scope="slotData">
            <span>{{
              slotData.slotScope.required_delivery_date | formatDate
            }}</span>
          </template>
          <template slot="__status" slot-scope="slotData">
            <span v-html="getStatus(slotData.slotScope.status)"></span>
          </template>
          <template slot="__status.cancel_reason" slot-scope="slotData">
            <span v-html="getCancelReasons(slotData.slotScope.status)"></span>
          </template>
          <template slot="__status.insurance_provider" slot-scope="slotData">
            <span>
              <pg-button v-if="!slotData.slotScope.insurance_provider" color="success" size="small" @click="showAddInsuranceModal(slotData.slotScope.id)">Add</pg-button>
              <span v-else>{{slotData.slotScope.insurance_provider}}</span>
            </span>
          </template>
        </efsane-table>
      </tab>
      <tab
        :name="getTabName(pkg, key)"
        v-for="(pkg, key) in packages"
        :key="key"
      >
        <package-detail :pkg="pkg" :order-select-data="orderSelectData" :order="order"></package-detail>
      </tab>
    </tabs>
    <div v-else>Packages Not Found</div>


    <vs-prompt
      title="Add Insurance"
      style="z-index: 9999999"
      :is-valid="isInsuranceValid"
      @accept="addInsurance"
      :active.sync="addInsuranceModalVisibility"
    >
      <div>
        <efsane-form v-if="insuranceData" v-model="insuranceData">
          <select name="insurance_provider" placeholder="Please Select Insurance Provider"
                  :options="visibleInsuranceProviders" option-label="label" option-value="value"  type="text"
                  label="Insurance Provider"/>
          <input name="insured_value"   type="number" step="0.01" label="Insured Value"/>
        </efsane-form>
      </div>
    </vs-prompt>
  </div>
</template>

<script>
import PackageProgress from "@/components/pg-order-detail/partials/package-progress";
import StickyHeader from "@/components/pg-order-detail/partials/sticky-header";
import PackageDetail from "@/components/pg-order-detail/partials/package-details";
import PackageActions from "@/components/pg-order-detail/partials/package-actions";

export default {
  name: "packages-timeline",
  components: {
    StickyHeader,
    PackageDetail,
    PackageProgress,
    PackageActions,
  },
  props: {
    order:Object,
    packages: {
      type: Array,
      default: () => {
        return [];
      },
    },
    order: Object,
    orderSelectData: {
      type: Function,
      default: () => {},
    }
  },
  data() {
    return {
      pkge: {},
      addInsuranceModalVisibility:false,
      insuranceData: {
        insurance_provider:null,
        insured_value:null,
      },
      insurancePackageId:null,
      insuranceProviders: [
        {value: "parcel_guard", label: "Parcel Guard Insurance", carrier: "stamps"},
      ],
      columns: [
        {
          type: "row_number",
          header: "Line",
          size: "50",
        },
        {
          type: "more",
          name: "__more",
          header: "Package Details",
          size: "50",
        },
        {
          type: "data",
          header: "Tracking Number",
          name: "tracking_number",
          copyable: true,
          size: "2fr",
        },
        {
          type: "slot",
          header: "Req. Delivery Date",
          name: "__required_delivery_date",
          size: "1fr",
        },
        {
          type: "data",
          header: "Carrier",
          name: "carrier",
          size: "1fr",
        },
        {
          type: "data",
          header: "Ship Method",
          name: "ship_method",
          size: "1fr",
        },
        {
          type: "data",
          header: "Shipping Cost",
          name: "shipping_cost",
          size: "1fr",
        },
        {
          type: "slot",
          header: "Status",
          name: "__status",
          size: "2fr",
        },
        {
          type: "slot",
          header: "Cancel Reason",
          name: "__status.cancel_reason",
          visibility: "exists",
          visibilityCondition: "status.cancel_reason",
          size: "2fr",
        },
        {
          type: "slot",
          header: "Parcel Ins.",
          name: "__status.insurance_provider",
          size: "1fr",
        },
      ],
      statuses: [
        {
          value: "awaiting_shipments",
          name: "Awaiting Shipments",
          color: "#5276ee",
        },
        { value: "label_created", name: "Label Created", color: "#4ee1bf" },
        { value: "printed", name: "Printed", color: "#adacac" },
        { value: "in_transit", name: "In Transit", color: "#516367" },
        { value: "delivered", name: "Delivered", color: "#38a819" },
        { value: "needs_attention", name: "Need Attention", color: "#e78f17" },
        { value: "put_on_hold", name: "Orders On Hold", color: "#149abe" },
        { value: "canceled", name: "Canceled", color: "#ee4545" },
      ],
      cancelReasons: [],
    };
  },
  mounted(){
    this.fetchCancelReasons();
  },
  computed: {
    visibleInsuranceProviders(){
      return this.insuranceProviders.filter(v => v.carrier === this.order.carrier)
    },
    isInsuranceValid(){
      return Object.values(this.insuranceData).every(v => v)
      }
  },
  methods: {
    addInsurance(id){
      if (!Object.keys(this.insuranceData).length) {
        return;
      }
      this.$store
        .dispatch("packages/partialUpdatePackage", {
          package_Id: this.insurancePackageId,
          formData: this.insuranceData,
        })
        .then(() => {
          this.$swal({
            icon: "success",
            title: "Added Insurance",
            text: "Successfully Sent",
          }).then(() => {
            this.orderSelectData();
          });
        });
    },
    showAddInsuranceModal(id){
      this.insurancePackageId = id
      this.addInsuranceModalVisibility = true
    },
    getStatus(data) {
      if (!data) return;
      let currentStatus = this.statuses.find((v) => v.value === data.status);
      return `<span class="status-area" style="background-color: ${currentStatus?.color};">${currentStatus?.name}</span>`;
    },
    getCancelReasons(data) {
      if (!data) return;
      let currentReason = this.cancelReasons.find(
        (v) => v.value === data.cancel_reason
      );
      return `<span class="status-area" style="background-color: ${currentReason?.color};">${currentReason?.name}</span>`;
    },
    getTabName(pkg, key) {
      if (pkg.tracking_number) {
        return `Package ${key + 1} (${pkg.tracking_number || "-"})`;
      }
      return `Package ${key + 1}`;
    },
    async fetchCancelReasons() {
      let obj = {marketplace: this.order.marketplace};
      await this.$store
        .dispatch("orders/fetchCancelReasons", obj)
        .then((response) => {
          this.cancelReasons = response.data.reasons;
          this.cancelReasons.forEach((reason)=>{
            reason["color"] = "#ee4545";
          })

        })
    },
  },
};
</script>

<style lang="scss">
.status-area {
  padding: 2px 4px;
  border-radius: 0.4em;
  color: #fff;
}
.packages-timeline-container {
  padding-bottom: 15px;
  background-color: #fdfdfd;
  padding-block: 60px;
  padding-inline: 30px;
  scroll-margin-top: 50px;
  margin-inline: 50px;
  border: 1px dashed rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin-bottom: 50px;
}
.package-actions-container{
  margin-inline: 50px;
  display: flex;
  padding-block: 10px;
  gap: 10px;
  margin-top: -10px;
}
</style>
