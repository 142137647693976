<template>
  <div class="middle-content-container">
    <errors-timeline
      :order="order"
    ></errors-timeline>

    <order-timeline
      :order="order"
      :lines="order.lines"
      :order-select-data="orderSelectData"
    ></order-timeline>
    
    <packages-timeline :packages="order.packages" :order-select-data="orderSelectData" :order="order" :lines="order.lines"></packages-timeline>
    
    <items-timeline :items="order.items" :order-select-data="orderSelectData"></items-timeline>
    
    <integrations-timeline
      :order="order"
      :order-select-data="orderSelectData"
      :integrations="order.integrations"
    ></integrations-timeline>
    
    
  </div>
</template>

<script>
import OrderTimeline from "@/components/pg-order-detail/partials/order-timeline";
import PackagesTimeline from "@/components/pg-order-detail/partials/packages-timeline";
import IntegrationsTimeline from "@/components/pg-order-detail/partials/integrations-timeline";
import ItemsTimeline from "@/components/pg-order-detail/partials/items-timeline";
import ErrorsTimeline from "@/components/pg-order-detail/partials/errors-timeline";
import PackageActions from "@/components/pg-order-detail/partials/package-actions";
import OrderActions from "@/components/pg-order-detail/partials/order-actions";
import IntegrationActions from "@/components/pg-order-detail/partials/integration-actions";
import ItemActions from "@/components/pg-order-detail/partials/item-actions";
export default {
  name: "middle-content",
  components: {
    OrderTimeline,
    PackagesTimeline,
    IntegrationsTimeline,
    ItemsTimeline,
    ErrorsTimeline,
    PackageActions,
    OrderActions,
    IntegrationActions,
    ItemActions,
  },
  props: {
    order: Object,
    orderSelectData: {
      type: Function,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
.middle-content-container {
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: #ccc;
  width: calc(100% - 400px);
  background-color: #fff;
  border-top: 20px solid #fff;

  &::-webkit-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #ddd;
  }
}
</style>
