<template>
  <div v-if="isActive">
    <slot></slot></div>
</template>

<script>
export default {
  name: "tab",

    props: {
      name: { required: true },
      componentKey: {
        type: [String, Number],
        default: 1
      },
      selected: { default: false},
      image:{
        required:false,
        type:String
      },
      base64Icon: {
        required:false,
        type:String
      }
    },

    data() {

      return {
        isActive: false
      };

    },

    computed: {

      href() {
        return '#' + this.name.toLowerCase().replace(/ /g, '-');
      },
      imageSrc() {
        return this.image ? this.image : this.base64Icon ? this.base64Icon : null;
      }
    },

    mounted() {

      this.isActive = this.selected;

    }
}
</script>

<style scoped>

</style>
