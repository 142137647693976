<template>
  <div class="switch-area" :style="styles">
    <label class="type-label" :class="{'selected':item.value === modelValue}" v-for="(item,key) in options" :key="key">
      <pg-icon v-if="item.iconName" class="radio-icon" :name="item.iconName" height="24"> </pg-icon>
      <input :name="elementName" type="radio" :value="item.value" v-model="modelValue">
      <span>{{ item.label }}</span>
    </label>
  </div>
</template>

<script>
export default {
  name: "pg-radio-switch",
  data(){
    return {
      modelValue:this.value,
      elementName:this.makeId()
    }
  },
  props:{
    value: {},
    fontSize: String,
    options:{
      type:Array,
      default:()=>{return []}
    }
  },
  computed:{
    styles(){
      return{
        "--pg-radio-count": this.options.length || 0,
        "--pg-radio-font-size": this.fontSize || "1.2em",
      }
    }
  },
  watch:{
    modelValue(newValue){
      this.$emit('input', newValue)
    }
  },
  methods:{
    makeId(){
      let length = Math.floor(Math.random() * 30)
      let result           = ["id"];
      let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let charactersLength = characters.length;
      for ( let i = 0; i < length; i++ ) {
        result.push(characters.charAt(Math.floor(Math.random() *
          charactersLength)));
      }
      return result.join('');
    }
  }
}
</script>

<style lang="scss" scoped>

.switch-area{
  border: 1px solid #ddd;
  border-radius: 32px;
  background-color: #F7F7F7;
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns:  repeat(var(--pg-radio-count), 1fr);

  .type-label{
    height: 100%;
    display: inline-flex;
    border-radius: 32px;
    justify-content: center;
    position: relative;
    align-items: center;
    cursor: pointer;
    text-align: center;

    input{
      position: absolute;
      width: 0;
      height: 0;
    }
    .radio-icon{
      margin-right: 10px;
    }
    span{
      font-size: calc(var(--pg-radio-font-size) / .6);
      @media (min-width: 768px) {
        font-size: var(--pg-radio-font-size);
      }
    }
    &.selected{
      background-color: #fff;
      box-shadow: 3px 10px 20px rgba(0,0,0,.1);
    }
    &:hover:not(.selected){
      background-color: #EBEBEB;
    }
  }
}
</style>
