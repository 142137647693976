<template>
  <div class="pg-detail-wrapper-mask" v-if="show && order" :style="styles">
    <div class="pg-detail-wrapper" v-click-outside-popup="close">
      <left-side :order.sync="order" :before-order="beforeOrder" :after-order="afterOrder" :id.sync="currentId"></left-side>
      <middle-content :order.sync="order" :order-select-data="orderselectData"></middle-content>
      <right-side :order.sync="order"  :close="close" :order-select-data="orderselectData"></right-side>
    </div>
  </div>
</template>

<script>
//mixins
import Computed from "@/components/pg-order-detail/computed";
import StylesComputed from "@/components/pg-order-detail/stylesComputed";
import Watch from "@/components/pg-order-detail/watch";
import Data from "@/components/pg-order-detail/data";
import Emits from "@/components/pg-order-detail/emits";
import Methods from "@/components/pg-order-detail/methods";
import LifecycleMethods from "@/components/pg-order-detail/lifecycleMethods";
import LeftSide from "@/components/pg-order-detail/partials/left-side";
import RightSide from "@/components/pg-order-detail/partials/right-side";
import MiddleContent from "@/components/pg-order-detail/partials/middle-content";
import Directives from "@/components/pg-order-detail/directives";
export default {
  name: "pg-detail",
  mixins: [Computed,StylesComputed,Watch,Data,Emits,Methods, LifecycleMethods, Directives],
  props:{
    show:Boolean,
    value:[Number,String],
    orders:{
      type:Array,
      default:()=>{return []}
    }
  },
  components:{
    LeftSide,
    RightSide,
    MiddleContent
  }
}
</script>

<style lang="scss" scoped>
  @import url('https://fonts.googleapis.com/css2?family=Bai+Jamjuree:wght@200&display=swap');
  .pg-detail-wrapper-mask{
    position: fixed;
    inset: 0;
    z-index: 52010;
    background-color: rgba(0,0,0,.3);
    .pg-detail-wrapper{
      font-family: 'Bai Jamjuree', sans-serif;
      margin-left: 4%;
      margin-top: 2%;
      height: 92%;
      width: 94%;
      background-color: #fff;
      box-shadow: 0 3px 5px rgba(0,0,0,.1);
      border-radius: 5px;
      display: flex;
    }
  }
</style>
