<template>
    <table class="filter-dropdown-table" :style="styles">
      <label class="select-all-row" v-if="!data.length" >No Option Available</label>
      <label class="select-all-row" v-if="allSelection && data.length > 1"  @click="selectAll">Select All</label>
      <template v-if="data.length > 1">
        <label :for="'filter-checkbox'+ind" class="pg-filter-checkbox" v-for="(row,ind) in data" :key="ind">
          <tr    v-bind:class="{'select-tr':checkList && checkList.includes(ind)}">
            <td :key="tdKey" >
              <checkbox :name="'filter-checkbox'+ind" class="checkbox-input">
                <input slot="checkbox-input" type="checkbox" :value="ind" v-model="checkList" :id="'filter-checkbox'+ind"/>
              </checkbox>
            </td>
            <td v-if="row.img"> <img :src="row.img" height="15px" style="margin:0 auto;"  :alt="row.name"> </td>
            <td v-else-if="row.icon">
              <feather-icon
                :icon="row.icon"
                svgClasses="w-5 h-5 hover:text-primary stroke-current"
              /> </td>
            <td>{{row[dropdownShow]}}</td>

          </tr>
        </label>
      </template>
    </table>
</template>

<script>
import Checkbox from "./checkbox"
export default {
  components: {Checkbox},

  props:{
      value:{},
      dropdownShow:{
        type:String,
        default:"name"
      },
      allSelection:Boolean,
      dropdownValue:{
        type:String,
        default:"value"
      },
      data: {
        type:Array,
        default:function (){
          return []
        }
      }
    },
    data(){
        return{
            checkList:[],
            tdKey:0,
            selectedAll:false
        }
    },
    computed:{
        selected() {
          let selected = []
          this.data.map((value, index) => {
            if (this.checkList.includes(index)) {
              selected.push(value)
            }
          })
          return selected
        },
        styles(){
          return {
            "--pg-filter-checkbox-table-tr-grid-columns": this.data.some(v => v.img || v.icon) ? "50px 50px auto": "50px auto"
          }
        }
    },
    created() {
          this.valueChanged()
    },
    watch:{
      selected(newV){
        this.$emit('input',newV)
      },
      data:{
        handler(){
          this.valueChanged()
        },
        deep:true
      }

    },
    methods:{
        selectAll(){
          if(this.data.length === this.checkList.length){
            this.checkList = []
            return
          }
          this.checkList = Array.from({length: this.data.length}, (_, i) => i)
        },
        valueChanged(){
          let selectedValues = []
          if(this.value){
            if(!Array.isArray(this.value)){
              selectedValues = [this.value]
            }else{
              selectedValues = this.value
            }
            this.data.forEach((value, key)=>{
              if(selectedValues.includes(value[this.dropdownValue])){
                this.checkList.push(key)
              }
            })
          }
        },
         changeValue(index){
              if(this.checkList.includes(index)){
                  let key = this.checkList.findIndex(v => v === index)
                  this.checkList.splice(key,1)
              }else{
                this.checkList.push(index)
              }
        }
    }
}
</script>

<style lang="scss" scoped>

    .filter-dropdown-table{
        width: 100%;
        margin-top: 10px;
        text-align: left;
        padding: 5px ;
        border-spacing: 0;

      label{
          width: 100%;
          display: flex;

        }
      .select-all-row{
        width: 100%;
        text-align: left;
        cursor: pointer;
        padding: 5px;
      }
    }

    .filter-dropdown-table tr{
      width: 100%;
      position: relative;
      padding-left: 70px;
    }
    .filter-dropdown-table tr:not(:nth-child(1)):not(:nth-last-child(1)){
      border-bottom: 1px solid #F0F0F0;
    }

    .filter-dropdown-table tr:not(:nth-child(1)){
        border-bottom: 1px solid #F0F0F0;
        overflow-x: hidden;
        display: grid;
        width: 100%;
        grid-template-columns: var(--pg-filter-checkbox-table-tr-grid-columns);
        align-items: center;
        gap: 5px;
        justify-content: space-evenly;

    }

    .filter-dropdown-table tr td:nth-child(1){
      position: absolute;
      top: 0;
      left: 0;
    }
    .filter-dropdown-table tr td:nth-last-child(1){
        padding-left: 15px;
        text-align: left;
    }

    .filter-dropdown-table tr:hover{
        background: #F0F0F0;
    }

    .select-tr{
        background: rgba(0,0,0,0.1)  !important;
    }

    .filter-dropdown-table tr td:nth-child(1){
        width: 15%;
        padding-right: 10px;
    }

</style>
