<template>
  <div class="relative efsane-basic-select-container">
    <label :for="elementId" class="flex items-center gap-1 pl-1 mb-1 font-semibold">
      {{ label }}
      <soludio-tooltip v-if="tooltip" :type="tooltipType" :tooltip="tooltip"></soludio-tooltip>
    </label>
    <select
            v-bind="filterAttrs"
            type="text"
            :data-cy="dataCy"
            :value="value"
            @input="(e)=>{$emit('input',e.target.value)}"
            :id="elementId"
    >  <option :value="placeholderValue" v-if="placeholder" disabled>
      {{ placeholder }}
    </option>
      <option :value="setValue(option)" :selected="setValue(option) === value" v-for="(option, key) in fixedOptions" :key="key">{{setLabel(option)}}</option>
    </select>
  </div>
</template>

<script>
import SoludioTooltip from "@/components/soludio-input/partials/SoludioTooltip.vue";
export default {
  name: "soludio-text-input",
  components: {
    SoludioTooltip
  },
  props: {
    id: String,
    label: String,
    tooltip: String,
    tooltipType: String,
    optionLabel: String,
    optionValue: String,
    placeholder: String,
    dataCy: String,
    value:{},
    options: {
      type: [Array, String],
    },
    size: {
      type: String,
      default: "base",
      options: ["large", "base", "small"],
    },
  },
  data() {
    return {
      elementId: this.id || this.$helper.makeId(),
    };
  },
  computed:{
    placeholderValue() {
      let values = this.fixedOptions.map((option) => this.setValue(option));
      if (
        !values.includes(
          !isNaN(this.modelValue) ? Number(this.modelValue) : this.modelValue
        )
      ) {
        return this.modelValue;
      }
      return "";
    },
    fixedOptions() {
      if (!Array.isArray(this.options)) {
        return this.options.split(",").map((v) => v.trim());
      }
      return this.options;
    },
    filterAttrs() {
      // eslint-disable-next-line no-unused-vars
      const { size, ...attrs } = this.$attrs;
      return attrs;
    }
  },
  methods: {
    setLabel(data){
      if(!this.optionLabel) return data
      let optionLabel = this.optionLabel || 'label'
      return data[optionLabel]
    },
    setValue(data){
      if(!this.optionValue) return data
      let optionValue = this.optionValue || 'value'
      return data[optionValue]
    },
  }
};
</script>

<style lang="scss" scoped>
@import "../css/basic.scss";
.custom-input {
  &:disabled {
    cursor: not-allowed;
  }
}
</style>




