
export default {
  SET_CARRIER_COMPANIES(state, payload) {
    state.shippingCompanies = payload
  },
  SET_EDIT_DATA(state, payload) {
    state.editShippingCompanies = payload
  },
  SET_UPDATE_CARRIER(state, payload) {
    state.shippingCompanies.splice(payload,1)
  }
}

