<template>
  <div class="address-checkbox-card-container" :style="styles">
<div v-if="address" class="address-checkbox-card-wrapper">
  <h2 class="address-checkbox-card-title">{{title}}</h2>
  <div class="company-name">
    <span>Company Name</span>
    <span>{{address.company_name || " "}}</span>
  </div>
  <div class="customer-name">
    <span>Name</span>
    <span>{{address.name || " "}}</span>
  </div>
  <div class="phone">
    <span>Phone</span>
    <span>{{address.phone || " "}}</span>
  </div>
  <div class="address-1">
    <span>Address Line 1</span>
    <span>{{address.address_line1 || " "}}</span>
  </div>
  <div class="address-2">
    <span>Address Line 2</span>
    <span>{{address.address_line2 || " "}}</span>
  </div>
  <div class="address-3">
    <span>Address Line 3</span>
    <span>{{address.address_line3 || " "}}</span>
  </div>
  <div class="city">
    <span>City</span>
    <span>{{address.city || " "}}</span>
  </div>
  <div class="state-province">
    <span>State</span>
    <span>{{address.state_province || " "}}</span>
  </div>
  <div class="country-code">
    <span>Country</span>
    <span>{{address.country_code || " "}}</span>
  </div>
  <div class="postal-code">
    <span>Postal Code</span>
    <span>{{address.postal_code || " "}}</span>
  </div>
  <div class="residential">
    <span>Residential Indicator</span>
    <span>{{address.residential_indicator || " "}}</span>
  </div>
  <span v-if="!original" class="address-apply-button">
    <pg-button color="success" @click="submitAddress(address)">Apply</pg-button>
  </span>
</div>
  </div>
</template>

<script>
import AddressCheckbox from "./address-checkbox"
export default {
  name: "address-checkbox-card",
  components:{
    AddressCheckbox
  },
  computed:{
    styles(){
      return {
        "--get-template-areas": this.getTemplateAreas,
        "--border-color": this.matched ? "#52b036" : "rgba(0,0,0,.1)"
      }
    },
    getTemplateAreas(){
      return  `"company-name company-name company-name company-name"
      "customer-name customer-name customer-name customer-name"
      "address-1 address-1 address-1 address-1"
      "address-2 address-2 address-3 address-3"
      "city state-province country-code postal-code"
      "residential residential phone phone"
      "${!this.original ? 'address-apply-button address-apply-button address-apply-button address-apply-button' : 'span span span span'}"`
    }
  },
  props:{
    address:Object,
    title:String,
    original:Boolean,
    matched:Boolean,
    submitAddress:Function
  }
}
</script>

<style lang="scss" scoped>
.address-checkbox-card-container{
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  gap: 15px;
}
.address-checkbox-card-wrapper{

  font-size: .9em;
  border: 2px solid var(--border-color);
  border-radius: 5px;
  height: max-content;
  width: 100%;
  max-width: 350px;
  display: grid;
  padding: 40px 15px 15px 15px;
  position: relative;
  gap: 5px;
  grid-template-rows: repeat(8, max-content);
  grid-template-columns: repeat(4, 1fr);
  grid-template-areas: var(--get-template-areas);

  &:hover {
    background-color: #fefefe;
  }


  .address-checkbox-card-title{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;
    font-size: 1.3em;
    margin-top: 10px;
    margin-bottom: 5px;
    color: #3b4150;
  }

  div{
    display: flex;
    padding: 3px;
    flex-direction: column;
    justify-content: flex-start;
    text-align: center;
    align-items: center;
    border: 1px solid rgba(0,0,0,.1);
    border-radius: 5px;
    span:first-child{
      font-weight: 600;
    }
  }

  .address-apply-button{
    margin-top: 15px;
    grid-area: address-apply-button;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .company-name{
    grid-area: company-name;
  }
  .customer-name{
    grid-area: customer-name;
  }
  .address-1{
    grid-area: address-1;
  }
  .address-2{
    grid-area: address-2;
  }
  .address-3{
    grid-area: address-3;
  }
  .city{
    grid-area: city;
  }
  .state-province{
    grid-area: state-province;
  }
  .phone{
    grid-area: phone;
  }
  .country-code{
    grid-area: country-code;
  }
  .postal-code{
    grid-area: postal-code;
  }
  .residential{
    grid-area: residential;
  }
}


</style>
