<template>
  <vs-popup
    fullscreen
    color="info"
    :title="currentTitle"
    :active.sync="isShow"
  >
    <div class="flex w-full h-full relative">
        <div class="w-32 text-sm  icon-wrapper flex flex-col px-5 py-10 bg-gray-50">
          <div v-if="currentItemType === itemTypes.alias"  class="h-28 flex items-center justify-center text-center cursor-pointer hover:text-gray-500" @click="selectMenuType(menuTypes.originalItem)">
            <div class="flex flex-col justify-center items-center gap-2">
              <svg class="h-9 w-9" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M16.272 5.451c-.176-.45-.272-.939-.272-1.451 0-2.208 1.792-4 4-4s4 1.792 4 4-1.792 4-4 4c-1.339 0-2.525-.659-3.251-1.67l-7.131 3.751c.246.591.382 1.239.382 1.919 0 .681-.136 1.33-.384 1.922l7.131 3.751c.726-1.013 1.913-1.673 3.253-1.673 2.208 0 4 1.792 4 4s-1.792 4-4 4-4-1.792-4-4c0-.51.096-.999.27-1.447l-7.129-3.751c-.9 1.326-2.419 2.198-4.141 2.198-2.76 0-5-2.24-5-5s2.24-5 5-5c1.723 0 3.243.873 4.143 2.201l7.129-3.75zm3.728 11.549c1.656 0 3 1.344 3 3s-1.344 3-3 3-3-1.344-3-3 1.344-3 3-3zm-15-9c2.208 0 4 1.792 4 4s-1.792 4-4 4-4-1.792-4-4 1.792-4 4-4zm15-7c1.656 0 3 1.344 3 3s-1.344 3-3 3-3-1.344-3-3 1.344-3 3-3z"/></svg>
              <span>Original Item</span>
            </div>
            <pg-icon v-if="menuType === menuTypes.originalItem" class="arrow-up w-5 opacity-50 ml-auto" style="transform: rotate(90deg);" name="arrow-up" height="18"></pg-icon>

          </div>
          <div v-if="currentItemType === itemTypes.assembly"  class="h-28 flex items-center justify-center text-center cursor-pointer hover:text-gray-500" @click="selectMenuType(menuTypes.parts)">
            <div class="flex flex-col justify-center items-center gap-2">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8" viewBox="0 0 512 512"><title>Parts</title><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M413.66 246.1H386a2 2 0 01-2-2v-77.24A38.86 38.86 0 00345.14 128H267.9a2 2 0 01-2-2V98.34c0-27.14-21.5-49.86-48.64-50.33a49.53 49.53 0 00-50.4 49.51V126a2 2 0 01-2 2H87.62A39.74 39.74 0 0048 167.62V238a2 2 0 002 2h26.91c29.37 0 53.68 25.48 54.09 54.85.42 29.87-23.51 57.15-53.29 57.15H50a2 2 0 00-2 2v70.38A39.74 39.74 0 0087.62 464H158a2 2 0 002-2v-20.93c0-30.28 24.75-56.35 55-57.06 30.1-.7 57 20.31 57 50.28V462a2 2 0 002 2h71.14A38.86 38.86 0 00384 425.14v-78a2 2 0 012-2h28.48c27.63 0 49.52-22.67 49.52-50.4s-23.2-48.64-50.34-48.64z"/></svg>
              <span>Parts</span>
            </div>
            <pg-icon v-if="menuType === menuTypes.parts" class="arrow-up opacity-50 ml-auto w-5" style="transform: rotate(90deg);" name="arrow-up" height="18"></pg-icon>

          </div>
          <div v-if="processType === processTypes.edit"  class="h-28 flex items-center justify-center text-center cursor-pointer hover:text-gray-500" @click="selectMenuType(menuTypes.inventory)">
            <div class="flex flex-col justify-center items-center gap-2">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8" viewBox="0 0 512 512"><title>Inventory</title><path d="M48 336v96a48.14 48.14 0 0048 48h320a48.14 48.14 0 0048-48v-96" fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="32"/><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M48 336h144M320 336h144M192 336a64 64 0 00128 0"/><path d="M384 32H128c-26 0-43 14-48 40L48 192v96a48.14 48.14 0 0048 48h320a48.14 48.14 0 0048-48v-96L432 72c-5-27-23-40-48-40z" fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="32"/><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M48 192h144M320 192h144M192 192a64 64 0 00128 0"/></svg>
              <span>Inventory</span>
            </div>
            <pg-icon v-if="menuType === menuTypes.inventory" class="arrow-up opacity-50 ml-auto w-5" style="transform: rotate(90deg);" name="arrow-up" height="18"></pg-icon>

          </div>
          <div v-if="processType === processTypes.edit"  class="h-28 flex items-center justify-center text-center cursor-pointer hover:text-gray-500" @click="selectMenuType(menuTypes.marketplaceInfo)">
            <div class="flex flex-col justify-center items-center gap-2">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8"  viewBox="0 0 512 512"><title>Marketplace Info</title><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M448 448V240M64 240v208M382.47 48H129.53c-21.79 0-41.47 12-49.93 30.46L36.3 173c-14.58 31.81 9.63 67.85 47.19 69h2c31.4 0 56.85-25.18 56.85-52.23 0 27 25.46 52.23 56.86 52.23s56.8-23.38 56.8-52.23c0 27 25.45 52.23 56.85 52.23s56.86-23.38 56.86-52.23c0 28.85 25.45 52.23 56.85 52.23h1.95c37.56-1.17 61.77-37.21 47.19-69l-43.3-94.54C423.94 60 404.26 48 382.47 48zM32 464h448M136 288h80a24 24 0 0124 24v88h0-128 0v-88a24 24 0 0124-24zM288 464V312a24 24 0 0124-24h64a24 24 0 0124 24v152"/></svg>
              <span>Marketplace Info</span>
            </div>
            <pg-icon v-if="menuType === menuTypes.marketplaceInfo" class="arrow-up opacity-50 ml-auto w-5" style="transform: rotate(90deg);" name="arrow-up" height="18"></pg-icon>
          </div>
        </div>

        <div class="w-full relative h-full" :class="{'overflow-y-auto ': !menuType, 'overflow-y-hidden': menuType}">

          <div class="w-96 list-wrapper overflow-y-auto justify-center h-full bg-gray-50 p-5 absolute z-10 top-0 left-0" v-if="menuType">
            <div v-if="menuType === menuTypes.originalItem" class="flex flex-col justify-center items-center gap-3">
              <h3 class="text-lg ml-0 mr-auto mb-6">Original Item</h3>
              <original-item @click="selectItem($event, key)" :key="key" :item-id="item.item_part_id" :class="{'bg-orange-100 hover:bg-orange-100': key === selectedReadOnlyItemKey, 'hover:bg-gray-50': key !== selectedReadOnlyItemKey}" v-for="(item, key) in currentItemParts"></original-item>
            </div>
            <div v-else-if="menuType === menuTypes.parts" class="flex flex-col justify-center items-center gap-3">
              <h3 class="text-lg ml-0 mr-auto mb-6">Parts</h3>
              <original-item @click="selectItem($event, key)" :key="key" :item-id="item.item_part_id" :class="{'bg-orange-100 hover:bg-orange-100': key === selectedReadOnlyItemKey, 'hover:bg-gray-50': key !== selectedReadOnlyItemKey}" v-for="(item, key) in currentItemParts"></original-item>
            </div>
            <div v-else-if="menuType === menuTypes.marketplaceInfo" class="flex flex-col justify-center items-center gap-3">
              <h3 class="text-lg ml-0 mr-auto mb-6">Marketplace Info</h3>
              <h3 class="text-sm text-gray-600" v-if="!currentItemData.marketplace_infos.results.length && !currentItemData.marketplace_infos.parts.length">Not Found Transaction</h3>
              <h4 class="pl-1 text-gray-700 mb-1 text-base mr-auto" v-if="currentItemData.marketplace_infos.results.length > 0">{{currentItemData.sku}}</h4>
              <marketplace-info @click="selectMarketplaceInfo(marketplaceInfo, 0, key)" :class="{'bg-orange-100 hover:bg-orange-100': selectedMarketplaceInfoKey === `0-${key}`, 'hover:bg-gray-50': `0-${key}` !== selectedMarketplaceInfoKey}" :marketplace-info-data="marketplaceInfo" :key="`0-${key}`" v-for="(marketplaceInfo, key) in currentItemData.marketplace_infos.results"></marketplace-info>
              <template v-for="(marketplaceInfoPart, partKey) in currentItemData.marketplace_infos.parts">
                <h4 class="pl-1 text-gray-700 mb-1 text-base w-full flex justify-between mt-10" ><span>{{marketplaceInfoPart.sku}}</span> <span class="ml-auto mr-2 text-xs font-semibold">{{currentItemData.type === 'alias' ? 'Original Item' : 'Bill Of Material'}}</span></h4>
                <marketplace-info @click="selectMarketplaceInfo(marketplaceInfo, partKey + 1, key)" :class="{'bg-orange-100 hover:bg-orange-100': selectedMarketplaceInfoKey === `${partKey +1}-${key}`, 'hover:bg-gray-50': `${partKey +1}-${key}` !== selectedMarketplaceInfoKey}" :marketplace-info-data="marketplaceInfo" :key="`${partKey +1}-${key}`" v-for="(marketplaceInfo, key) in marketplaceInfoPart.results"></marketplace-info>
              </template>
            </div>
            <div v-else-if="menuType === menuTypes.inventory" class="flex flex-col justify-center items-center gap-3">
              <template v-if="!currentItemData.is_default_inventory">
                <h3 class="text-lg ml-0 mr-auto mb-6">External Item Inventory</h3>
                <external-item-inventory :sku="currentItemData.sku" :updated="currentItemData.external_qty_on_hand.updated" :quantity="currentItemData.external_qty_on_hand.quantity"></external-item-inventory>
                <external-item-inventory :type="currentItemData.type === 'alias' ? 'Original Item' : 'Bill Of Material'" :sku="part.sku" :updated="part.updated" :quantity="part.quantity" :key="key" v-for="(part, key) in currentItemData.external_qty_on_hand.parts"></external-item-inventory>
              </template>
              <template v-else>
                <h3 class="text-lg ml-0 mr-auto mb-6">Item Inventory</h3>
                <h4 class="pl-1 text-gray-700 mb-1 text-base mr-auto">{{currentItemData.sku}}</h4>
                <default-item-inventory  @click="selectDefaultInventory(inventory, currentItemData.id, 0, key)" :class="{'bg-orange-100 hover:bg-orange-100': selectedDefaultInventoryKey === `0-${key}`, 'hover:bg-gray-50': `0-${key}` !== selectedDefaultInventoryKey}" :warehouse="inventory.warehouse" :quantity="inventory.quantity"  :key="`0-${key}`" v-for="(inventory, key) in currentItemData.inventory.results"></default-item-inventory>
                <template v-for="(inventoryPart, partKey) in currentItemData.inventory.parts">
                  <h4 class="pl-1 text-gray-700 mb-1 text-base w-full flex justify-between mt-10" ><span>{{inventoryPart.sku}}</span> <span class="ml-auto mr-2 text-xs font-semibold">{{currentItemData.type === 'alias' ? 'Original Item' : 'Bill Of Material'}}</span></h4>
                  <default-item-inventory @click="selectDefaultInventory(inventory, inventoryPart.id, partKey + 1, key)" :class="{'bg-orange-100 hover:bg-orange-100': selectedDefaultInventoryKey === `${partKey +1}-${key}`, 'hover:bg-gray-50': `${partKey +1}-${key}` !== selectedDefaultInventoryKey}" :warehouse="inventory.warehouse" :quantity="inventory.quantity" :key="`${partKey +1}-${key}`" v-for="(inventory, key) in inventoryPart.results"></default-item-inventory>
                </template>
              </template>
            </div>
          </div>
          <div class="w-96 hover-form-wrapper justify-center overflow-y-auto h-full bg-gray-50 p-5 absolute z-10 top-0 ml-96 left-0" v-if="isShowHoverForm">
            <span class="detail-close" @click="selectMenuType(null)">
              <svg xmlns="http://www.w3.org/2000/svg" height="28" viewBox="0 0 512 512"><title>Close</title><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M368 368L144 144M368 144L144 368"/></svg>
            </span>
            <edit-marketplace-info v-if="isShowMarketplaceInfoForm" :marketplace-info-data="currentMarketplaceInfoData" :current-item-data="currentItemData"></edit-marketplace-info>
            <default-warehouse-inventory-history v-if="isShowDefaultInventoryForm" :warehouse-inventory-data="currentDefaultInventoryData"></default-warehouse-inventory-history>
            <read-only-item v-if="isShowReadOnlyItemForm" :current-item-data="currentReadOnlyItemData"></read-only-item>
          </div>
          <div class="ml-10 mb-10 relative"  @click="selectMenuType(null)">
            <span class="flex justify-start ml-5 items-center gap-3 mt-10" v-if="createWithSku">
                <vs-switch :style="switchStyle" label="No" v-model="isExternal">
                  <span slot="on">Yes</span>
                  <span slot="off">No</span>
                </vs-switch>
                <span>Add as an External SKU</span>
              </span>
            <external-create-item v-if="isExternal" :close="close" :external-sku="createWithSku"></external-create-item>
            <template v-else>
              <add-single :close="close" :create-item="createWithSku" v-if="currentForm === 'create-single'"></add-single>
              <add-alias :close="close" :create-item="createWithSku" :set-item-parts="setItemParts" v-else-if="currentForm === 'create-alias'"></add-alias>
              <add-assembly :close="close" :create-item="createWithSku" :set-item-parts="setItemParts" v-else-if="currentForm === 'create-assembly'"></add-assembly>
              <edit-single :current-item-data="currentItemData" :close="close" v-else-if="currentForm === 'edit-single'" :reset-form="resetForm" @reset="reset"></edit-single>
              <edit-alias :current-item-data="currentItemData" :set-item-parts="setItemParts" :close="close" v-else-if="currentForm === 'edit-alias'" :reset-form="resetForm" @reset="reset"></edit-alias>
              <edit-assembly :current-item-data="currentItemData" :set-item-parts="setItemParts" :close="close" v-else-if="currentForm === 'edit-assembly'" :reset-form="resetForm" @reset="reset"></edit-assembly>
              <type-switch v-if="!isShowHoverForm" class="absolute right-0 mr-5 mt-5 top-0 h-80 w-28 select-none"  v-model="currentItemType"></type-switch>
            </template>
            </div>
           </div>
    </div>
  </vs-popup>
</template>

<script>
import AddSingle from "@/views/items/item/forms/CreateSingle.vue";
import AddAlias from "@/views/items/item/forms/CreateAlias.vue";
import AddAssembly from "@/views/items/item/forms/CreateAssembly.vue";
import EditSingle from "@/views/items/item/forms/EditSingle.vue";
import EditAlias from "@/views/items/item/forms/EditAlias.vue";
import EditAssembly from "@/views/items/item/forms/EditAssembly.vue";
import ExternalCreateItem from "@/views/items/item/forms/ExternalCreateItem.vue";
import EditMarketplaceInfo from "@/views/items/item/forms/EditMarketplaceInfo.vue";
import TypeSwitch from "@/views/items/item/partials/TypeSwitch.vue";
import OriginalItem from "@/views/items/item/partials/OriginalItem.vue";
import MarketplaceInfo from "@/views/items/item/partials/MarketplaceInfo.vue";
import ReadOnlyItem from "@/views/items/item/partials/ReadOnlyItem.vue";
import ExternalItemInventory from "@/views/items/item/partials/ExternalItemInventory.vue";
import DefaultItemInventory from "@/views/items/item/partials/DefaultItemInventory.vue";
import DefaultWarehouseInventoryHistory from "@/views/items/item/partials/DefaultWarehouseInventoryHistory.vue";

export default {
  name: "Index",
  components: {
    DefaultWarehouseInventoryHistory,
    DefaultItemInventory,
    ExternalItemInventory,
    ReadOnlyItem, ExternalCreateItem,
    MarketplaceInfo, EditMarketplaceInfo,
    OriginalItem, EditAssembly, EditAlias, EditSingle, AddAssembly, AddAlias, AddSingle, TypeSwitch},
  props: {
    show: Boolean,
    createWithSku: String,
    itemId:[String, Number],
    processType: {
      type: String,
      default: "create",
      options: ["create", "edit"]
    },
    itemType: {
      type: String,
      default: "alias",
      options: ["single", "alias", "assembly"]
    },
  },
  data(){
    return {
      resetForm: false,
      isShow: this.show,
      isExternal: false,
      currentItemData: null,
      currentMarketplaceInfoData: null,
      currentDefaultInventoryData: null,
      selectedMarketplaceInfoKey: null,
      selectedDefaultInventoryKey: null,
      currentReadOnlyItemData: null,
      selectedReadOnlyItemKey: null,
      menuType: null,
      isShowMarketplaceInfoForm:false,
      isShowDefaultInventoryForm:false,
      isShowReadOnlyItemForm:false,
      currentItemParts: [],
      currentItemType: "single",
      menuTypes: {
        originalItem: "original-item",
        parts: "parts",
        inventory: "inventory",
        marketplaceInfo: "marketplace-info",
      },
      itemTypes: {
        single: "single",
        alias: "alias",
        assembly: "assembly"
      },
      processTypes: {
        create: "create",
        edit: "edit"
      }
    }
  },
  computed: {
    isShowHoverForm(){
      return this.isShowMarketplaceInfoForm || this.isShowReadOnlyItemForm || this.isShowDefaultInventoryForm
    },
    currentTitle(){
      if(this.processType === "create" && !this.isExternal) return "Create Item"
      if(!this.currentItemData) return "Edit Item"
      return `Edit ${this.currentItemData.sku}`
    },
    currentForm(){
      return `${this.processType}-${this.currentItemType}`
    },
    switchStyle() {
      return {
        backgroundColor: this.isExternal ? "#64BFE5" : "#e1e1e1",
        color: "#fff",
      };
    },
  },
  created() {
    this.getItemData()
  },
  methods: {
    selectMarketplaceInfo(data, baseKey, key){
      this.currentMarketplaceInfoData = data
      this.selectedMarketplaceInfoKey = `${baseKey}-${key}`
      queueMicrotask(()=> {
        this.isShowMarketplaceInfoForm = true
      })
    },
    selectDefaultInventory(data, itemId, baseKey, key){
      this.currentDefaultInventoryData = data
      this.currentDefaultInventoryData["itemId"] = itemId
      this.selectedDefaultInventoryKey = `${baseKey}-${key}`
      queueMicrotask(()=> {
        this.isShowDefaultInventoryForm = true
      })
    },
    selectItem(data, key){
      this.currentReadOnlyItemData = data
      this.selectedReadOnlyItemKey = key
      queueMicrotask(()=> {
        this.isShowReadOnlyItemForm = true
      })
    },
    selectMenuType(type){
      this.menuType = type
      this.isShowMarketplaceInfoForm = false
      this.isShowReadOnlyItemForm = false
      this.isShowDefaultInventoryForm = false
      this.selectedMarketplaceInfoKey = null
      this.selectedReadOnlyItemKey = null
      this.selectedDefaultInventoryKey = null
    },
    close(){
      this.$emit('update:show', false)
    },
    setItemParts(parts){
      this.currentItemParts = parts
    },
    getItemData() {
      if(!this.itemId) return
      this.$spinner.fingerprint();
      this.$store
        .dispatch("items/fetchSelectData", this.itemId)
        .then((response) => {
          this.currentItemType = response.data.type
          this.currentItemParts = response.data.item_parts
          queueMicrotask(()=>{
            this.currentItemData = response.data
          })
          this.$spinner.close();
        })
        .catch((error) => {
          console.log(error)
          this.$spinner.close();
          this.$swal({
            icon: "error",
            title: "Item Not Found",
          });
        });
    },
    reset(val){
      this.resetForm = val;
    }
  },
  watch: {
    itemId(newValue){
      if(!newValue) return
      this.getItemData(newValue)
    },
    show(newValue){
      this.isShow = newValue
    },
    isShow(newValue){
      this.$emit('update:show', newValue)
    },
    currentItemType(newValue, oldValue){
      if (oldValue == 'alias' && ['single', 'assembly'].includes(newValue)){
        this.resetForm = true;
        return
      }
      if (oldValue == 'assembly' && ['single', 'alias'].includes(newValue)){
        this.resetForm = true;
        return
      }
      if (oldValue == 'single' && ['alias', 'assembly'].includes(newValue)){
        this.resetForm = true;
        return
      }
      this.resetForm = false;
    }
  }
}
</script>

<style lang="scss">
.vs-popup--content{
  padding: 0 !important;
  margin: 0 !important;
  height: 100%;
  max-height: calc(100% - 35px);
  width: 100%;
  border-block: 1px solid rgba(0,0,0,.1);
}
.type-wrapper{
  border-right: 1px solid rgba(0,0,0,.1);
}
.icon-wrapper{
  border-right: 1px solid rgba(0,0,0,.1);
  border-bottom-left-radius: 15px;
}
.list-wrapper{
  border-right: 1px solid rgba(0,0,0,.1);
}
.hover-form-wrapper{
  border-right: 1px solid rgba(0,0,0,.1);
}
.detail-close{
  cursor: pointer;
  padding: 1px;
  margin-left: auto;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0,0,0,.1);
  &:hover{
    background-color: #f15656;
    color: #fff;
  }
}
</style>
