<template>
    <table class="filter-dropdown-table">
        <tr v-for="(row,ind) in data" :key="ind" @click="selectValue(ind)" v-bind:class="{'select-tr':isSelect(row)}">
            <td v-if="row.img"> <img :src="row.img" height="15px" style="margin:0 auto;"  :alt="row.name"> </td>
            <td v-else> &nbsp; </td>
            <td v-if="row.icon">
                <feather-icon
                :icon="row.icon"
                svgClasses="w-5 h-5 hover:text-primary stroke-current"
            /> </td>
            <td>{{row[dropdownShow]}}</td>

        </tr>
    </table>
</template>

<script>
export default {
    props:{
      value:{},
      dropdownShow:{
        type:String,
        default:"name"
      },
      dropdownValue:{
        type:String,
        default:"value"
      },
      data: {
        type:Array,
        default:function (){
          return []
        }
      }
    },
    data(){
        return{
            tdKey:0,
            selected:null
        }
    },
    watch:{
      value(newValue){
        let key = this.data.findIndex(v => v[this.dropdownValue] == newValue)
        this.selectValue(key)
      },
      data:{
        handler(newValue){
          if(newValue && newValue.length){
            let key = newValue.findIndex(v => v[this.dropdownValue] == this.value)
            this.selectValue(key)
          }
        },
        deep:true
      }
    },
    created() {
      let key = this.data.findIndex(v => v[this.dropdownValue] == this.value)
      this.selectValue(key)
    },
    methods:{
      selectValue(index){
        this.selected = this.data[index]
        this.$emit('input',this.selected)
      },
      isSelect(row){
        if(!this.selected){
          return false
        }
        return this.selected[this.dropdownValue] == row[this.dropdownValue]
      }

    }
}
</script>

<style lang="scss">
    .filter-dropdown-table{
        margin: 0 auto;
        border-spacing:  0px 5px;
        text-align: left;
        width: 100%;
        padding: 5px;
    }

    .filter-dropdown-table tr{
        border-bottom: 1px solid #F0F0F0;
    }

    .filter-dropdown-table tr td{
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .filter-dropdown-table tr:hover{
        background: #F0F0F0;
    }

    .select-tr{
        background: #F0F0F0  !important;
    }

    .filter-dropdown-table tr td:nth-child(1){
        width: 15%;
        padding-right: 10px;
    }
</style>
