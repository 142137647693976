export default {
  SET_PACKAGES(state, payload) {
    state.packages = payload

  },
  SET_SELECT_PACKAGE(state, payload) {
    state.selectPackage = payload
  },

};
