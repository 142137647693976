export default {
  methods:{
    close(){
      this.$emit('update:show',false)
    },
    orderselectData() {
      if(!this.value || !this.show) return

      this.$spinner.fingerprint();
      this.$store
        .dispatch("orders/fetchSelectOrder", this.value)
        .then((response) => {
          this.$spinner.close();
          this.order = response.data
          this.$emit('update:show',true)
        })
        .catch(() => {
          this.$swal({ icon: "error", title: "orders Not Found" })
            .then(()=>{
              this.close()
            })

        });
    }
  },
}
