import axios from "@/axios";
import requestsHelper from "@/services/helpers/requestsHelper";

export default {
  fetchSearchOrders({ commit }, options) {
    return new Promise((resolve, reject) => {
      let helpers = new requestsHelper(options);
      let queryset = helpers.createQueryset();
      axios
        .get("/order/orders/search_list/" + queryset)
        .then(response => {
          commit("SET_ORDERS", response.data.results);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchOrders({ commit }, options) {
    let helpers = new requestsHelper(options);
    let queryset = helpers.createQueryset();

    return new Promise((resolve, reject) => {
      axios
        .get("/order/orders/" + queryset)
        .then(response => {
          commit("SET_ORDERS", response.data.results);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchMarketplacesUsingPurolator(){
    return new Promise((resolve, reject) => {
      axios
        .get("/order/orders/fetch_marketplaces_using_purolator/", {})
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchPurolatorEndOfDayPackages(ignore, payload){
    return new Promise((resolve, reject) => {
      axios
        .post("/order/orders/fetch_purolator_end_of_day_packages/", payload)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  submitPurolatorEndOfDayPackages(ignore, payload){
    return new Promise((resolve, reject) => {
      axios
        .post("/order/orders/purolator_end_of_day_batch/", payload)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  changeSelectAll( { commit }, value){
    return new Promise(() => {
      commit("CHANGE_SELECT_ALL", value);
    });
  },
  checkImportOrderLogs(){
    return new Promise((resolve, reject) => {
      axios
        .get("/order/orders/download_import_order_logs/")
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  clearPreparedOrderData(){
    return new Promise((resolve, reject) => {
      axios
        .get("/order/orders/clear_prepared_order_data/")
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  clearOrderImportFile(){
    return new Promise((resolve, reject) => {
      axios
        .get("/order/orders/clear_order_import_file/")
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  clearImportOrderLogs(){
    return new Promise((resolve, reject) => {
      axios
        .get("/order/orders/clear_import_order_logs/")
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchOrderLines(ignore, options) {
    options.limit || (options.limit = 50);
    let helpers = new requestsHelper(options);
    let queryset = helpers.createQueryset();

    return new Promise((resolve, reject) => {
      axios
        .get("/order/order_lines/" + queryset)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  fetchSelectOrder({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get("/order/orders/" + id + "/")
        .then(response => {
          commit("SET_SELECT_ORDER", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchDownloadLabelZips(ignore, options) {
    let helpers = new requestsHelper(options);
    let queryset = helpers.createQueryset();
    return new Promise((resolve, reject) => {
      axios
        .get("/order/download_label_jobs/", queryset)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  bulkStatusUpdate(ignore, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/order/orders/bulk_update/", payload)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  getZipList() {
    return new Promise((resolve, reject) => {
      axios
        .get("/package/packages/get_zip_job_list/")
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  getLogDetail({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get("/order/logs/" + id + "/")
        .then(response => {
          commit("SET_LOG_DATA", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  getLogs() {
    return new Promise((resolve, reject) => {
      axios
        .get("/order/logs/?limit=50")
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  addItem(ignore, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/order/orders/", payload)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  fetchMultiMarketOrders(ignore, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/order/orders/fetch_orders/", payload.marketplaces)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  removeItem(ignore, payload) {
    return new Promise((resolve, reject) => {
      axios
        .delete("/order/orders/" + payload + "/")
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  uploadBulk(ignore, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/media/upload/", payload)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  create_bulk(ignore, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/order/orders/create_bulk/", payload)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  createBulkJson(ignore, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/item/items/bulk_upload_json/", payload)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  getTrackingNumber(ignore, obj) {
    let url = "/package/packages/get_tracking_numbers/"
      return new Promise((resolve, reject) => {
        axios
          .post(url, obj)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });

  },


  fetchStatuses() {
    return new Promise((resolve, reject) => {
      axios
        .get("/order/orders/statuses/")
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  fetchReasons() {
    return new Promise((resolve, reject) => {
      axios
        .get("/order/orders/cancel_reasons/")
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchCancelReasons(ignore, obj){
    return new Promise((resolve, reject) => {
      axios
        .post("/order/orders/marketplace_cancel_reasons/", obj)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  orderStatusUpdate(ignore, payload) {
    return new Promise((resolve, reject) => {
      axios
        .patch("/order/orders/update_partial/?id=" + payload.id, payload)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  orderUpdate(ignore, payload) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/order/orders/` + payload.id + "/", payload)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  packageLineUpdate(ignore, payload, id) {
    Object.assign(payload, {
      tracking_manually_updated: true
    });

    return new Promise((resolve, reject) => {
      axios
        .patch("/package/packages/" + id + "/", payload)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  getLabelZip(ignore, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/package/packages/get_labels_zip/", data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  submitTrackingNumber(ignore, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/order/orders/submit_tracking_number/", data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  submitSameTrackingNumber(ignore, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/order/orders/submit_same_tracking_number/", data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  acknowledgedOrder(ignore, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/order/orders/acknowledged_order/", data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  createPackages(ignore, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/order/orders/create_packages/", data)
        .then(response => {
          if(response.status === 205){
            reject(response);
          }
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  regeneratePackages(ignore, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/order/orders/regenerate_packages/", data)
        .then(response => {
          if(response.status === 205){
            reject(response);
          }
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  submitInvoice(ignore, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/order/orders/submit_invoice/", data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  cancelOrder(ignore, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/order/orders/cancel_order/", data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  submitInvoiceInBC(ignore, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/order/orders/invoice_bc/", data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  releaseBC(ignore, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/order/orders/release_bc/", data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  create_bc(ignore, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/order/orders/create_bc/", data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  exportTrackingFile(ignore, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/package/packages/export_tracking_number/", data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  orderLineUpdate(ignore, payload) {
    let id = payload.id;
    delete payload.id;
    return new Promise((resolve, reject) => {
      axios
        .patch("/order/order_lines/" + id + "/", payload)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  downloadPickList(ignore, data) {
    let url = "/order/orders/download_pick_list/"
    return new Promise((resolve, reject) => {
      axios
        .post(url, data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  downloadStampsPickList(ignore, data) {
    let url = "/order/orders/download_stamps_pick_list/"
    return new Promise((resolve, reject) => {
      axios
        .post(url, data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  fetchPrinters() {
    return new Promise((resolve, reject) => {
      axios
        .get("/printer/printers/")
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  getLabelFolders(ignore, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/printer/printers/get_label_folders/", data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  sendToPrinter(ignore, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/printer/printers/send_to_printer/", data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  cancelPrinting(ignore, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/printer/printers/cancel_printing/", data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  putOrdersOnHold(ignore, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/order/orders/put_orders_on_hold/", data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  validateAnAddress(ignore, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/order/orders/order_validate_an_address/", data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  createFileOrders(ignore, options){
    let helpers = new requestsHelper(options);
    let queryset = helpers.createQueryset();
    return new Promise((resolve, reject) => {
      axios
        .get("/order/orders/create_file_orders/" + queryset)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },



  fetchPreparedOrderData(ignore, options) {
    let helpers = new requestsHelper(options);
    let queryset = helpers.createQueryset();
    return new Promise((resolve, reject) => {
      axios
        .get("/order/orders/fetch_prepared_order_data/"+ queryset)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },



  fetchImportOrderData(ignore, options) {
    let helpers = new requestsHelper(options);
    let queryset = helpers.createQueryset();
    return new Promise((resolve, reject) => {
      axios
        .get("/order/orders/fetch_import_order_data/"+ queryset)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  updateOrder({commit}, {orderId, formData}) {
    return new Promise((resolve, reject) => {
      axios.patch("/order/orders/" + orderId + "/", formData).then(response => {
        commit("PARTIAL_UPDATE_ORDER", response.data);
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  }

};
