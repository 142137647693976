import axios from "@/axios";
import requestsHelper from "@/services/helpers/requestsHelper";

export default {


  fetchUsers(ignore, options) {
    let helpers = new requestsHelper(options);
    let queryset = helpers.createQueryset();
    return new Promise((resolve, reject) => {
      axios
        .get("/account/accounts/" + queryset)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  retrieveUser(ignore, id) {
    return new Promise((resolve, reject) => {
      axios
        .get("/account/accounts/" + id)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  createUser(ignore, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/account/accounts/", payload)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  updateUser(ignore, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put("/account/accounts/" + payload.id + "/", payload)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  changePassword({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put("/account/change_password/", payload)
        .then(response => {
          commit("auth/SET_BEARER", response.data.access, { root: true });
          resolve(response); 
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};
