<template>
  <div class="image-zoom-modal-wrapper" v-show="show" :style="styles">
    <div class="image-zoom-modal-header">
          <span class="image-zoom-download" @click.stop="download">
            <svg width="28" height="28" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><title>Download</title><path fill="currentColor" d="M11.5 8h1v7.826l2.5-3.076.753.665-3.753 4.585-3.737-4.559.737-.677 2.5 3.064v-7.828zm7 12h-13c-2.481 0-4.5-2.019-4.5-4.5 0-2.178 1.555-4.038 3.698-4.424l.779-.14.043-.79c.185-3.447 3.031-6.146 6.48-6.146 3.449 0 6.295 2.699 6.479 6.146l.043.79.78.14c2.142.386 3.698 2.246 3.698 4.424 0 2.481-2.019 4.5-4.5 4.5m.979-9.908c-.212-3.951-3.473-7.092-7.479-7.092s-7.267 3.141-7.479 7.092c-2.57.463-4.521 2.706-4.521 5.408 0 3.037 2.463 5.5 5.5 5.5h13c3.037 0 5.5-2.463 5.5-5.5 0-2.702-1.951-4.945-4.521-5.408"/></svg> Download
          </span>
        <span class="image-zoom-close" @click.stop="closeLabel">
              <svg xmlns="http://www.w3.org/2000/svg" height="28" viewBox="0 0 512 512"><title>Close</title><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M368 368L144 144M368 144L144 368"/></svg>Close
            </span>
    </div>
<!--    <figure :class="{'zoom': isZoom}" @mousemove="zoom" @mouseleave="isZoom=false" @mouseout="isZoom=false">-->
    <figure>
      <img class="image-container"  :src="imageSrc" />
    </figure>
  </div>
</template>

<script>
export default {
  name: "index",
  props:{
    imageSrc: String,
    trackingNumber: String,
    show:Boolean
  },
  data(){
    return {
      isZoom:false
    }
  },
  computed:{
    styles(){
      return {
        "--background-image": this.isZoom ? `url(${this.imageSrc})` : "unset"
      }
    }
  },
  methods:{
    closeLabel(){
      this.$emit('update:show', false)
    },
    async download(){
      const image = await fetch(this.imageSrc)
      const imageBlog = await image.blob()
      const imageURL = URL.createObjectURL(imageBlog)

      const link = document.createElement('a')
      link.href = imageURL
      link.download = this.imageSrc.substring(this.imageSrc.lastIndexOf('/')+1)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    zoom(e){
      this.isZoom = e.target.classList.contains("image-container");

      let zoomer = e.currentTarget;
      let offsetX = 0
      let offsetY = 0
      if(e.offsetX){
        offsetX = e.offsetX
      }else{
        offsetX = e?.touches[0]?.pageX || 0
      }
      if(e.offsetY){
        offsetY = e.offsetY
      }else{
        offsetY = e?.touches.length && e?.touches[0]?.pageX || 0
      }
      let verticalDiff = (e.clientHeight / 2) >=  e.clientY ? 50 : -50
      let x = offsetX /zoomer.offsetWidth*100
      let y = (offsetY + verticalDiff) /zoomer.offsetHeight*100
      zoomer.style.backgroundPosition = x + '% ' + y + '%';
    },
  }
}
</script>

<style lang="scss" scoped>
.image-zoom-modal-wrapper{
  position: fixed;
  z-index: 99999999999;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,.8);
  figure{
    margin-left: 10vw;
    margin-top: 10vh;
    height: 80vh;
    position: relative;
    width: 80vw;
    background-color: transparent;
    box-shadow: 0 3px 5px rgba(0,0,0,.1);
    border-radius: 5px;
    border: none;
    display: flex;
    justify-content: center;
    background-image: var(--background-image);
    padding-block: 15px;
    align-items: center;
    img{
      object-fit: fill;
      height: 100%;
    }
  }
}

figure.zoom {
  & img:hover {
    opacity: 0;
  }
  img {
    transition: opacity .5s;
    display: block;
    width: 80vw;
    object-fit: contain;
  }
  background-position: 50% 50%;
  position: relative;
  max-width: 80vw;
  width: max-content;
  max-height: 80vh;
  background-repeat: no-repeat;
  overflow: hidden;
  cursor: zoom-in;
}
.image-zoom-modal-header{
  margin-left: 30vw;
  margin-top: 3vh;
  width: 40vw;
  position: absolute;
  top: 0;
  left: 0;
  height: 50px;
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(2, 1fr);
  span{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
  }
}
.image-zoom-close{
  cursor: pointer;
  border: none;
  background-color: #fff;
  z-index: 999999999999;
  font-family: 'Bai Jamjuree', sans-serif;
  display: flex;
  height: 100%;
  font-size: 1.2em;
  width: 100%;
  font-weight: 700;
  color: #f15656;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  &:hover{
    background-color: #f15656;
    box-shadow: 0 1px 3px rgba(0,0,0,.1);
    color: #fff;
  }
}
.image-zoom-download{
  cursor: pointer;
  border: none;
  background-color: #fff;
  z-index: 999999999999;
  font-family: 'Bai Jamjuree', sans-serif;
  display: flex;
  height: 100%;
  font-size: 1.2em;
  width: 100%;
  font-weight: 700;
  color: #33a812;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  &:hover{
    background-color: #33a812;
    box-shadow: 0 1px 3px rgba(0,0,0,.1);
    color: #fff;
  }
}
</style>
