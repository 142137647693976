<template>
<span>
  {{ind+1}}
</span>
</template>

<script>
export default {
  name: "rowRumber",
  props:{
    ind:Number
  }
}
</script>

<style scoped>

</style>
