<template>
      <date-picker
        :style="cssVariables"
        v-model="rangeValue"
        prefix-class="pg-daterange"
        :placeholder="label"
        lang="en"
        append-to-body
        editable
        range
        confirm
        partial-update
        :time-picker-options="{start: '00:00', step:'00:15' , end: '23:45', format: 'hh:mm a' }"
        type="datetime"
        :format="format"
        :shortcuts="shortcuts"
        @confirm="confirmDate"
        :confirm-text="'Confirm'">
      </date-picker>
</template>

<script>
import DatePicker from "vue2-datepicker";
import 'vue2-datepicker/locale/tr';
import moment from "moment";
export default {
  components:{
    DatePicker
  },
  props:{
    value:{},
    dropdownShow:{
      type:String,
      default:"name"
    },
    format:{
      type:String,
      default:"DD MMM hh:mm A "
    },
    dropdownValue:{
      type:String,
      default:"value"
    },
    label:{
      type:String,
      default:"Title"
    },
    data: {
      type:Array,
      default:function (){
        return []
      }
    }
  },
  data(){
    return{
      showSelected:"",
      show:false,
      visibilityLock:true,
      rangeValue:null,
      shortcuts: [
        {
          text: "Today",
          onClick: () => {
            this.rangeValue = [ moment().subtract(moment().toDate().getHours(),"hour").subtract(moment().toDate().getMinutes(),"minute").toDate(), moment().toDate() ]
          }
        },
        {
          text: "Yesterday",
          onClick: () => {
            this.rangeValue = [ moment().subtract(moment().toDate().getHours(),"hour").subtract(moment().toDate().getMinutes(),"minute").subtract(1, 'days').toDate(), moment().subtract(1, 'days').endOf('day').toDate() ]
          }
        },
        {
          text: "Last 7 Days",
          onClick: () => {
            this.rangeValue = [ moment().subtract(moment().toDate().getHours(),"hour").subtract(moment().toDate().getMinutes(),"minute").subtract(6, 'days').toDate(), moment().toDate() ]
          }
        },
        {
          text: "Last 30 Days",
          onClick: () => {
            this.rangeValue = [ moment().subtract(moment().toDate().getHours(),"hour").subtract(moment().toDate().getMinutes(),"minute").subtract(29, 'days').toDate(), moment().toDate() ]
          }
        },
        {
          text: "This Month",
          onClick: () => {
            this.rangeValue = [ moment().subtract(moment().toDate().getHours(),"hour").subtract(moment().toDate().getMinutes(),"minute").startOf('month').toDate(), moment().toDate() ]
          }
        },
        {
          text: "Last Month",
          onClick: () => {
            this.rangeValue = [ moment().subtract(moment().toDate().getHours(),"hour").subtract(moment().toDate().getMinutes(),"minute").subtract(1, 'month').startOf('month').toDate(),moment().subtract(moment().toDate().getHours(),"hour").subtract(moment().toDate().getMinutes(),"minute").subtract(1, 'month').endOf('month').toDate() ]
          }
        },
        {
          text: "All",
          onClick: () => {
            this.rangeValue = [ moment().subtract(moment().toDate().getHours(),"hour").subtract(moment().toDate().getMinutes(),"minute").subtract(1, 'year').startOf("year").toDate(), moment().toDate() ]
          }
        }
      ]
    }
  },
  computed:{
    cssVariables(){
      return {
      }
    }
  },
  methods:{
    close(){
      if(!this.visibilityLock){
        this.show = false
      }
    },
    modelChange(value){
      this.$emit('input', value)
    },
    confirmDate(){
      this.$emit('input', [moment(this.rangeValue[0]).toDate(),moment(this.rangeValue[1]).toDate()])
    },
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital@1&display=swap');
.pg-daterange-datepicker {
  margin-right: 8px;
  padding-bottom: 10px;
  position: relative;
  display: inline-block;
  max-width:  var(--filter-width); //bu ayar pg-daterange-filters-containers'dan geliyor;
  width: var(--filter-width); //bu ayar pg-daterange-filters-containers'dan geliyor;
  padding-right: var(--filter-datepicker-padding); //bu ayar pg-daterange-filters-containers'dan geliyor;
  svg {
    width: 18px;
    height: 18px;
    background-color: #fff;
    fill: rgba(0,0,0,0.4);
    overflow: hidden;
  }
}

.pg-daterange-datepicker-range {
  max-width:  var(--filter-width); //bu ayar pg-daterange-filters-containers'dan geliyor;
  width: var(--filter-width); //bu ayar pg-daterange-filters-containers'dan geliyor;
}

.pg-daterange-datepicker-inline {
  width: auto;
}

.pg-daterange-input-wrapper {
  position: relative;
  .pg-daterange-icon-clear {
    display: none;
  }
  &:hover {
    .pg-daterange-icon-clear {
      display: block;
    }
    .pg-daterange-icon-clear + .pg-daterange-icon-calendar {
      display: none;
    }
  }
}

.pg-daterange-input {
  display: inline-block;
  box-sizing: border-box;
  width: var(--filter-daterange-width);
  height: 33px;
  padding-left: 15px;
  color: #6E6B7B;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 0;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #d4d4d4;
  box-shadow: 0 3px 6px 0 rgba(214, 217, 218, 0.4);
  font-family: "Montserrat", Helvetica, Arial, sans-serif;

  &:hover,
  &:focus {
    border-color: rgba(0,0,0,0.4);
  }
  &:disabled,
  &.disabled {
    color: #ccc;
    background-color: #f3f3f3;
    border-color: #ccc;
    cursor: not-allowed;
  }
  &:focus {
    outline: none;
  }
  &::-ms-clear {
    display: none;
  }
}

.pg-daterange-icon-calendar,
.pg-daterange-icon-clear {
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  font-size: 16px;
  line-height: 1;
  color: rgba(0, 0, 0, 0.5);
  vertical-align: middle;
}

.pg-daterange-icon-clear {
  cursor: pointer;
  &:hover {
    color: rgba(0, 0, 0, 0.8);
  }
}

.pg-daterange-datepicker-main {
  font: 14px/1.5 'Helvetica Neue', Helvetica, Arial, 'Microsoft Yahei', sans-serif;
  color: var(--default-color);
  background-color: #fff;
  border: 1px solid #e8e8e8 ;
}

.pg-daterange-datepicker-popup {
  margin-top: 1px;
  margin-bottom: 1px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  z-index: 99999999;
}

.pg-daterange-datepicker-sidebar {
  float: left;
  box-sizing: border-box;
  width: 130px;
  padding: 6px;
  overflow: auto;
  button{
    color: #337ab7;
    font-weight: 600;
    border: none;
    background-color: #fff;
  }
}

.pg-daterange-datepicker-sidebar + .pg-daterange-datepicker-content {
  margin-left: 100px;
}

.pg-daterange-datepicker-body {
  position: relative;
  user-select: none;
}

.pg-daterange-btn-shortcut {
  display: block;
  padding: 0 6px;
  line-height: 24px;
}

.pg-daterange-range-wrapper {
  display: flex;
  @media (max-width: 750px) {
    flex-direction: column;
  }
}

.pg-daterange-datepicker-header {
  padding: 6px 8px;
  border-bottom: 1px solid #e8e8e8 ;
}

.pg-daterange-datepicker-footer {
  padding: 6px 8px;
  text-align: right;
  border-top: 1px solid #e8e8e8 ;

  button{
    background-color: #337ab7 ;
    padding: 10px 15px;
    border-radius: 4px;
    color: #fff;
    border: none;

  }

}

.pg-daterange-calendar {
  box-sizing: border-box;
  width: 248px;
  padding: 6px 12px;
  & + & {
    border-left: 1px solid #e8e8e8 ;
  }
}

.pg-daterange-calendar-header {
  box-sizing: border-box;
  height: 34px;
  line-height: 34px;
  text-align: center;
  overflow: hidden;
  button{
    border: none;
    background-color: #fff;
    color: #337ab7;
    font-weight: 600;
  }
}

.pg-daterange-btn-icon-left{
  float: left;
  padding: 10px;
  &::before{
    content: "<" !important;
    font-family: 'Rubik', sans-serif;

  }
}
.pg-daterange-btn-icon-double-left {
  float: left;
  padding: 10px;
  &::before{
    content: "<<" !important;
    font-family: 'Rubik', sans-serif;
  }
}
.pg-daterange-btn-icon-right{
  float: right;
  padding: 10px;
  &::before{
    content: ">" !important;
    font-family: 'Rubik', sans-serif;
  }
}

.pg-daterange-btn-icon-double-right {
  float: right;
  padding: 10px;
  &::before{
    content: ">>" !important;
    font-family: 'Rubik', sans-serif;
  }
}

.pg-daterange-calendar-header-label {
  font-size: 14px;
  font-family: 'Rubik', sans-serif;
  button{
    padding: 5px;
  }
}

.pg-daterange-calendar-decade-separator {
  margin: 0 2px;
  &:after {
    content: '~';
    font-weight: 600;
  }
}

.pg-daterange-calendar-content {
  position: relative;
  height: 224px;
  box-sizing: border-box;
  .cell {
    cursor: pointer;
    text-align: center;
    &:hover {
      color: #fff;
      background-color: #337ab7;
      border-radius: 5px;
    }
    &.active {
      color: #fff;
      background-color: #337ab7;
      border-radius: 4px;
    }
    &.in-range,
    &.hover-in-range {
      color: #fff;
      background-color: #337ab7;
      border-radius: 4px;
    }
    &.disabled {
      cursor: not-allowed;
      color: #ccc;
      background-color: #f3f3f3;
    }
  }
}

.pg-daterange-calendar-week-mode {
  .pg-daterange-date-row {
    cursor: pointer;
    text-align: center;
    &:hover {
      background-color: #337ab7;
    }
    &.pg-daterange-active-week {
      background-color: #337ab7;
    }
    .cell {
      &:hover {
        color: inherit;
        background-color: transparent;
      }
      &.active {
        color: inherit;
        background-color: transparent;
      }
    }
  }
}

.pg-daterange-week-number {
  opacity: 0.5;
}

.pg-daterange-table {
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  text-align: center;

  th {
    padding: 0;
    font-weight: 500;
    vertical-align: middle;
    text-align: center;
  }
  td {
    padding: 0;
    vertical-align: middle;
  }
}

.pg-daterange-table-date {
  td,
  th {
    height: 32px;
    font-size: 12px;
  }

  .today {
    color: #337ab7;
  }
  .cell.not-current-month {
    color: #fff;
    background: none; // cover the in-range style
  }
}

.pg-daterange-time {
  flex: 1;
  width: 224px;
  background: #fff;
  & + & {
    border-left: 1px solid #e8e8e8 ;
  }
}
.pg-daterange-calendar-time {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.pg-daterange-time-header {
  @extend .pg-daterange-calendar-header;
  border-bottom: 1px solid #e8e8e8 ;
}

.pg-daterange-time-content {
  height: 224px;
  box-sizing: border-box;
  overflow: hidden;
}

.pg-daterange-time-columns {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.pg-daterange-time-column {
  flex: 1;
  position: relative;
  border-left: 1px solid #e8e8e8;
  text-align: center;

  &:first-child {
    border-left: 0;
  }
  .pg-daterange-time-list {
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: center;
    &::after {
      content: '';
      display: block;
      height: 32 * 6px;
    }
  }
  .pg-daterange-time-item {
    cursor: pointer;
    font-size: 12px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    &:hover {
      color: #fff;
      background-color: #337ab7;
    }
    &.active {
      color: #337ab7;
      background-color: transparent;
      font-weight: 600;
    }
    &.disabled {
      cursor: not-allowed;
      color: #ccc;
      background-color: #f3f3f3;
    }
  }
}

.pg-daterange-time-option {
  cursor: pointer;
  padding: 8px 10px;
  font-size: 14px;
  line-height: 20px;
  &:hover {
    color: #fff;
    background-color: #337ab7;
  }
  &.active {
    color: #337ab7;
    background-color: transparent;
    font-weight: 600;
    &::after{
      color: #337ab7;
      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18'  viewBox='0 0 24 24'%3E%3Cpath fill='rgb(51,122,183)'  d='M23.334 11.96c-.713-.726-.872-1.829-.393-2.727.342-.64.366-1.401.064-2.062-.301-.66-.893-1.142-1.601-1.302-.991-.225-1.722-1.067-1.803-2.081-.059-.723-.451-1.378-1.062-1.77-.609-.393-1.367-.478-2.05-.229-.956.347-2.026.032-2.642-.776-.44-.576-1.124-.915-1.85-.915-.725 0-1.409.339-1.849.915-.613.809-1.683 1.124-2.639.777-.682-.248-1.44-.163-2.05.229-.61.392-1.003 1.047-1.061 1.77-.082 1.014-.812 1.857-1.803 2.081-.708.16-1.3.642-1.601 1.302s-.277 1.422.065 2.061c.479.897.32 2.001-.392 2.727-.509.517-.747 1.242-.644 1.96s.536 1.347 1.17 1.7c.888.495 1.352 1.51 1.144 2.505-.147.71.044 1.448.519 1.996.476.549 1.18.844 1.902.798 1.016-.063 1.953.54 2.317 1.489.259.678.82 1.195 1.517 1.399.695.204 1.447.072 2.031-.357.819-.603 1.936-.603 2.754 0 .584.43 1.336.562 2.031.357.697-.204 1.258-.722 1.518-1.399.363-.949 1.301-1.553 2.316-1.489.724.046 1.427-.249 1.902-.798.475-.548.667-1.286.519-1.996-.207-.995.256-2.01 1.145-2.505.633-.354 1.065-.982 1.169-1.7s-.135-1.443-.643-1.96zm-12.584 5.43l-4.5-4.364 1.857-1.857 2.643 2.506 5.643-5.784 1.857 1.857-7.5 7.642z'/%3E%3C/svg%3E");
  }}
  &.disabled {
    cursor: not-allowed;
    color: #ccc;
    background-color: #fff;
  }
}

.pg-daterange-scrollbar {
  height: 100%;
  &:hover {
    .pg-daterange-scrollbar-track {
      opacity: 1;
    }
  }
}

.pg-daterange-scrollbar-wrap {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.pg-daterange-scrollbar-track {
  position: absolute;
  top: 2px;
  right: 2px;
  bottom: 2px;
  width: 45px;
  z-index: 1;
  border-radius: 4px;
  opacity: 0;
  transition: opacity 0.24s ease-out;
  .pg-daterange-scrollbar-thumb {
    position: absolute;
    width: 100%;
    height: 0;
    cursor: pointer;
    border-radius: inherit;
    background-color: rgba(144, 147, 153, 0.3);
    transition: background-color 0.3s;
  }
}
</style>
