export default {
  SET_MARKETPLACES(state, payload) {
    state.marketplaces = payload
  },
  SET_SELECT_DATA(state, payload) {
    state.selectMarketplace = payload
  },
  SET_INVOICE_DATA(state, payload) {
    state.invoiceData = payload
    state.badge =! state.badge
  },
  SET_STORE_DATA(state, payload) {
    state.storeData = payload
  },
  SET_COMPANY_DATA(state, payload) {
    state.companyData = payload
    state.badge =! state.badge
  },
  SET_CARRIER_DATA(state, payload) {
    state.carrierData = payload
    state.badge =! state.badge
  },
  SET_CARRIER_DATA2(state, payload) {
    state.carrierData.push({
      id:state.carrierData.length+1,
      carrier:payload.name,
      package_type:'YP',
      carrier_name_def: null,
      ref_number1: null,
      ref_number2: null,
      third_party_zipcode: null,
      third_party_account_number: null,
      payment_method: "SENDER",
      carrier_account: null,
      is_default: false,
      services: payload.services
    })
  },
  SET_QTY_FILE(state, payload) {
    state.qty_file = payload
  },
  SET_AMAZON_ORDERS(state, payload) {
    state.amazon_orders = payload
  },
  SET_WAREHOUSES(state, payload) {
    state.warehouses = payload
  },
  SET_EDIT_DATA(state, payload) {
    state.editWarehouses = payload
  },
  SET_EDIT_ACTION(state, payload) {
    state.editAction = payload
  }
}
