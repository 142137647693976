<template>
  <div class="toast__container" :style="cssVars">
          <div class="toast__cell">
                  <div class="toast toast--yellow add-margin">
                    <div class="toast__icon"  @click="exportFile">
                    <svg xmlns="http://www.w3.org/2000/svg"   class="toast__svg" width="48" height="48" viewBox="0 0 24 24"><path d="M13.042 20h3l-5.021 4-4.979-4h3s0-8 7.812-8c-4.625 1.906-3.812 8-3.812 8zm6.437-12.908c-.212-3.951-3.473-7.092-7.479-7.092s-7.267 3.141-7.479 7.092c-2.57.463-4.521 2.706-4.521 5.408 0 3.037 2.463 5.5 5.5 5.5h1.766c.118-.596.303-1.283.578-2h-2.344c-1.93 0-3.5-1.57-3.5-3.5 0-2.797 2.479-3.833 4.433-3.72-.167-4.218 2.208-6.78 5.567-6.78 3.453 0 5.891 2.797 5.567 6.78 1.745-.046 4.433.751 4.433 3.72 0 1.93-1.57 3.5-3.5 3.5h-2.979c-.289.671-.437 1.38-.497 2h3.476c3.037 0 5.5-2.463 5.5-5.5 0-2.702-1.951-4.945-4.521-5.408z"/></svg>
                      </div>
                      <div class="toast__content">
                        <p class="toast__type">{{title}}</p>
                        <p class="toast__message">{{description}}</p>
                      </div>
                      <div class="toast__close">
                          
                        <vs-tooltip
                                    
                                    class="cursor-pointer printer-tooltip text-warning"
                                    text="When you close it, this log document will be lost."
                                    >
                                    <svg @click="close" version="1.1" height="18" width="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.642 15.642" xmlns:xlink="http://www.w3.org/1999/xlink" enable-background="new 0 0 15.642 15.642">
                                    <path fill-rule="evenodd" d="M8.882,7.821l6.541-6.541c0.293-0.293,0.293-0.768,0-1.061  c-0.293-0.293-0.768-0.293-1.061,0L7.821,6.76L1.28,0.22c-0.293-0.293-0.768-0.293-1.061,0c-0.293,0.293-0.293,0.768,0,1.061  l6.541,6.541L0.22,14.362c-0.293,0.293-0.293,0.768,0,1.061c0.147,0.146,0.338,0.22,0.53,0.22s0.384-0.073,0.53-0.22l6.541-6.541  l6.541,6.541c0.147,0.146,0.338,0.22,0.53,0.22c0.192,0,0.384-0.073,0.53-0.22c0.293-0.293,0.293-0.768,0-1.061L8.882,7.821z"></path>
                                  </svg>
                                </vs-tooltip>
                        
                    </div>
                  </div>
                  </div>
        </div>
</template>

<script>
export default {
    props:{
        title:String,
        description:String,
        alert:{
            type:String,
            default:"warning"
        }
    },
    methods:{
        close(){
            this.$emit('close',true)
        },
        exportFile(){
            this.$emit('export',true)
        }
    },
    computed:{
        cssVars(){
            let color = "#FFC007"
            if(this.alert == "info"){
                color = "#45a7e7"
            }else if(this.alert == "danger"){
                color = "#e44720"
            }
            return{
                "--alert-color":color
            }
        }
    }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Raleway:300,300i,400,400i,500,500i,600,600i,700,700i,800,800i');


.toast__container {  
    font-family: 'Raleway', sans-serif;
    width: 100%;
    padding-right: 10px;
}

.toast__cell{
  display:inline;
}

.add-margin{
  margin-bottom:20px;
}

.toast__svg{
  fill:rgba(32, 56, 117, 0.35);
}

.toast {
    text-align:left;
    padding: 21px 0;
    background-color:#fff;
    border-radius:4px;
    top: 0px;
    position:relative;
    box-shadow: 1px 7px 14px -5px rgba(0,0,0,0.2);
}


.toast:before {
    content: '';
    position: absolute;
    background-color:var(--alert-color);
    top: 0;
    left: 20;
    width: 20px;
    height: 100%;
    border-top-left-radius:4px;
    border-bottom-left-radius: 4px;

}

.toast__icon{
  position:absolute;
  display:grid;
  left:42px;
  place-content: center;
  width:48px;
  height:48px;
  padding: 7px;
  cursor:pointer;
  display:inline-block;
}

.toast__type {
  color: #3e3e3e;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 8px;
}

.toast__message {
    font-size: 14px;
    margin-top: 0;
  margin-bottom: 0;
    color: #878787;
}

.toast__content{
  padding-left:130px;
  padding-right:60px;
}

.toast__close {
    position: absolute;
    right: 64px;
    top: 50%;
    width: 14px;
    cursor:pointer;
    height: 14px;
    fill:#878787;
    transform: translateY(-50%);
}

</style>