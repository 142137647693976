<template>
  <div class="checkbox-container" :style="styles" >
    <span class="checkbox-label" >{{ efsaneAttrs.label }}</span>
    <label class="checkbox-btn" :class="{'disabled':efsaneAttrs.disabled}" :for="elementId" >
      <input
        :data-cy="efsaneAttrs.cy"
        v-bind="efsaneAttrs"
        v-on="efsaneEvents"
        :id="elementId"
        :checked="value"
        :value="value"
        @input="changeValue"
        type="checkbox"
      />
      <div><span class="slide"></span></div>
    </label>
  </div>
</template>

<script>
import Methods from "./methods";
import Attrs from "@/components/efsane-form-elements/attrs";
export default {
  name: "pg-switch",
  mixins: [Attrs, Methods],
  props: {
    value: {},
    efsaneAttrs:Object,
    efsaneEvents:Object
  },
  mounted() {
    document.getElementById(this.elementId).checked = this.value
  },
  data() {
    return {
      elementId: this.makeId(),
    };
  },
  computed:{
    styles(){
      return {
        "--efsane-switch-display-mode": this.efsaneAttrs.display || "flex",
        "--efsane-switch-justify-mode": this.efsaneAttrs.justify || "space-between",
        "--efsane-switch-option-true-label": this.efsaneAttrs.trueLabel || "ON",
        "--efsane-switch-option-false-label": this.efsaneAttrs.falseLabel || "OFF"

      }
    }
  },
  methods: {
    changeValue(e) {
      this.$emit("input", e.target.checked);
    },
  },
};
</script>

<style lang="scss" scoped>
.checkbox-container {
  position: relative;
  width: 100%;
  display: var(--efsane-switch-display-mode);
  justify-content: var(--efsane-switch-justify-mode);
  align-items: center;
  margin: 0;
  gap: 15px;
  padding: 1%;
  font-family: sans-serif;


  .checkbox-label {
    padding-left: .5em;
    font-size: 1.2em;
  }
  .checkbox-btn {
    width: 80px;
    height: 2.4em;
    background: #fff;

    &.disabled{
      div > .slide{
        cursor: not-allowed;
      }
    }

    input {
      position: absolute;
      display: block;
      cursor: pointer;
      opacity: 0;
      &:checked + div .slide {
        transform: translateX(50px);
      }
    }
    div {
      position: relative;
      width: 80px;
      height: 100%;
      border: 2px solid rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
      box-sizing: border-box;
      overflow: hidden;
      .slide {
        position: absolute;
        top: 0;
        left: 0;
        width: 30px;
        height: 100%;
        background: #fff;
        transition: 0.5s;
        &:before {
          content: "YES";
          position: absolute;
          top: 0;
          left: -50px;
          width: 50px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #4caf50;
          font-weight: bold;
          color: #fff;
        }
        &:after {
          content: "NO";
          position: absolute;
          top: 0;
          right: -50px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 50px;
          height: 100%;
          background-color: #d3d3ce;
          font-weight: bold;
          color: #fff;
        }
      }
    }
  }
}
</style>
