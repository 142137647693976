<template>
      <date-picker
        :style="cssVariables"
        v-model="rangeValue"
        prefix-class="pg"
        :placeholder="label"
        :confirm="true"
        :editable="true"
        :lang="'en'"
        :range="false"
        :shortcuts="shortcuts"
        @confirm="confirmDate"
        :confirm-text="'Confirm'">
      </date-picker>
</template>

<script>
import DatePicker from "vue2-datepicker";
import moment from "moment";
export default {
  components:{
    DatePicker
  },
  props:{
    value:{},
    dropdownShow:{
      type:String,
      default:"name"
    },
    dropdownValue:{
      type:String,
      default:"value"
    },
    label:{
      type:String,
      default:"Title"
    },
    data: {
      type:Array,
      default:function (){
        return []
      }
    }
  },
  data(){
    return{
      showSelected:"",
      show:false,
      visibilityLock:true,
      rangeValue:null,
      shortcuts: [
        {
          text: "Today",
          onClick: () => {
            this.rangeValue =  moment().toDate()
          },
        }
      ]
    }
  },
  computed:{
    cssVariables(){
      return {
      }
    }
  },
  methods:{
    close(){
      if(!this.visibilityLock){
        this.show = false
      }
    },
    modelChange(value){
      this.$emit('input', value)
    },
    confirmDate(date){
      this.$emit('input', moment(date).toDate())
    },
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital@1&display=swap');
@import '~vue2-datepicker/scss/index.scss';
.pg-datepicker {
  margin-right: 8px;
  padding-bottom: 10px;
  position: relative;
  display: inline-block;
  max-width:  var(--filter-width); //bu ayar pg-filters-containers'dan geliyor;
  width: var(--filter-width); //bu ayar pg-filters-containers'dan geliyor;
  padding-right: var(--filter-datepicker-padding); //bu ayar pg-filters-containers'dan geliyor;
  svg {
    width: 18px;
    height: 18px;
    fill: rgba(0,0,0,0.4);
    overflow: hidden;
  }
}

.pg-datepicker-range {
  width: 120px;
}

.pg-datepicker-inline {
  width: auto;
}

.pg-input-wrapper {
  position: relative;
  .pg-icon-clear {
    display: none;
  }
  &:hover {
    .pg-icon-clear {
      display: block;
    }
    .pg-icon-clear + .pg-icon-calendar {
      display: none;
    }
  }
}

.pg-input {
  display: inline-block;
  box-sizing: border-box;
  width: 140px;
  height: 33px;
  padding-left: 15px;
  color: #6E6B7B;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 0;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #d4d4d4;
  box-shadow: 0 3px 6px 0 rgba(214, 217, 218, 0.4);
  font-family: "Montserrat", Helvetica, Arial, sans-serif;

  &:hover,
  &:focus {
    border-color: rgba(0,0,0,0.4);
  }
  &:disabled,
  &.disabled {
    color: #ccc;
    background-color: #f3f3f3;
    border-color: #ccc;
    cursor: not-allowed;
  }
  &:focus {
    outline: none;
  }
  &::-ms-clear {
    display: none;
  }
}

.pg-icon-calendar,
.pg-icon-clear {
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  font-size: 16px;
  line-height: 1;
  color: rgba(0, 0, 0, 0.5);
  vertical-align: middle;
}

.pg-icon-clear {
  cursor: pointer;
  &:hover {
    color: rgba(0, 0, 0, 0.8);
  }
}

.pg-datepicker-main {
  font: 14px/1.5 'Helvetica Neue', Helvetica, Arial, 'Microsoft Yahei', sans-serif;
  color: var(--default-color);
  background-color: #fff;
  border: 1px solid #e8e8e8 ;
}

.pg-datepicker-popup {
  margin-top: 1px;
  margin-bottom: 1px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  z-index: 99999999;
}

.pg-datepicker-sidebar {
  float: left;
  box-sizing: border-box;
  width: 100px;
  padding: 6px;
  overflow: auto;
  button{
    color: #337ab7;
  }
}

.pg-datepicker-sidebar + .pg-datepicker-content {
  margin-left: 100px;
}

.pg-datepicker-body {
  position: relative;
  user-select: none;
}

.pg-btn-shortcut {
  display: block;
  padding: 0 6px;
  line-height: 24px;
}

.pg-range-wrapper {
  display: flex;
  @media (max-width: 750px) {
    flex-direction: column;
  }
}

.pg-datepicker-header {
  padding: 6px 8px;
  border-bottom: 1px solid #e8e8e8 ;
}

.pg-datepicker-footer {
  padding: 6px 8px;
  text-align: right;
  border-top: 1px solid #e8e8e8 ;

  button{
    background-color: #337ab7 ;
    padding: 10px 15px;
    border-radius: 4px;
    color: #fff;

  }
  &::before{
     content: "All orders up to selected date";
      position: absolute;
      left: 15px;
      padding-top: 8px;
      padding-left: 15px;
      font-family: 'Rubik', sans-serif;
   }

}

.pg-calendar {
  box-sizing: border-box;
  width: 248px;
  border-left: 1px solid #e8e8e8 ;
  padding: 6px 12px;
  & + & {
    border-left: 1px solid #e8e8e8 ;
  }
}

.pg-calendar-header {
  box-sizing: border-box;
  height: 34px;
  line-height: 34px;
  text-align: center;
  overflow: hidden;
  button{
    color: #337ab7;
  }
}

.pg-btn-icon-left{
  float: left;
  padding: 10px;
  &::before{
    content: "<" !important;
    font-family: 'Rubik', sans-serif;

  }
}
.pg-btn-icon-double-left {
  float: left;
  padding: 10px;
  &::before{
    content: "<<" !important;
    font-family: 'Rubik', sans-serif;
  }
}
.pg-btn-icon-right{
  float: right;
  padding: 10px;
  &::before{
    content: ">" !important;
    font-family: 'Rubik', sans-serif;
  }
}

.pg-btn-icon-double-right {
  float: right;
  padding: 10px;
  &::before{
    content: ">>" !important;
    font-family: 'Rubik', sans-serif;
  }
}

.pg-calendar-header-label {
  font-size: 14px;
  font-family: 'Rubik', sans-serif;
  button{
    padding: 5px;
  }
}

.pg-calendar-decade-separator {
  margin: 0 2px;
  &:after {
    content: '~';
    font-weight: 700;
  }
}

.pg-calendar-content {
  position: relative;
  height: 224px;
  box-sizing: border-box;
  .cell {
    cursor: pointer;
    text-align: center;
    &:hover {
      color: #fff;
      background-color: #337ab7;
      border-radius: 5px;
    }
    &.active {
      color: #fff;
      background-color: #337ab7;
      border-radius: 4px;
    }
    &.in-range,
    &.hover-in-range {
      color: #73879c;
      background-color: #337ab7;
    }
    &.disabled {
      cursor: not-allowed;
      color: #ccc;
      background-color: #f3f3f3;
    }
  }
}

.pg-calendar-week-mode {
  .pg-date-row {
    cursor: pointer;
    text-align: center;
    &:hover {
      background-color: #337ab7;
    }
    &.pg-active-week {
      background-color: #337ab7;
    }
    .cell {
      &:hover {
        color: inherit;
        background-color: transparent;
      }
      &.active {
        color: inherit;
        background-color: transparent;
      }
    }
  }
}

.pg-week-number {
  opacity: 0.5;
}

.pg-table {
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  text-align: center;

  th {
    padding: 0;
    font-weight: 500;
    vertical-align: middle;
    text-align: center;
  }
  td {
    padding: 0;
    vertical-align: middle;
  }
}

.pg-table-date {
  td,
  th {
    height: 32px;
    font-size: 12px;
  }

  .today {
    color: #337ab7;
  }
  .cell.not-current-month {
    color: #ccc;
    background: none; // cover the in-range style
  }
}

.pg-time {
  flex: 1;
  width: 224px;
  background: #fff;
  & + & {
    border-left: 1px solid #e8e8e8 ;
  }
}
.pg-calendar-time {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.pg-time-header {
  @extend .pg-calendar-header;
  border-bottom: 1px solid #e8e8e8 ;
}

.pg-time-content {
  height: 224px;
  box-sizing: border-box;
  overflow: hidden;
}

.pg-time-columns {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.pg-time-column {
  flex: 1;
  position: relative;
  border-left: 1px solid #e8e8e8 ;
  text-align: center;

  &:first-child {
    border-left: 0;
  }
  .pg-time-list {
    margin: 0;
    padding: 0;
    list-style: none;
    &::after {
      content: '';
      display: block;
      height: 32 * 6px;
    }
  }
  .pg-time-item {
    cursor: pointer;
    font-size: 12px;
    height: 32px;
    line-height: 32px;
    &:hover {
      color: #73879c;
      background-color: #337ab7;
    }
    &.active {
      color: #337ab7;
      background-color: transparent;
      font-weight: 700;
    }
    &.disabled {
      cursor: not-allowed;
      color: #ccc;
      background-color: #f3f3f3;
    }
  }
}

.pg-time-option {
  cursor: pointer;
  padding: 8px 10px;
  font-size: 14px;
  line-height: 20px;
  &:hover {
    color: #73879c;
    background-color: #337ab7;
  }
  &.active {
    color: #337ab7;
    background-color: transparent;
    font-weight: 700;
  }
  &.disabled {
    cursor: not-allowed;
    color: #ccc;
    background-color: #f3f3f3;
  }
}
</style>
