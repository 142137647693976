<template>
  <div class="relative efsane-basic-input-container">
    <label :for="elementId" class="flex items-center gap-1 pl-1 mb-1 font-semibold"
    >{{ label
      }}
      <soludio-tooltip v-if="tooltip" :type="tooltipType" :tooltip="tooltip"></soludio-tooltip>

    </label>
      <input
        v-bind="filterAttrs"
        type="number"
        :data-cy="dataCy"
        :id="elementId"
        :value="value"
        @input="(e)=>{$emit('input',e.target.value)}"
      />
  </div>
</template>

<script>
import SoludioTooltip from "@/components/soludio-input/partials/SoludioTooltip.vue";
export default {
  name: "soludio-number-input",
  components: {
    SoludioTooltip
  },
  props: {
    id: String,
    label: String,
    tooltipType: String,
    tooltip: String,
    dataCy: String,
    value:{},
    inputFeatures: {
      type: [Array, String],
    },
    size: {
      type: String,
      default: "base", // ["large","base", "small"]
    },
  },
  data() {
    return {
      elementId: this.id || this.$helper.makeId(),
    };
  },
  computed: {
    filterAttrs() {
      // eslint-disable-next-line no-unused-vars
      const { size, ...attrs } = this.$attrs;
      return attrs;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../css/basic.scss";
.custom-input {
  &:disabled {
    cursor: not-allowed;
  }
}
</style>
