<template>
  <div class="package-detail">
    <efsane-form v-model="formData">
      <template class="row">
        <fieldset col-span="2" no-border>
          <component
            v-for="input in d_line_number"
            :key="input.name"
            :is="input.tag"
            :label="input.label"
            disabled
            :name="input.name"
            @keyup="filter(input, $event)"
            :options="input.getOptions ? input.getOptions() : undefined"
            :option-value="input.optionValue"
            :option-label="input.optionLabel"
            :autocomplete="input.autocomplete"
            :type="input.type"
            :step="input.step"
          ></component>
        </fieldset>
        <fieldset col-span="2" no-border>
          <component
            v-for="input in d_sku"
            :key="input.name"
            :is="input.tag"
            disabled
            :label="input.label"
            :name="input.name"
            @keyup="onInput"
            :type="input.type"
            :step="input.step"
          ></component>
        </fieldset>
        <fieldset col-span="2" no-border>
          <component
            v-for="input in d_quantity"
            :key="input.name"
            :is="input.tag"
            disabled
            :label="input.label"
            :name="input.name"
            @keyup="onInput"
            :type="input.type"
            :step="input.step"
          ></component>
        </fieldset>
        <fieldset col-span="2" no-border>
          <component
            v-for="input in d_unit_cost"
            :key="input.name"
            :is="input.tag"
            disabled
            :label="input.label"
            :name="input.name"
            @keyup="onInput"
            :type="input.type"
            :step="input.step"
          ></component>
        </fieldset>
        <fieldset col-span="2" no-border>
          <component
            v-for="input in d_item"
            :key="input.name"
            :is="input.tag"
            disabled
            :label="input.label"
            :name="input.name"
            @keyup="onInput"
            :type="input.type"
            :step="input.step"
          ></component>
        </fieldset>
        <fieldset col-span="2" no-border>
          <component
            v-for="input in d_marketplace_sku"
            :key="input.name"
            :is="input.tag"
            disabled
            :label="input.label"
            :name="input.name"
            @keyup="onInput"
            :type="input.type"
            :step="input.step"
          ></component>
        </fieldset>
      </template>
      <template class="row">
        <fieldset col-span="3" no-border>
          <component
            v-for="input in d_length"
            :key="input.name"
            :is="input.tag"
            disabled
            :label="input.label"
            :name="input.name"
            @keyup="onInput"
            :type="input.type"
            :step="input.step"
          ></component>
        </fieldset>
        <fieldset col-span="3" no-border>
          <component
            v-for="input in d_width"
            :key="input.name"
            :is="input.tag"
            disabled
            :label="input.label"
            :name="input.name"
            @keyup="onInput"
            :type="input.type"
            :step="input.step"
          ></component>
        </fieldset>
        <fieldset col-span="3" no-border>
          <component
            v-for="input in d_height"
            :key="input.name"
            :is="input.tag"
            disabled
            :label="input.label"
            :name="input.name"
            @keyup="onInput"
            :type="input.type"
            :step="input.step"
          ></component>
        </fieldset>
        <fieldset col-span="3" no-border>
          <component
            v-for="input in d_weight"
            :key="input.name"
            :is="input.tag"
            disabled
            :label="input.label"
            :name="input.name"
            @keyup="onInput"
            :type="input.type"
            :step="input.step"
          ></component>
        </fieldset>
      </template>
    </efsane-form>
    <pg-button-group
      justify="right"
      gap="20"
      style="width: 100%; padding-right: 20px; margin-top:2rem;"
    >
      <pg-button color="success" size="small" @click="updatePackageLine"
        >Update Package Line</pg-button
      >
    </pg-button-group>
  </div>
</template>

<script>
export default {
  name: "PackageLine",
  props: {
    slotData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    pkg: {
      type: Object,
    },
    step: Number,
  },
  data() {
    return {
      optionsItem: [],
      formData: {
        sku: null,
        width: 0,
        length: 0,
        line_number: null,
        item: {
          sku: null,
        },
        depth: 0,
        weight: 0,
        quantity: 0,
        unit_cost: 0,
      },
      d_line_number: [
        {
          name: "line_number",
          tag: "input",
          label: "Line Number",
          disabled: false,
          type: "text",
        },
      ],
      d_sku: [
        {
          name: "sku",
          tag: "input",
          label: "SKU",
          disabled: false,
          type: "text",
        },
      ],
      d_quantity: [
        {
          name: "quantity",
          tag: "input",
          label: "Quantity",
          disabled: false,
          type: "number",
          step: 0.01,
        },
      ],
      d_unit_cost: [
        {
          name: "unit_cost",
          tag: "input",
          label: "Unit Cost",
          disabled: false,
          type: "number",
          step: 0.01,
        },
      ],
      d_item: [
        {
          name: "item_id",
          tag: "select",
          label: "Item",
          disabled: false,
          autocomplete: "off",
          type: "search",
          getOptions: () => {
            return this.optionsItem;
          },
          onFilter: this.onFilterItem.bind(this),
          optionValue: "id",
          optionLabel: "sku",
        },
      ],
      d_marketplace_sku: [
        {
          name: "marketplace_sku",
          tag: "input",
          label: "Marketplace SKU",
          disabled: false,
          type: "text",
        },
      ],
      d_length: [
        {
          name: "length",
          tag: "input",
          label: "Length",
          disabled: false,
          type: "number",
          step: 0.01,
        }
      ],
      d_width: [
        {
          name: "width",
          tag: "input",
          label: "Width",
          disabled: false,
          type: "number",
          step: 0.01,
        }
      ],
      d_height: [
        {
          name: "depth",
          tag: "input",
          label: "Height",
          disabled: false,
          type: "number",
          step: 0.01,
        }
      ],
      d_weight: [
        {
          name: "weight",
          tag: "input",
          label: "Weight",
          disabled: false,
          type: "number",
          step: 0.01,
        }
      ],
    };
  },
  created() {
    this.formData = this.slotData?.slotScope;
  },
  watch: {
    slotData: {
      handler() {
        this.formData = this.slotData?.slotScope;
      },
      deep: true,
    },
  },
  methods: {
    updatePackageLine() {
      if (Object.keys(this.formData).length == 0) {
        return;
      }
      this.$store
        .dispatch("packages/partialUpdatePackageline", {
          package_line_Id: this.slotData.slotScope.id,
          formData: this.formData,
        })
        .then(() => {
          this.$swal({
            icon: "success",
            title: "Partial update",
            text: "Succesfully Sent",
          });
        });
    },
    filter(input, e) {
      this.onInput(e);
      if (input.onFilter) {
        input.onFilter(e);
      } else {
        this.onInput(e);
      }
    },
    onInput(e) {
      if (e.keyCode === 16) {
        e.target.value = this.slotData.slotScope[e.target.name];
      }
    },
    async onFilterItem(e) {
      const res = await this.$store.dispatch("items/searchItems", {
        value: e.target.value,
      });
      this.optionsItem = res.results;
    },
  },
};
</script>

<style lang="scss" scoped>
.package-detail {
  width: 100%;
  padding: 5px 20px 5px 5px;
  margin-top: 1.25rem;
  margin-left: 1.25rem;
}
</style>
