import Vue from 'vue'
import Router from 'vue-router'
import auth from '@/views/auth/store/state'

Vue.use(Router)

let routesArray = [
  {
    path: '/privacy-policy',
    name: 'Privacy Policy',
    component: () => import('./views/privacy'),
    meta: { authRequired: true ,
      activeMenu: '/',
    }
  },

  {
// =============================================================================
// MAIN LAYOUT ROUTES
// =============================================================================
      path: '',
      component: () => import('./layouts/main'),
      meta: { authRequired: true } ,
      children: [
  // =============================================================================
  // Theme Routes
  // =============================================================================
        {
          path: '/',
          name: 'home',
          component: () => import('./views/dashboard'),
          meta: { authRequired: true ,
            activeMenu: '/',
            mainSlug:'reports'
          }
        },
        {
          path: '/warehouse/list',
          name: 'warehouse-list',
          component: () => import('@/views/warehouse/WarehouseListView.vue'),
          meta: {
            activeMenu: '/warehouse/list',
            mainSlug:'inventory',
            authRequired: true,
            pageTitle: 'warehouse',
            rule: 'editor'
          }
        },
        {
          path: '/schedule/list',
          name: 'schedule-list',
          component: () => import('@/views/schedule/ScheduleListView.vue'),
          meta: {
            activeMenu: '/schedule/list',
            mainSlug:'settings',
            authRequired: true,
            pageTitle: 'schedule',
            rule: 'editor'
          }
        },
        {
          path: '/vendor/list',
          name: 'vendor-list',
          component: () => import('@/views/vendor/VendorsListView.vue'),
          meta: {
            activeMenu: '/vendor/list',
            authRequired: true,
            mainSlug:'inventory',
            isHidden: process.env.VUE_APP_INVENTORY_TYPE === 'EXTERNAL',
            pageTitle: 'vendor',
            rule: 'editor'
          }
        },
        {
          path: '/packgroup/list',
          name: 'packgroup-list',
          component: () => import('@/views/packgroup/PackgroupListView.vue'),
          meta: {
            activeMenu: '/packgroup/list',
            authRequired: true,
            mainSlug:'inventory',
            pageTitle: 'Pack Group',
            rule: 'editor'
          }
        },
        {
          path: '/shipping-accounts/list',
          name: 'shipping-accounts-list',
          component: () => import('@/views/shippingAccounts/list.vue'),
          meta: {
            activeMenu: '/shipping-accounts/list',
            authRequired: true,
            mainSlug:'settings',
            pageTitle: 'shipping-accounts',
            rule: 'editor'
          }
        },
        {
          path: '/marketplace/list',
          name: 'marketplace-list',
          component: () => import('@/views/marketplace/MarketplaceListView.vue'),
          meta: {
            activeMenu: '/marketplace/list',
            authRequired: true,
            mainSlug:'settings',
            pageTitle: 'marketplace',
            rule: 'editor'
          }
        },
        {
          path: '/marketplace/edit/:id',
          name: 'marketplace-edit',
          component: () => import('@/views/marketplace/marketplace-detail'),
          meta: {
            activeMenu: '/marketplace/list',
            authRequired: true,
            mainSlug:'settings',
            pageTitle: 'marketplace',
            rule: 'editor'
          }
        },
        {
          path: '/items/list',
          name: 'items-list',
          component: () => import('@/views/items/ItemsListView.vue'),
          meta: {
            activeMenu: '/items/list',
            authRequired: true,
            mainSlug:'inventory',
            pageTitle: 'items',
            rule: 'editor'
          }
        },
        {
          path: '/items/items-map',
          name: 'items-map',
          component: () => import('@/views/items/partials/Rule.vue'),
          meta: {
            activeMenu: '/items/list',
            authRequired: true,
            mainSlug:'inventory',
            pageTitle: 'items',
            rule: 'editor'
          }
        },
        {
          path: '/inventory-adjustment/list',
          name: 'adjustment-list',
          component: () => import('@/views/adjustment/InventoryListView.vue'),
          meta: {
            activeMenu: '/inventory-adjustment/list',
            authRequired: true,
            mainSlug:'inventory',
            isHidden: process.env.VUE_APP_INVENTORY_TYPE === 'EXTERNAL',
            pageTitle: 'inventory',
            rule: 'editor'
          }
        },
        {
          path: '/inventory-adjustment/add',
          name: 'adjustment-add',
          component: () => import('@/views/adjustment/CreateAndEditAdjustment.vue'),
          meta: {
            activeMenu: '/inventory-adjustment/list',
            authRequired: true,
            mainSlug:'inventory',
            isHidden: process.env.VUE_APP_INVENTORY_TYPE === 'EXTERNAL',
            pageTitle: 'inventory add',
            rule: 'editor'
          }
        },
        {
          path: '/purchase-order/list',
          name: 'purchase-order-list',
          component: () => import('@/views/purchase/PurchaseOrderListView.vue'),
          meta: {
            activeMenu: '/purchase-order/list',
            isHidden: process.env.VUE_APP_INVENTORY_TYPE === 'EXTERNAL',
            authRequired: true,
            mainSlug:'inventory',
            pageTitle: 'inventory',
            rule: 'editor'
          }
        },
        {
          path: '/purchase-order/edit/:id',
          name: 'purchase-order-edit',
          component: () => import('@/views/purchase/PurchaseOrderEditView.vue'),
          meta: {
            activeMenu: '/purchase-order/list',
            authRequired: true,
            isHidden: process.env.VUE_APP_INVENTORY_TYPE === 'EXTERNAL',
            mainSlug:'inventory',
            pageTitle: 'inventory edit',
            rule: 'editor'
          }
        },
        {
          path: '/purchase-order/add',
          name: 'purchase-order-add',
          component: () => import('@/views/purchase/PurchaseOrderAddView.vue'),
          meta: {
            activeMenu: '/purchase-order/list',
            isHidden: process.env.VUE_APP_INVENTORY_TYPE === 'EXTERNAL',
            authRequired: true,
            mainSlug:'inventory',
            pageTitle: 'purchase order add',
            rule: 'editor'
          }
        },
        {
          path: '/profile',
          name: 'profile',
          component: () => import('@/views/profile/UserView.vue'),
          meta: {
            activeMenu: '/profile',
            authRequired: true,
            mainSlug:'settings',
            pageTitle: 'My Seller Profile',
            rule: 'editor'
          }
        },{
          path: '/awaiting-shipments/list',
          name: 'Awaiting Shipments',
          component: () => import('@/views/orders/statuses/AwaitingShipments.vue'),
          meta: {
            activeMenu: '/awaiting-shipments/list',
            authRequired: true,
            mainSlug:'orders',
            pageTitle: 'Orders',
            rule: 'editor'
          }
        },{
          path: '/label-created/list',
          name: 'Label Created',
          component: () => import('@/views/orders/statuses/LabelCreated.vue'),
          meta: {
            activeMenu: '/label-created/list',
            authRequired: true,
            mainSlug:'orders',
            pageTitle: 'Label Created Orders',
            rule: 'editor'
          }
        },{
          path: '/printed/list',
          name: 'Printed',
          component: () => import('@/views/orders/statuses/Printed.vue'),
          meta: {
            activeMenu: '/printed/list',
            authRequired: true,
            mainSlug:'orders',
            pageTitle: 'Printed Orders',
            rule: 'editor'
          }
        },{
          path: '/needs-attention/list',
          name: 'Needs Attention',
          component: () => import('@/views/orders/statuses/NeedAttention.vue'),
          meta: {
            activeMenu: '/needs-attention/list',
            authRequired: true,
            mainSlug:'orders',
            pageTitle: 'Needs Attention Orders',
            rule: 'editor'
          }
        },{
          path: '/in-transit/list',
          name: 'In Transit',
          component: () => import('@/views/orders/statuses/InTransit.vue'),
          meta: {
            activeMenu: '/in-transit/list',
            authRequired: true,
            mainSlug:'orders',
            pageTitle: 'In Transit Orders',
            rule: 'editor'
          }
        },{
          path: '/delivered/list',
          name: 'Delivered',
          component: () => import('@/views/orders/statuses/Delivered.vue'),
          meta: {
            activeMenu: '/delivered/list',
            authRequired: true,
            mainSlug:'orders',
            pageTitle: 'Delivered Orders',
            rule: 'editor'
          }
        },{
          path: '/canceled/list',
          name: 'Canceled',
          component: () => import('@/views/orders/statuses/Canceled.vue'),
          meta: {
            activeMenu: '/canceled/list',
            authRequired: true,
            mainSlug:'orders',
            pageTitle: 'Canceled Orders',
            rule: 'editor'
          }
        },
        {
          path: '/jobs/list',
          name: 'Jobs',
          component: () => import('@/views/jobs'),
          meta: {
            activeMenu: '/jobs/list',
            authRequired: true,
            mainSlug:'orders',
            pageTitle: 'Jobs',
            rule: 'editor'
          }
        },
        {
          path: '/printer-jobs/list',
          name: 'Printer Jobs',
          component: () => import('@/views/printerJobs'),
          meta: {
            activeMenu: '/printer-jobs/list',
            authRequired: true,
            mainSlug:'orders',
            pageTitle: 'Printer Jobs',
            rule: 'editor'
          }
        },
        {
          path: '/printer-jobs/list/:id',
          name: 'Printer Jobs Id',
          component: () => import('@/views/printerJobs'),
          meta: {
            activeMenu: '/printer-jobs/list',
            authRequired: true,
            mainSlug:'orders',
            pageTitle: 'Printer Jobs',
            rule: 'editor'
          }
        },
        {
          path: '/orders-on-hold/list',
          name: 'Orders On Hold',
          component: () => import('@/views/orders/statuses/OrdersOnHold.vue'),
          meta: {
            activeMenu: '/orders-on-hold/list',
            authRequired: true,
            mainSlug:'orders',
            pageTitle: 'Orders On Hold',
            rule: 'editor'
          }
        },
        {
          path: '/orders/details/:id',
          name: 'orders details',
          component: () => import('@/views/orders/OrdersEditView.vue'),
          meta: {
            activeMenu: '/shipments/list',
            authRequired: true,
            mainSlug:'orders',
            pageTitle: 'Orders',
            rule: 'editor'
          }
        },
        {
          path: '/orders/add',
          name: 'orders add',
          component: () => import('@/views/orders/OrdersAddView.vue'),
          meta: {
            activeMenu: '/shipments/list',
            authRequired: true,
            mainSlug:'orders',
            pageTitle: 'Orders',
            rule: 'editor'
          }
        },
        {
          path: '/orders/create-bulk-order',
          name: 'create bulk order',
          component: () => import('@/views/orders/OrderCreateBulkView.vue'),
          meta: {
            activeMenu: '/shipments/list',
            authRequired: true,
            mainSlug:'orders',
            pageTitle: 'Orders',
            rule: 'editor'
          }
        },
        {
          path: '/packages/list',
          name: 'packages',
          component: () => import('@/views/packages/PackagesListView.vue'),
          meta: {
            activeMenu: '/packages/list',
            authRequired: true,
            mainSlug:'orders',
            pageTitle: 'Packages',
            rule: 'editor'
          }
        },
        {
          path: '/packages/details/:id',
          name: 'packages details',
          component: () => import('@/views/packages/PackagesEditView.vue'),
          meta: {
            activeMenu: '/packages/list',
            authRequired: true,
            pageTitle: 'Packages',
            mainSlug:'orders',
            rule: 'editor'
          }
        },
        {
          path: '/packages/add',
          name: 'packages add',
          component: () => import('@/views/packages/PackagesAddView.vue'),
          meta: {
            activeMenu: '/packages/list',
            authRequired: true,
            mainSlug:'orders',
            pageTitle: 'Packages',
            rule: 'editor'
          }
        },
        {
          path: '/integrations/list',
          name: 'integrations',
          component: () => import('@/views/integrations/IntegrationList.vue'),
          meta: {
            activeMenu: '/integrations/list',
            authRequired: true,
            mainSlug:'settings',
            pageTitle: 'integrations',
            rule: 'editor'
          }
        },
        {
          path: '/integrations/:id',
          name: 'integrations edit',
          component: () => import('@/views/integrations/IntegrationUpdate.vue'),
          meta: {
            activeMenu: '/integrations/list',
            authRequired: true,
            mainSlug:'settings',
            pageTitle: 'Integrations',
            rule: 'editor'
          }
        },
        {
          path: '/printer/list',
          name: 'printer',
          component: () => import('@/views/printer/PrinterListView.vue'),
          meta: {
            activeMenu: '/printer/list',
            authRequired: true,
            mainSlug:'settings',
            pageTitle: 'Printer',
            rule: 'editor'
          }
        },
        {
          path: '/users/list',
          name: 'users',
          component: () => import('@/views/user/list.vue'),
          meta: {
            activeMenu: '/users/list',
            authRequired: true,
            mainSlug:'settings',
            pageTitle: 'users',
            rule: 'editor'
          }
        },
        {
          path: '/users/:id',
          name: 'user edit',
          component: () => import('@/views/profile/UserView.vue'),
          meta: {
            activeMenu: '/users/list',
            authRequired: true,
            mainSlug:'settings',
            pageTitle: 'Users',
            rule: 'editor'
          }
        },
      ],
  },
// =============================================================================
// FULL PAGE LAYOUTS
// =============================================================================
  {
      path: '',
      component: () => import('@/layouts/auth'),
      children: [
  // =============================================================================
  // PAGES
  // =============================================================================
        {
          path: '/auth/login',
          name: 'auth-login',
          component: () => import('@/views/auth/Login.vue'),
          meta: { authRequired: false }
        },
        {
          path: '/pages/error-404',
          name: 'page-error-404',
          component: () => import('@/views/pages/Error404.vue')
        },

        {
          path: '/pages/error-500',
          name: 'page-error-500',
          component: () => import('@/views/pages/Error500.vue')
        }
      ]
  },
  // Redirect to 404 page, if no match found
  {
      path: '*',
      redirect: '/auth/login'
  }
]


const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior () {
        return { x: 0, y: 0 }
    },
    routes: routesArray
})

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = "none";
    }
})


 router.beforeEach((to, from, next) => {

     if('isHidden' in to.meta && to.meta.isHidden){
       next('/auth/login')
     }

     if (
         to.path === "/auth/login" ||
         to.path === "/auth/forgot-password" ||
         to.path === "/pages/error-404" ||
         to.path === "/pages/error-500" ||
         (auth.isAuthenticated())
     ) {
         next();
     }else{
        next('/auth/login')
     }


    // If auth required, check login. If login fails redirect to login page
    if (to.meta.authRequired) {
      if (!(auth.isAuthenticated())) {
        router.push({ path: '/auth/login', query: { to: to.path } })
      }
    }

    return next()
    // Specify the current path as the customState parameter, meaning it
    // will be returned to the application after auth
    // auth.login({ target: to.path });


})

export default router
