<template>
  <div  v-if="!!integrations.length" class="integrations-timeline-container">
    <sticky-header title="Integrations">
      <template slot="icon">
        <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24"  viewBox="0 0 512 512"><title>Integrations</title><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M304 160l-64-64 64-64M207 352l64 64-64 64"/><circle cx="112" cy="96" r="48" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/><circle cx="400" cy="416" r="48" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/><path d="M256 96h84a60 60 0 0160 60v212M255 416h-84a60 60 0 01-60-60V144" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/></svg>
      </template>
      <template slot="dropdown">
        <integration-actions :order="order" :order-select-data="orderSelectData"></integration-actions>
      </template>
      <template slot="progress">
        <bc-integration-progress
          :create-in-bc-done="!!integrations.some(v => v.is_created) && !!integrations.length"
          :release-in-bc-done="!!integrations.some(v => v.is_released) && !!integrations.length"
          :invoice-in-bc-done="!!integrations.some(v => v.is_invoiced) && !!integrations.length"
          :delete-in-bc-done="!!integrations.some(v => v.is_deleted) && !!integrations.length"
        ></bc-integration-progress>
      </template>
    </sticky-header>

    <tabs>
      <tab :selected="true" name="Overview">
        <efsane-table :data="integrations" :default-columns="columns" table-name="order-detail-integrations">
          <template slot="__created" slot-scope="slotData">
            <span>{{slotData.slotScope.created | formatDate}}</span>
          </template>
        </efsane-table>
      </tab>

      <tab :image="integrationImages[integrate.integration.type]" :name="findCurrectIntegration(integrate)" v-for="(integrate, key) in integrations" :key="key">
      </tab>
    </tabs>
  </div>
</template>

<script>
import StickyHeader from "@/components/pg-order-detail/partials/sticky-header";
import BcIntegrationProgress from "@/components/pg-order-detail/partials/bc-integration-progress";
import quickbooks from "@/assets/images/integrations/quickbooks.png";
import businessCentral from "@/assets/images/integrations/business_central.png";
import IntegrationActions from "@/components/pg-order-detail/partials/integration-actions";

export default {
  name: "integrations-timeline",
  components:{
    StickyHeader,
    BcIntegrationProgress,
    IntegrationActions
  },
  props:{
    order:Object,
    orderSelectData:{
      type:Function,
      default:()=>{}
    },
    integrations:{
      type:Array,
      default:()=>{return []}
    }
  },
  data(){
    return {
      integrationImages:{"quickbooks":quickbooks,"business_central":businessCentral} ,
      columns:[
        {
          type:'row_number',
          header:'Line',
          size:'50'
        },
        {
          type:'slot',
          header:'Created',
          name:'__created',
          size:'1fr'
        },
        {
          type:'data',
          header:'3P PO Number',
          name:'tp_po_number',
          copyable:true,
          size:'1fr'
        },
        {
          type:'data',
          header:'TP Id',
          name:'tp_id',
          size:'1fr'
        }
      ]
    }
  },
  methods:{
    findCurrectIntegration(integration){
      let currect = this.$store.state.integrations.integrationCompanies.find(v => v.value === integration.integration.type)
      return currect ? currect.name : ''
    }
  }
}
</script>

<style lang="scss" scoped>
  .integrations-timeline-container{
    margin-bottom: 50px;
    background-color: #fdfdfd;
    padding-block: 60px;
    padding-inline: 30px;
    scroll-margin-top: 60px;
    margin-inline: 50px;
    border: 1px dashed rgba(0,0,0,.1);
    border-radius: 5px;
  }
  .integration-actions-container{
  margin-inline: 50px;
  display: flex;
  padding-block: 10px;
  gap: 10px;
  margin-top: -10px;
}
</style>
