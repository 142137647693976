<template>
  <div class="integration-actions-container" v-if="order.integrations.length" >
    <pg-button-dropdown color="#fdfdfd" text-color="dark" border title="Select an action" scroll-source=".middle-content-container" width="180px">
      <pg-button click-lock dropdown-button  @click.stop="createBC" :disabled="order.integrations && order.integrations.some(v => !!v.is_created)">Create in integration</pg-button>
      <pg-button click-lock dropdown-button @click.stop="releaseBC" :disabled="order.integrations && order.integrations.some(v => !!v.is_released)">Release in integration</pg-button>
      <pg-button click-lock dropdown-button @click.stop="invoiceBC" :disabled="order.integrations && order.integrations.some(v => !!v.is_invoiced)">Invoice in integration</pg-button>
    </pg-button-dropdown>
  </div>
</template>

<script>
export default {
  name: "integration-actions",
  props:{
    order:Object,
    orderSelectData:{
      type:Function,
      default:()=>{}
    },  
  },
  methods:{
    releaseBC() {
      let obj = {ids:[this.order.id]};
      this.$store
          .dispatch("orders/releaseBC", obj)
          .then(() => {
            this.$swal({
              icon: "success",
              title: "Release in integration",
              text: "Succesfully Sent",
            }).then(()=>{
              this.orderSelectData()
            })
          })
          .catch((error) => {
            this.$swal({
              icon: "error",
              title: "Release in integration",
              text: error,
            });
          });
    },
    createBC() {
      let obj = {ids:[this.order.id]};
      this.$store
          .dispatch("orders/create_bc", obj)
          .then(() => {
            this.$swal({
              icon: "success",
              title: "Create in integration",
              text: "Succesfully Sent",
            }).then(()=>{
              this.orderSelectData()
            })
          })
          .catch((error) => {
            this.$swal({
              icon: "error",
              title: "Create in integration",
              text: error,
            });
          });
    },
    invoiceBC() {
      let obj = {ids:[this.order.id]};
      this.$store
          .dispatch("orders/submitInvoiceInBC", obj)
          .then(() => {
            this.$swal({
              icon: "success",
              title: "Invoice in integration",
              text: "Succesfully Sent",
            }).then(()=>{
              this.orderSelectData()
            })

          })
          .catch((error) => {
            this.$swal({
              icon: "error",
              title: "Invoice in integration",
              text: error,
            });
          });
    },
  }
}
</script>

<style lang="scss" scoped>

.integration-actions-container{
  margin-inline: 50px;
  display: flex;
  padding-block: 10px;
  gap: 10px;
}
</style>
