<template>
  <div class="switch-area bg-gray-100" >
    <label class="type-label" :class="{'selected bg-white':'single' === modelValue}" >
      <svg xmlns="http://www.w3.org/2000/svg" data-cy="item-single" class="h-9 w-9" viewBox="0 0 512 512"><title>Single</title><path d="M448 341.37V170.61A32 32 0 00432.11 143l-152-88.46a47.94 47.94 0 00-48.24 0L79.89 143A32 32 0 0064 170.61v170.76A32 32 0 0079.89 369l152 88.46a48 48 0 0048.24 0l152-88.46A32 32 0 00448 341.37z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M69 153.99l187 110 187-110M256 463.99v-200"/></svg>
      <input :name="elementName" type="radio" value="single" v-model="modelValue">
      <span>Single</span>
    </label>

    <label class="type-label" :class="{'selected bg-white':'alias' === modelValue}" >
      <svg xmlns="http://www.w3.org/2000/svg" data-cy="item-alias" class="h-9 w-9" viewBox="0 0 512 512"><title>Alias</title><rect x="128" y="128" width="336" height="336" rx="57" ry="57" fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="32"/><path d="M383.5 128l.5-24a56.16 56.16 0 00-56-56H112a64.19 64.19 0 00-64 64v216a56.16 56.16 0 0056 56h24" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/></svg>
      <input :name="elementName" type="radio" value="alias" v-model="modelValue">
      <span>Alias</span>
    </label>

    <label class="type-label" :class="{'selected bg-white':'assembly' === modelValue}" >
      <svg xmlns="http://www.w3.org/2000/svg" data-cy="item-assembly" class="h-9 w-9" viewBox="0 0 24 24"><path d="M0 0v24h24v-24h-24zm1 1h11v3.55c0 .932.575 1.357 1.109 1.357.332 0 .672-.156.953-.438.284-.296.389-.469.786-.469.47 0 1.152.534 1.152 1.5s-.682 1.5-1.152 1.5c-.396 0-.501-.173-.785-.468-.281-.283-.621-.438-.953-.438-.535-.001-1.11.424-1.11 1.356v3.55h-3.55c-.536 0-.439-.139-.108-.454.262-.245.658-.616.658-1.394 0-1.039-1.004-2.152-2.5-2.152s-2.5 1.113-2.5 2.152c0 .777.396 1.148.658 1.394.333.317.425.454-.108.454h-3.55v-11zm0 22v-10h3.55c.933 0 1.356-.575 1.356-1.109 0-.332-.155-.672-.438-.953-.294-.284-.468-.388-.468-.786 0-.47.534-1.152 1.5-1.152s1.5.682 1.5 1.152c0 .397-.174.501-.469.785-.282.281-.438.621-.438.953.001.535.425 1.11 1.357 1.11h3.55v3.55c0 .535-.137.44-.454.109-.245-.263-.616-.659-1.394-.659-1.039 0-2.152 1.004-2.152 2.5s1.113 2.5 2.152 2.5c.777 0 1.148-.396 1.394-.659.317-.333.454-.424.454.109v2.55h-11zm22 0h-10v-2.55c0-.932-.575-1.357-1.109-1.357-.332 0-.672.156-.953.438-.284.296-.389.469-.786.469-.47 0-1.152-.534-1.152-1.5s.682-1.5 1.152-1.5c.396 0 .501.173.785.468.281.283.621.438.953.438.534 0 1.109-.425 1.109-1.357v-3.549h3.55c.536 0 .439.139.108.454-.261.245-.657.616-.657 1.394 0 1.039 1.004 2.152 2.5 2.152s2.5-1.113 2.5-2.152c0-.777-.396-1.148-.658-1.394-.333-.317-.425-.454.108-.454h2.55v10zm-2.55-11c-.933 0-1.356.575-1.356 1.109 0 .332.155.672.438.953.294.284.468.388.468.786 0 .47-.534 1.152-1.5 1.152s-1.5-.682-1.5-1.152c0-.397.174-.501.469-.785.282-.281.438-.621.438-.953 0-.534-.424-1.109-1.356-1.109h-3.551v-3.551c0-.535.137-.44.454-.109.245.263.616.659 1.394.659 1.039 0 2.152-1.004 2.152-2.5s-1.113-2.5-2.152-2.5c-.777 0-1.148.396-1.394.659-.317.333-.454.424-.454-.109v-3.55h10v11h-2.55z"/></svg>
      <input :name="elementName" type="radio" value="assembly" v-model="modelValue">
      <span>Assembly</span>
    </label>
  </div>
</template>

<script>
export default {
  name: "pg-radio-switch",
  data(){
    return {
      modelValue:this.value,
      elementName:this.makeId(),
      options: [
        {
          label: "Single",
          value: "single",
        },
        {
          label: "Alias",
          value: "alias",
        },
        {
          label: "Assembly",
          value: "assembly",
        }
      ]
    }
  },
  props:{
    value: {},
  },
  watch:{
    value(newValue){
      this.modelValue = newValue
    },
    modelValue(newValue){
      this.$emit('input', newValue)
    }
  },
  methods:{
    makeId(){
      let length = Math.floor(Math.random() * 30)
      let result           = ["id"];
      let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let charactersLength = characters.length;
      for ( let i = 0; i < length; i++ ) {
        result.push(characters.charAt(Math.floor(Math.random() *
          charactersLength)));
      }
      return result.join('');
    }
  }
}
</script>

<style lang="scss" scoped>

.switch-area{
  border: 1px solid #ddd;
  border-radius: 5px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows:  repeat(3, 1fr);

  .type-label{
    height: 100%;
    display: inline-flex;
    border-radius: 5px;
    justify-content: center;
    position: relative;
    gap: 10px;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    text-align: center;

    input{
      position: absolute;
      width: 0;
      height: 0;
    }
    .radio-icon{
      margin-right: 10px;
    }
    span{
      font-size: calc(var(--pg-radio-font-size) / .6);
      @media (min-width: 768px) {
        font-size: var(--pg-radio-font-size);
      }
    }
    &.selected{
      box-shadow: 3px 10px 20px rgba(0,0,0,.1);
    }
    &:hover:not(.selected){
      background-color: #F7F7F7;
    }
  }
}
</style>
