export default {
  data(){
    return {
      packGroups: [],
      items: [],
      shipmentTypes: [
        { value: "parcel", label: "Parcel" },
        { value: "freight", label: "Freight" },
      ],
      skuOnLabels: [
        { value: "SELF", label: "Show the SKU of this Item" },
        { value: "BASE", label: "Show the SKU of Item's Parts" },
      ],
      itemTypes: [
        { value: "single", label: "Single" },
        { value: "alias", label: "Alias" },
        { value: "assembly", label: "Assembly" },
      ],
      assemblyPackagingTypes: [
        { value: "individual", label: "Use the Shipment Details of the Sub-assemblies" },
        {
          value: "consolidated",
          label: "Enter the Shipment Details for This Item",
        },
      ],
    }
  }
}
