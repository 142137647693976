<template>
  <ul class="progressbar">
    <li class="active">Create Order</li>
    <li :class="{'active':acknowledgedDone}"><abbr :title="acknowledgedDone && 'Succesfully Acknowledged on the Marketplace'">Acknowledge Order</abbr></li>
    <li :class="{'active':submitTrackingDone}"><abbr :title="submitTrackingDone && 'Succesfully Tracking Submitted on the Marketplace'">Submit Tracking</abbr></li>
    <li :class="{'active':invoicedDone}"><abbr :title="invoicedDone && 'Succesfully Invoiced on the Marketplace'">Submit Invoice</abbr></li>
  </ul>
</template>

<script>
export default {
name: "order-progress",
  props:{
    acknowledgedDone:Boolean,
    invoicedDone:Boolean,
    submitTrackingDone:Boolean
  }
}
</script>

<style lang="scss" scoped>
.progressbar {
  counter-reset: step;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
  @media screen and (max-width: 992px){
    margin-top: 15px;
    justify-content: flex-start;
  }
}
.progressbar li {
  list-style: none;
  display: inline-block;
  width: 15%;
  position: relative;
  text-align: center;
  cursor: pointer;
}
.progressbar li:before {
  content: counter(step);
  counter-increment: step;
  width: 30px;
  height: 30px;
  line-height : 30px;
  border: 1px solid #ddd;
  border-radius: 100%;
  display: block;
  text-align: center;
  margin: 0 auto 10px auto;
  background-color: #fff;
}
.progressbar li:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: #ddd;
  top: 15px;
  left: -50%;
  z-index : -1;
}
.progressbar li:first-child:after {
  content: none;
}
.progressbar li:first-child:after {
  content: none;
}
.progressbar li.warning {
  color: orange;
}
.progressbar li.warning:before {
  border-color: orange;
}
.progressbar li.warning + li:after {
  background-color: orange;
}
.progressbar li.active {
  color: green!important;
}
.progressbar li.active:before {
  border-color: green!important;
  font-weight: 600;
}
.progressbar li.active + li:after {
  background-color: green !important;
}
</style>
