import axios from "@/axios";
import requestsHelper from "@/services/helpers/requestsHelper";

export default {
  addItem(ignore, item) {
    return new Promise((resolve, reject) => {
      axios
        .post("/purchase_order/purchase_orders/", item)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  fetchItems({ commit }, options) {
    return new Promise((resolve, reject) => {
      let helpers = new requestsHelper(options);
      let queryset = helpers.createQueryset();

      axios
        .get("/purchase_order/purchase_orders/" + queryset)
        .then(response => {
          commit("SET_ITEMS", response.data.results);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  fetchPurchaseLines(ignore, options) {
    return new Promise((resolve, reject) => {
      let helpers = new requestsHelper(options);
      let queryset = helpers.createQueryset();

      axios
        .get("/purchase_order/purchase_lines/" + queryset)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  fetchSearchItems({ commit }, options) {
    return new Promise((resolve, reject) => {
      let helpers = new requestsHelper(options);
      let queryset = helpers.createQueryset();

      axios
        .get("/purchase_order/purchase_orders/" + queryset)
        .then(response => {
          commit("SET_ITEMS", response.data.results);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  fetchSelectData({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get("/purchase_order/purchase_orders/" + id + "/")
        .then(response => {
          commit("SET_SELECT_DATA", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  updateItem(ignore, item) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/purchase_order/purchase_orders/` + item.id + "/", item)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  removeItem(ignore, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/purchase_order/purchase_orders/` + id + "/")
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  createBulkJson(ignore, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/purchase_order/purchase_orders/bulk_upload_json/", payload)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }


};
