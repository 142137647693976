import {swal} from "vue-swal";

export default {
  SET_MESSAGE(state, payload) {
    console.log(payload);
  },
  SET_IMPORT_FETCH_LOGS(state, payload) {
    state.import_fetch_logs_time = payload.created;
  },
  SET_LAST_MESSAGE(state, payload) {
    state.last_message_date = new Date().getTime();
  },
  SET_UPLOAD_ORDER_WIZARD_STATUS(state, payload) {
    state.order_wizard_status = payload
  },
};
