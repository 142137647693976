import { render, staticRenderFns } from "./bc-integration-progress.vue?vue&type=template&id=13ed6161&scoped=true&"
import script from "./bc-integration-progress.vue?vue&type=script&lang=js&"
export * from "./bc-integration-progress.vue?vue&type=script&lang=js&"
import style0 from "./bc-integration-progress.vue?vue&type=style&index=0&id=13ed6161&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13ed6161",
  null
  
)

export default component.exports