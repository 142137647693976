import axios from "@/axios";
import requestsHelper from "@/services/helpers/requestsHelper";

export default {
  addScheduler(ignore, event){
    return new Promise((resolve, reject) => {
      axios
        .post("/schedule/schedule_set/", {
          name: event.name,
          action: event.action,
          monday: event.monday,
          tuesday: event.tuesday,
          wednesday: event.wednesday,
          thursday: event.thursday,
          friday: event.friday,
          saturday: event.saturday,
          sunday: event.sunday,
          schedule_time: event.schedule_time,
          marketplace: event.marketplace,
          update_type: event.update_type,
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchScheduleSets({ commit }, options){
    let helpers = new requestsHelper(options);
    let queryset = helpers.createQueryset();

    return new Promise((resolve, reject) => {
      axios
        .get("/schedule/schedule_set/" + queryset)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  removeSchedule(ignore, id){
    return new Promise((resolve, reject) => {
      axios
        .delete(`/schedule/schedule_set/` + id + "/")
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchSingleSchedule({commit}, id){
    const obj = {'id': id}
    return new Promise((resolve, reject) => {
      axios
        .post("/schedule/schedule_set/get_single_schedule_set/", obj)
        .then(response => {
          commit("SET_EDIT_ACTION", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  updateSchedule(ignore, event){
    return new Promise((resolve, reject) => {
      axios
        .put(`/schedule/schedule_set/` + event.id + "/", {
          name: event.name,
          action: event.action,
          monday: event.monday,
          tuesday: event.tuesday,
          wednesday: event.wednesday,
          thursday: event.thursday,
          friday: event.friday,
          saturday: event.saturday,
          sunday: event.sunday,
          time: event.time,
          marketplace: event.marketplace,
          schedule_time: event.schedule_time,
          update_type: event.update_type
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchActionChoices(){
    return new Promise((resolve, reject) => {
      axios
        .get("/schedule/schedule_set/list_action_choices/")
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};
