export default {
  methods: {
    searchPackGroups(value) {
      if(!value || value.length < 3) return
      this.$store
        .dispatch("packgroup/fetchItems", {
          search: value,
          limit: 15,
        })
        .then((response) => {
          this.packGroups = response.data.results;
        });
    },
    searchItem(value, force=false) {
      if(!value || (!force && value.length < 3)) return
      this.$spinner.fingerprint()
      this.$store
        .dispatch("items/fetchSearchOriginalAndPartItems", {
          search: value,
          limit: 15,
        })
        .then((response) => {
          this.items = response.data;
        });
        this.$spinner.close();
    },
    changeDescription(value){
      if(!value) return
      if(value.length > 25){
        this.$swal({
          icon: "error",
          title: "Description Exceed 25 Characters. Description truncating..."
        }).then(()=>{
          this.itemData.description = value.substring(0,25)
        })
      }
    }
  }
}
