export default {
    marketplaces:[],
    paymentValues:[], 
    qty_file:[],
    amazon_orders:[],
    selectMarketplace:null,
    invoiceData:null,
    companyData:null,
    carrierData:null,
    quantityData:null,
    storeData:null,
    badge:false,
    editAction:null,
}
