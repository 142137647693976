<template>
  <div class="relative efsane-basic-textarea-container">
    <label :for="elementId" class="flex items-center gap-1 pl-1 mb-1 font-semibold">
      {{ label }}
      <soludio-tooltip v-if="tooltip" :type="tooltipType" :tooltip="tooltip"></soludio-tooltip>
    </label>

    <textarea
      v-bind="filterAttrs"
      :data-cy="dataCy"
      :value="value"
      @input="(e)=>{$emit('input',e.target.value)}"
      :id="elementId"
    ></textarea>
  </div>
</template>

<script>
import SoludioTooltip from "@/components/soludio-input/partials/SoludioTooltip.vue";

export default {
  name: "soludio-textarea-input",
  components: {
    SoludioTooltip
  },
  props: {
    id: String,
    label: String,
    tooltip: String,
    tooltipType: String,
    dataCy: String,
    value:{},
    inputFeatures: {
      type: [Array, String],
    },
    size: {
      type: String,
      default: "base",
      options: ["large", "base", "small"],
    },
  },
  data() {
    return {
      elementId: this.id || this.$helper.makeId(),
    };
  },
  computed: {
    filterAttrs() {
      // eslint-disable-next-line no-unused-vars
      const { size, ...attrs } = this.$attrs;
      return attrs;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../css/basic.scss";
.custom-input {
  &:disabled {
    cursor: not-allowed;
  }
}
</style>
