<template>
  <label v-bind="efsaneInput" :for="elementId">
    <span v-if="efsaneAttrs.label">{{efsaneAttrs.label}}
         <efsane-description-tooltip :tooltip="efsaneAttrs.description"></efsane-description-tooltip>
    </span>
    <input :id="elementId" v-bind="inputAttrs" :data-cy="efsaneAttrs.cy" v-on="efsaneEvents" :value="value" @input="(e)=>{$emit('input',e.target.value)}">
    <span v-if="efsaneAttrs.type === 'password'" class="password-viewer">
      <svg @click="showPassword" v-if="!isShowPassword" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="rgba(0,0,0,.4)" d="M15 12c0 1.657-1.343 3-3 3s-3-1.343-3-3c0-.199.02-.393.057-.581 1.474.541 2.927-.882 2.405-2.371.174-.03.354-.048.538-.048 1.657 0 3 1.344 3 3zm-2.985-7c-7.569 0-12.015 6.551-12.015 6.551s4.835 7.449 12.015 7.449c7.733 0 11.985-7.449 11.985-7.449s-4.291-6.551-11.985-6.551zm-.015 12c-2.761 0-5-2.238-5-5 0-2.761 2.239-5 5-5 2.762 0 5 2.239 5 5 0 2.762-2.238 5-5 5z"/></svg>
      <svg @click="hidePassword" v-else xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="rgba(0,0,0,.4)" d="M19.604 2.562l-3.346 3.137c-1.27-.428-2.686-.699-4.243-.699-7.569 0-12.015 6.551-12.015 6.551s1.928 2.951 5.146 5.138l-2.911 2.909 1.414 1.414 17.37-17.035-1.415-1.415zm-6.016 5.779c-3.288-1.453-6.681 1.908-5.265 5.206l-1.726 1.707c-1.814-1.16-3.225-2.65-4.06-3.66 1.493-1.648 4.817-4.594 9.478-4.594.927 0 1.796.119 2.61.315l-1.037 1.026zm-2.883 7.431l5.09-4.993c1.017 3.111-2.003 6.067-5.09 4.993zm13.295-4.221s-4.252 7.449-11.985 7.449c-1.379 0-2.662-.291-3.851-.737l1.614-1.583c.715.193 1.458.32 2.237.32 4.791 0 8.104-3.527 9.504-5.364-.729-.822-1.956-1.99-3.587-2.952l1.489-1.46c2.982 1.9 4.579 4.327 4.579 4.327z"/></svg>
    </span>
  </label>
</template>

<script>
import Attrs from "./attrs";
import Methods from "./methods";
import EfsaneDescriptionTooltip from "./partials/efsane-description-tooltip";
export default {
  name: "efsane-input",
  mixins:[Attrs, Methods],
  components:{
    EfsaneDescriptionTooltip
  },
  data(){
    return {
      elementId: this.makeId(),
      isShowPassword:false
    }
  },
  props:{
    efsaneAttrs:Object,
    efsaneEvents:Object,
    value: {}
  },
  computed:{
    inputAttrs(){
      let asArray = Object.entries(this.efsaneAttrs);
      let acceptAttrs = ["accept", "alt", "checked", "dirname","disabled", "formaction", "formenctype", "formmethod", "formnovalidate", "formtarget", "height", "list", "max", "maxlength", "min", "minlength", "multiple", "name", "pattern", "placeholder", "readonly", "required", "size", "src", "step", "width", "type"]
      let filtered = asArray.filter(([key, attr]) => acceptAttrs.includes(key))
      return Object.fromEntries(filtered)
    }
  },
  methods: {
    showPassword(){
      this.isShowPassword = true
      let input = document.getElementById(this.elementId)
      input.type = "text"

    },
    hidePassword(){
      this.isShowPassword = false
      let input = document.getElementById(this.elementId)
      input.type = "password"
    }
  }
}
</script>


<style lang="scss" scoped>
@import "css/basic.scss";

</style>
