import axios from "@/axios";
import requestsHelper from "@/services/helpers/requestsHelper";

export default {


  addItem(ignore, item) {
    return new Promise((resolve, reject) => {
      axios
        .post("/vendor/vendors/", item)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  fetchVendors({ commit }, options) {
    return new Promise((resolve, reject) => {
      let helpers = new requestsHelper(options);
      let queryset = helpers.createQueryset();

      axios
        .get("/vendor/vendors/" + queryset)
        .then(response => {
          commit("SET_VENDORS", response.data.results);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  fetchSingleVendor({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get("/vendor/vendors/" + id + "/")
        .then(response => {
          commit("SET_EDIT_DATA", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  updateItem(ignore, item) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/vendor/vendors/` + item.id + "/",item)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  removeItem(ignore, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/vendor/vendors/` + id + "/")
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }


};
