import axios from "@/axios";
import requestsHelper from "@/services/helpers/requestsHelper";

export default {
  addItem(ignore, item) {
    return new Promise((resolve, reject) => {
      axios
        .post("/printer/printers/", {
          name: item.name,
          ip_addr: item.ip_addr,
          port: item.port,
          order: item.order
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  fetchPrinters(ignore, options) {
    let helpers = new requestsHelper(options);
    let queryset = helpers.createQueryset();

    return new Promise((resolve, reject) => {
      axios
        .get("/printer/printers/" + queryset)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  fetchSinglePrinter({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get("/printer/printers/" + id + "/")
        .then(response => {
          commit("SET_EDIT_DATA", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  updateItem(ignore, item) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/printer/printers/` + item.id + "/", {
          name: item.name,
          ip_addr: item.ip_addr,
          port: item.port,
          order: item.order
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  removeItem(ignore, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/printer/printers/` + id + "/")
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  downloadLabels(ignore,options){

    let helpers = new requestsHelper(options);
    let queryset = helpers.createQueryset();

    return new Promise((resolve, reject) => {
      axios
        .get("/printer/printers/download_labels/" + queryset)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });

  }


};
