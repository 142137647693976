<template>
  <component class="soludio-input" :value="value" :data-cy="dataCy" @input="inputValue" @change="changeValue" :is="`${type}-type`" v-bind="$attrs">
  </component>
</template>
<script>
import TextType from "@/components/soludio-input/input-types/Text.vue";
import NumberType from "@/components/soludio-input/input-types/Number.vue";
import TextAreaType from "@/components/soludio-input/input-types/TextArea.vue";
import SelectType from "@/components/soludio-input/input-types/Select.vue";
import SearchType from "@/components/soludio-input/input-types/Search.vue";
export default {
  name: "SoludioInput",
  components: {
    TextType,
    NumberType,
    TextAreaType,
    SelectType,
    SearchType
  },
  props: {
    type: {
      type: String,
      default: "text",
    },
    value:{},
    dataCy: String
  },
  methods: {
    inputValue(value){
      this.$emit('input', value)
    },
    changeValue(value){
      this.$emit('change', value)
    }
  }
};
</script>

<style scoped></style>
