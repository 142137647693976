export default {
  computed:{
  formValidation(){
    return [
      this.assemblyItemsValidation,
      this.aliasItemValidation,
      this.dimensionAndWeightValidation,
      this.assemblyItemsDuplicateValidation,
      !!this.itemData?.sku,
      !!this.itemData?.type,
      !!this.itemData?.length,
      !!this.itemData?.height,
      !!this.itemData?.width,
      !!this.itemData?.weight,
      !!this.itemData?.shipment_type,
      !!this.itemData?.shipping_label_sort_order_prefix,
      !!this.itemData?.pack_group?.id,
      !!this.itemData?.shipment_type,
      !!this.itemData?.print_folder
    ].every(v => v)
  },
  dimensionAndWeightValidation(){
    if(this.itemData.type === 'assembly' && this.itemData.assembly_packaging_type === 'individual') return true
    if(!this.itemData) return false
    return parseFloat(this.itemData.length) >= parseFloat(this.itemData.height) &&
      parseFloat(this.itemData.length) >= parseFloat(this.itemData.width)
  },
  assemblyItemsValidation(){
    if(this.itemData?.type !== 'assembly') return true
    if(!this.itemData?.item_parts || !this.itemData?.item_parts.length) return false
    let validationList  = [...this.itemData.item_parts.map(v => v.item_part_id),...this.itemData.item_parts.map(v => v.quantity)]
    return validationList.every(v => v && (!isNaN(v) && parseInt(v) > 0))
  },
  assemblyItemsDuplicateValidation(){
    if(this.itemData?.type !== 'assembly') return true
    if(!this.itemData?.item_parts || !this.itemData?.item_parts.length) return false
    let assemblies = this.itemData.item_parts.map(v => v.item_part_id)
    let duplicates = assemblies.filter((element, key) => assemblies.indexOf(element) !== key)
    return !duplicates.length
  },
  aliasItemValidation(){
    if(this.itemData?.type !== 'alias') return true
    return !(!this.itemData?.item_part);
  }
  }
}
