<template>
  <div class="flex flex-col gap-6 p-5">
    <soludio-input class="w-2/3 mt-5" v-model="itemData.sku" label="Sku Number" data-cy="edit-assembly-sku" placeholder="Please Enter Sku Number"></soludio-input>
    <div class="flex flex-col gap-4">
      <h3 class="text-xl font-light my-5 flex font-semibold text-gray-700 items-center gap-2">
        <img height="24"
             src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAAAsTAAALEwEAmpwYAAACd0lEQVR4nO1aS07DMBScVbMCBOzopZBQOUShPWQrPl1AWHYNJ6Dp3ijoRYoqGvsl89w4ZCSvOrKfJ7Yz4wYY8YsnAM64rdHjelykForo9ThlgVq0FSBaPW4UAOMKcDGXHJnfuR43rgBEUbxsewCvAB4BTBT8BYCMXE+0FbA+8hrKAUwJ/N4LUMcZgFsAWxnzvWEl/MXPCSvhpAJUuATwKeM+QMcvt0/yApSYybjP0PFfMBABzmXcbyV/NxQBLpQCXAxNgHvlFrgf0ha4BvAl486V/KQPwXN5ktVk3jyvwUN+7uGbCZABWIor2ysd2rELjnLyNwr+yYzQFMBHw8WCr7A6dycTnAda4ULOiCbrbCpAVpt8aUTuxJ1pHFrSaXBZm/xVS4eWtAAb+a188m0dWtICFPJbueTbOrSkBXCBxTD6aMunCeAaWminXfoI7WtN+m/BewFRtRX8YPQR2hfjgWjq6RUYWzJpFAGHMis19hLVa7k0YdapkZYFmFjI5LZiwixTIy0LMJHJeJU7nYkPsUiNtCzAxrQmgvkDWRKygAUmMt6LHIwWqZGWBZJGQcgCScP9B+PxFMF6Mr8RYmQB1TdCjCzADEOO3YczXr5tBejSl6oPl5AAjCyQtAAbQhZIWoAFIQskLUBGyAKmAmQHqdF3cmv5jCxgJsC0ITWuCHxWFjARIDuSGll8JkwEWHpSY1d+7wXYBKTGLvzeC1AEGJQufCZGAWC4BW6N+L0XYOFxaF35TJi9BvM/HBqLz4SpEcoVlxhaPgumVnhy4NB81lbLZyDpMMTAKADGFYBxCzTuif96Bjjjpi0wWj3rCIOxvxGKWQ8Gix/CdpAixnnzhwAAAABJRU5ErkJggg=="/>
        Bill Of Materials (Sub-assemblies)</h3>

      <div v-for="(item, key) in itemData.item_parts" class="w-2/3 flex gap-2 items-end" :key="key">
        <soludio-input  class="w-2/3" type="search" v-model="item.item_part_id" @input="searchItem" :options="items"
                        option-value="id"
                        option-label="sku" label="Item" data-cy="edit-assembly-material" placeholder="Please Select Bill Of Material"></soludio-input>
        <soludio-input class="w-1/3" v-model.number="item.quantity" type="number"
                       label="Quantity" data-cy="edit-assembly-quantity" placeholder="Please Enter Quantity"></soludio-input>

        <pg-button
          @click="addItemPart"
          color="light"
          :data-cy="`button-add-${key}`"
        >
          <img height="24"
               src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAlUlEQVR4nO2UQQrCQAxF5ybJcRwX0sv54406mUNMQe2iXqIuuqmCUGgnlpIHf5sHSfghOHuG0rWhJC9SGbjFxUzMKj2rjFPwtBSP87i4GuyrtoIPs2pq5cyK7nvw+qCjjPhbnOS+vXRBw9HfxBmRFaWCtJDeTqt/4ThfvRQXByvYb2wFf/Q5ipmYMiIpHlM2aCQnVOQNrTmvzzVQx+4AAAAASUVORK5CYII="
          >
        </pg-button>
        <pg-button
          @click="deleteItemPart(key)"
          color="light"
          :data-cy="`button-delete-${key}`"
        >
          <img height="24"
               src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABmJLR0QA/wD/AP+gvaeTAAAD8UlEQVRogcWaz29bRRDHP7N2yA9BUdwkbsoJUU49tFIOqEqCfCftrceK/wIhLkTwn3DtBVVFXHooOI2oqopTTxRxoODaSZW2IBJSe4fD231EENu79u7r92LreXdnvjuzM/NmLSTCo+sX32gcLn6JcANldczw3xW+Opg/+PzizUfHKeTXUywC0Dha/AL4BA0afl7g08XDhgKfpZCfjAjKDQCjZr35zfe7o4b+dm1z3VjdEfRjEhExKRZxOA8wjgTAO7fa907OSYGURF4rZNgPCtLZ2vhR4FKVCo3Azurtnc1hPw63yDYiqM2i0mQYGUaGWuQkOlc3HqO8Zwb2QvPb3Z/T6DUavWtXLgxs7SeQx6u32++PGx90RlTpFqPrzSn1C8YrClmi2g0ZH0TEQA9gILYyIjJwsqSQPQ5hUctbRHRl2JDO1fXvOh9tPJj2+QnNlp3sICJBCVHRLgiCGW4RlQ9PPXGxzx1E5VwxjKchOoZZRKTYFauVuRZCBtdCvGtVR4SCiHrZYxAYtaxbTIaekeRQnKyEUUtrpXkrs4g6WXaQ0LWOzYxzreqIiCOycDyXziLvfn33OXCE8vYvrdbcFPoFwck4Axw17tx5ETInpvrdA5h9q5/9nMwuHHvLB7kVxBBxSVHIn93F1JyMsIgFMUTKXFLLbhHxFYRoBou4MChUkUucDF8aBSCYiEJ1SdGaqGQIE7iWRbITsX6z1KZ3LeMzbJlx80EoZBiTwyJaLCpVuFaZeE0GItZFrQpcC+uI2H6GqDXb97tTgUWKzVJbS2+R5qXdfaAPnNW1tZl47cKgrVYddBEYnPug/Sx0XjAR2cYCzwDZX1pYmkDHIOyfebXi9NpzMoMQ12l0b2t2Zvi7+7T4u1+uHXw+IJKIbwvl7KYYKeosjcjqEEnElJl2RBNiSvjwbkzYm6FHnEUqqbckujyB2DPie0w2n0XKWk4l3xnByNhG3dTwJVDZ8AhDpEVs9iaE+MrBZI1art7KSMS686cRdRZEErG23gXQjN0Uv0kD7ecj8uLNvR6gKMu6nf7azq25BOjL+Zd7MXOjlHF34s+BeudhqxEzNwRP72+epWisH8Tev0+yqz0AMYP0katuJypPYBIi4jJuP32ZIvg2UFx5AhNZxEeuHEnRRndPPOL/+eCz+/9feXc4/eY1/LlPhkLUQYeJiGgXEex/svuwO/CY5xZpCooSdkt1EtGuVRZzNv27+7/FaFzBCBMQEdEnxSeXf71+ZT52/jC4tS4Xa9snsfOjXatuaPctPWCtflj7q7O1EbvE6Tgsv3VrczPt2OnRFlm+de8PxG4h3KdoRqRCH/hBrW6t3Lz7Z+zkfwC3mmUaeWsI8wAAAABJRU5ErkJggg=="

          >
        </pg-button>

      </div>

    </div>
    <div class="flex flex-col gap-5">
      <div class="flex flex-col gap-4">
        <h3 class="text-xl font-light my-5 flex font-semibold text-gray-700 items-center gap-2">
          <img height="24"
               src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yMyA2LjA2NnYxMi4wNjVsLTExLjAwMSA1Ljg2OS0xMS01Ljg2OXYtMTIuMTMxbDExLTYgMTEuMDAxIDYuMDY2em0tMjEuMDAxIDExLjQ2NWw5LjUgNS4wNjl2LTEwLjU3bC05LjUtNC45NDZ2MTAuNDQ3em0yMC4wMDEtMTAuMzg4bC05LjUwMSA0Ljg4OXYxMC41NjhsOS41MDEtNS4wNjl2LTEwLjM4OHptLTUuNTIgMS43MTZsLTkuNTM0LTQuOTY0LTQuMzQ5IDIuMzczIDkuNDA0IDQuODk2IDQuNDc5LTIuMzA1em0tOC40NzYtNS41NDFsOS41NjUgNC45OCAzLjgzMi0xLjk3Mi05LjQwNS01LjE4NS0zLjk5MiAyLjE3N3oiLz48L3N2Zz4="/>
          Item Information</h3>
        <soludio-input v-model="itemData.upc" class="w-2/3" label="UPC (optional)" data-cy="edit-assembly-upc" placeholder="Please Enter UPC"></soludio-input>
        <soludio-input v-model="itemData.description" @input="changeDescription" class="w-2/3" data-cy="edit-assembly-description" label="Description (optional)" placeholder="Please Enter Description" ></soludio-input>
        <soludio-input v-model="itemData.external_sku_ref_list" class="w-2/3" data-cy="edit-assembly-external-sku" type="text-area" tooltip="Enter external SKU typos. Use comma to separate values."
                       label="External SKU Ref List (optional)" placeholder="Please Enter Description" ></soludio-input>
      </div>
      <div class="flex flex-col gap-4">
        <h3 class="text-xl font-light my-5 font-semibold text-gray-700 flex items-center gap-2">
          <img height="24"
               src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTkgMGgtMTRjLTIuNzYyIDAtNSAyLjIzOS01IDV2MTRjMCAyLjc2MSAyLjIzOCA1IDUgNWgxNGMyLjc2MiAwIDUtMi4yMzkgNS01di0xNGMwLTIuNzYxLTIuMjM4LTUtNS01em0zIDE5YzAgMS4zMDItLjgzOSAyLjQwMS0yIDIuODE2di0yLjgxNmgtMXYzaC0ydi0zaC0xdjNoLTJ2LTNoLTF2M2gtMnYtM2gtMXYzaC0ydi0zaC0xdjNoLTJ2LTNoLTF2Mi44MTVjLTEuMTYxLS40MTQtMi0xLjUxMy0yLTIuODE1di0xNGMwLTEuMzAyLjgzOS0yLjQwMSAyLTIuODE2djIuODE2aDF2LTNoMnY2aDF2LTZoMnYzaDF2LTNoMnYzaDF2LTNoMnY2aDF2LTZoMnYzaDF2LTIuODE2YzEuMTYxLjQxNSAyIDEuNTE0IDIgMi44MTZ2MTR6bS0xNC4xNDEtOWgxLjE0MXY2aC0xLjM1NHYtMy44ODloLTEuNDg4di0xYy44OTMtLjA0MSAxLjcwMS0uMjAxIDEuNzAxLTEuMTExem04LjMxNSA0LjY2N2gxLjgyNnYxLjMwNmgtMy45MjJ2LS45NThjMS41MjktMS40OTEgMi4zNzktMi4yNDQgMi4zODktMy4wMTggMC0uNDYyLS4yNjgtLjcxNy0uNzU0LS43MTctLjQ1MyAwLS44MTYuMjQ1LTEuMTY4LjUyNGwtLjQyNC0xLjE0OGMuNTQ1LS40NTIgMS4xOTEtLjY1NiAxLjgzMy0uNjU2IDEuMTc5IDAgMS45NDEuNzQgMS45NDEgMS44ODUuMDAxIDEuMTU1LS44OTkgMi4wNDMtMS43MjEgMi43ODJ6Ii8+PC9zdmc+"/>
          Shipment Details</h3>
        <soludio-input v-model="itemData.assembly_packaging_type" type="select" :options="assemblyPackagingTypes" option-value="value" option-label="label" class="w-2/3" data-cy="edit-assembly-shipment-details" label="Assembly Item Shipment Details" placeholder="Please Select the Assembly Item Shipment Details"></soludio-input>

        <div class="flex gap-2 w-2/3" v-if="itemData.assembly_packaging_type === 'consolidated'">
          <soludio-input v-model="itemData.length" class="w-1/3" data-cy="edit-assembly-length" type="number" tooltip="Length can not be less than width and height."
                         label="Length(In.)" placeholder="Please Enter Length"></soludio-input>
          <soludio-input v-model="itemData.width" class="w-1/3" type="number" label="Width(In.)" data-cy="edit-assembly-width"
                         placeholder="Please Enter Width"></soludio-input>
          <soludio-input v-model="itemData.height" class="w-1/3" type="number" label="Height(In.)" data-cy="edit-assembly-height"
                         placeholder="Please Enter Height"></soludio-input>
        </div>
        <template v-if="itemData.assembly_packaging_type === 'consolidated'">
          <soludio-input v-model="itemData.weight" class="w-2/3" type="number" label="Weight(In.)" data-cy="edit-assembly-weight"
                         placeholder="Please Enter Weight"></soludio-input>
          <soludio-input v-model="itemData.pack_group.id"
                         @input="searchPackGroups"
                         option-value="id"
                         option-label="name" :options="packGroups" type="search" class="w-2/3" data-cy="edit-assembly-packgroup" label="Packgroup" tooltip="Maximum number of items can be packed together."
                         placeholder="Please Enter Packgroup"></soludio-input>
          <soludio-input v-model="itemData.print_folder" class="w-2/3" data-cy="edit-assembly-print-folder" label="Print Folder" tooltip="Assign labels to designated folders."
                         placeholder="Please Enter Print Folder"></soludio-input>
          <soludio-input v-model="itemData.shipment_type" type="select" :options="shipmentTypes" option-value="value" data-cy="edit-assembly-shipment-type" option-label="label" class="w-2/3" label="Shipment Type" placeholder="Please Enter Shipment Type"></soludio-input>
          <soludio-input v-model="itemData.sku_on_label" type="select" :options="skuOnLabels" option-value="value" data-cy="edit-assembly-sku-on-label" option-label="label" class="w-2/3" label="SKU On Label" placeholder="Please Enter SKU On Label"></soludio-input>
          <soludio-input v-model="itemData.shipping_label_sort_order_prefix" class="w-2/3" data-cy="edit-assembly-sort-order" label="Shipping Label Sort Order Prefix"
                         placeholder="Please Enter Shipping Label Sort Order Prefix"></soludio-input>
        </template>
      </div>
    </div>
    <div class="flex">
      <pg-button @click="save" :disabled="!formValidation" color="green" data-cy="edit-assembly-save-button" class="w-32">Save</pg-button>
    </div>
  </div>
</template>

<script>
import Data from "@/views/items/item/forms/data";
import Methods from "@/views/items/item/forms/methods";

export default {
  name: "EditAssembly",
  mixins: [Data, Methods],
  props: {
    close: {
      type: Function,
      default: ()=> {}
    },
    setItemParts: {
      type: Function,
      default: ()=> {}
    },
    currentItemData: Object,
    itemId:[String, Number],
    resetForm: Boolean
  },
  data(){
    return {
      itemData: {
        id:null,
        type: 'assembly',
        sku: null,
        upc: null,
        description: null,
        external_sku_ref_list: "",
        assembly_packaging_type: null,
        length: 0,
        width: 0,
        height: 0,
        weight: 0,
        pack_group: {
          id: null
        },
        item_parts: [
          {
            item_part_id: null,
            quantity: 1
          }
        ],
        sku_on_label: "SELF",
        print_folder: null,
        shipping_label_sort_order_prefix: null,
        shipment_type: null
      },
    }
  },
  computed: {
    dimensionValidation(){
      if(!this.itemData) return false;
      return parseFloat(this.itemData.length) >= parseFloat(this.itemData.height) &&
        parseFloat(this.itemData.length) >= parseFloat(this.itemData.width)
    },
    consolidatedValidation(){
      if(this.itemData.assembly_packaging_type !== "consolidated") return true
      return !!this.itemData?.length &&
        !!this.itemData?.height &&
        !!this.itemData?.width &&
        !!this.itemData?.weight &&
        !!this.itemData?.pack_group.id &&
        !!this.itemData?.print_folder &&
        !!this.itemData?.shipment_type &&
        !!this.itemData?.sku_on_label &&
        !!this.itemData?.shipping_label_sort_order_prefix &&
        this.dimensionValidation
    },
    assemblyPartsValidation(){
      if(!this.itemData?.item_parts || !this.itemData?.item_parts.length) return false
      let validationList  = [...this.itemData.item_parts.map(v => v.item_part_id),...this.itemData.item_parts.map(v => v.quantity)]
      return validationList.every(v => v && (!isNaN(v) && parseInt(v) > 0))
    },
    formValidation(){
      return !!this.assemblyPartsValidation &&
        !!this.consolidatedValidation &&
        !!this.itemData?.sku &&
        !!this.itemData?.assembly_packaging_type
    }
  },
  created() {
    this.searchPackGroups(this.currentItemData?.pack_group?.id)
    this.setItemFormData(this.currentItemData)
    if (this.resetForm){
      this.reset();
    }
  },
  methods: {
    reset(){
      this.itemData.length = 0;
      this.itemData.height = 0;
      this.itemData.width = 0;
      this.itemData.weight = 0;
      this.itemData.pack_group = {id: null}
      this.itemData.print_folder = null;
      this.itemData.shipment_type = null;
      this.itemData.sku_on_label = 'SELF';
      this.itemData.shipping_label_sort_order_prefix = null;
      this.itemData.upc = null;
      this.itemData.description = null;
      this.itemData.external_sku_ref_list = "";
      this.itemData.assembly_packaging_type = "";
      this.itemData.item_parts = [
          {
            item_part_id: null,
            quantity: 1
          }
        ]
    },
    addItemPart() {
      if (
        !this.itemData.item_parts ||
        !this.itemData.item_parts.length
      ) {
        this.itemData.item_parts = [];
      }
      this.itemData.item_parts.push({
        item_part_id: null,
        quantity: 1,
      });
    },
    deleteItemPart(key) {
      if (this.itemData.item_parts.length > 1){
        this.itemData.item_parts.splice(key, 1);
      }
    },
    checkPartQuantity(){
      if(!this.itemData.item_parts || this.itemData.item_parts.length === 0) return
      return !(this.itemData.item_parts.length === 1 && this.itemData.item_parts[0].quantity === 1)
    },
    checkDuplicate(){
      if(!this.itemData.item_parts || this.itemData.item_parts.length === 0) return
      let partIds = []
      this.itemData.item_parts.forEach((part, key) => {
        if(partIds.includes(part.item_part_id)){
          this.$swal({
            icon: "error",
            title: "Item Already Exists. Duplicate Item removing..."
          }).then(()=>{
            this.itemData.item_parts.splice(key, 1)
          })
        }
        partIds.push(part.item_part_id)
      })
    },
    setItemDataFromOriginalItem(originalItemData){
      Object.entries(originalItemData).forEach(([key,value])=> {
          this.itemData[key] = value
      })
      this.searchPackGroups(this.itemData.pack_group.id)
    },
    setItemFormData(data){
      if(!data) return;
      Object.entries(this.itemData).forEach(([key, value])=> {
        if(!["sku_on_label", "item_parts"].includes(key)){
          this.itemData[key] = data[key]
        }
        if(key === "item_parts" && data.item_parts.length > 0){
          this.itemData.item_parts = data.item_parts
          this.searchItem(data.item_parts.map(v => v.item_part_id).join(","))
        }
      })
      this.itemData.sku_on_label = data['sku_on_label'];
    },
    save(){
      if(!this.checkPartQuantity()){
        this.$swal({
          icon: "warning",
          title: "Select alias as item type.",
          text: "The information you have selected is suitable for the alias type. Change item type to alias.",
        })
        return
      }
      let {pack_group, ...data} = this.itemData
      if(data.assembly_packaging_type === "individual"){
        data.shipping_label_sort_order_prefix = ""
        data.length = 0
        data.weight = 0
        data.width = 0
        data.height = 0
        data.print_folder = ""
        data.shipment_type = ""
        data.sku_on_label = "BASE"
      }
      data["pack_group_id"] = pack_group.id
      data["type"] = "assembly"
      this.$store
        .dispatch("items/updateItem", data)
        .then(() => {
          this.$swal({
            icon: "success",
            title: "Item Updated Successfully",
            text: "Successfully Updated",
          })
          this.$emit('reset', false)
        })
        .catch((error) => {
          if (error.status && error.status === 205) {
            error = "Item Already Exist";
          }
          if (error.response.status === 400){
            error = error.response.data['error']
          }
          this.$swal({
            icon: "error",
            title: "Failed to update product",
            text: error || "An error occured",
          });
        });
    },
  },
  watch: {
    currentItemData: {
      handler(newValue){
        if(!newValue) return
        this.searchPackGroups(this.currentItemData?.pack_group?.id)
        this.setItemFormData(this.currentItemData)
      }
    },
    "itemData.item_parts": {
      handler(newValue){
        this.checkDuplicate()
        this.setItemParts(newValue)
      },
      deep: true
    },
  }
}
</script>

<style scoped>

</style>
