<template>
  <div class="items-timeline-container">
    <sticky-header title="Items">
      <template slot="icon">
        <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" viewBox="0 0 512 512"><title>Items</title><path fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="32" d="M336 320H32L184 48l152 272zM265.32 194.51A144 144 0 11192 320"/></svg>
      </template>
      <template slot="dropdown">
        <item-actions :order-select-data="orderSelectData"></item-actions>
      </template>
    </sticky-header>

    <tabs v-if="!!items.length">
      <tab name="Overview" :selected="true">
        <efsane-table :data="items" :default-columns="columns" table-name="order-detail-items">
          <template slot="__dimensions" slot-scope="slotData">
            <span>{{slotData.slotScope.length}}x{{slotData.slotScope.width}}x{{slotData.slotScope.height}}</span>
          </template>
        </efsane-table>
      </tab>
      <tab :name="item.sku" v-for="(item, key) in items" :key="key">
          <item-detail :item-id="item.id"></item-detail>
      </tab>
    </tabs>
    <div v-else>Items Not Found</div>




  </div>
</template>

<script>
import StickyHeader from "@/components/pg-order-detail/partials/sticky-header";
import ItemActions from "@/components/pg-order-detail/partials/item-actions";
import ItemDetail from "@/components/pg-order-detail/partials/item-detail.vue";


export default {
  name: "items-timeline",
  components:{
    ItemDetail,
    StickyHeader,
    ItemActions
  },
  props:{
    items:{
      type:Array,
      default:()=>{return []}
    },
    orderSelectData:{
      type:Function,
      default:()=>{}
    }
  },
  data(){
  return {
    columns:[
      {
        type:'row_number',
        header:'Line',
        size:'50'
      },
      {
        type:'data',
        header:'SKU',
        name:'sku',
        copyable:true,
        size:'2fr'
      },
      {
        type:'data',
        header:'Pack Group',
        name:'pack_group_name',
        size:'1fr'
      },
      {
        type:'slot',
        header:'Dimensions(LxWxH)',
        name:'__dimensions',
        size:'1fr'
      },
      {
        type:'data',
        header:'Weight',
        name:'weight',
        size:'1fr'
      },
      {
        type:'data',
        header:'Unit Cost',
        name:'cost',
        size:'1fr'
      }
    ]
  }
}
}
</script>

<style lang="scss" scoped>
  .items-timeline-container{
    margin-bottom: 50px;
    padding-block: 60px;
    padding-inline: 30px;
    margin-inline: 50px;
    scroll-margin-top: 60px;
    border: 1px dashed rgba(0,0,0,.1);
    border-radius: 5px;
    background-color: #fdfdfd;
  }
  .item-actions-container{
  margin-inline: 50px;
  display: flex;
  padding-block: 10px;
  gap: 10px;
  margin-top: -10px;
}
</style>
