const moment = require('moment-timezone');
const defaultTimezone = "America/New_York"
const defaultFormat = "YYYY-MM-DD"
export default {
    timezone(value=defaultTimezone){
        return moment().tz(value).format(defaultFormat);
    },
    dateTimezone(date,value=defaultTimezone){
        return moment(date).tz(value).format(defaultFormat);
    },
    nativeTimezone(withFormat = false){
        if(withFormat){
            return this.timezone()
        }
        return moment().tz(defaultTimezone)
    },
    makeId(length) {
        let result           = [];
        let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let charactersLength = characters.length;
        for ( let i = 0; i < length; i++ ) {
            result.push(characters.charAt(Math.floor(Math.random() *
            charactersLength)));
        }
       return result.join('');
    },
  formatDate(value){
    if (value) {
      return moment(value).format("MM/DD/YYYY hh:mm A");
    }
  }
}
