export default {
  watch:{
    lazyLoad(){
      this.lazyLoadFlag = true
    },
    show(newValue){
      if(!newValue) return
      this.orderselectData()
      this.currentId = this.value
    },
    value(newValue){
      this.orderselectData()
      this.currentId = newValue
    },
    currentId(newValue){
      this.$emit('input',newValue)
    },
  }
}
