<template>
  <div :class="'sticky-header ' + this.className ">
    <div class="sticky-title">
      <slot name="icon"></slot>
      <h2 >{{ title }}</h2>
    </div>
    <slot name="dropdown"></slot>
    <slot name="progress"></slot>
  </div>
</template>

<script>
export default {
  name: "stick-header",
  props:{
    title:String,
    className:{
      type: String,
      default: ""
    }
  }
}
</script>

<style lang="scss" scoped>

.sticky-header{
  position: sticky;
  top: 0;
  display: flex;
  height: max-content;
  left: 10px;
  right: 0;
  padding-bottom: 25px;
  width: 100%;
  z-index: 10000001;
  background-color: #fdfdfd;

  @media screen and (max-width: 992px){
    display: block;
  }



  .sticky-title{
    width: max-content;
    display: flex;
    gap: 10px;
    svg{
      margin-top: 3px;
    }
    *{
      display: inline-block;
    }
  }



}
.stickyheader-yellow{
    background-color: #fffbeb !important;
  }
</style>
