<template>
  <div class="flex flex-col gap-6 p-5">
    <div class="flex items-center gap-5 mb-5">
      <soludio-image height="48" width="48" :name="marketplaceInfo.store"></soludio-image>
      <h2 class="text-lg">{{marketplaceInfo.marketplace_name | title}}</h2>
    </div>
    <soludio-input v-model="marketplaceInfo.sku" label="Marketplace Sku Number" placeholder="Please Enter Sku Number"></soludio-input>
    <soludio-input v-if="marketplaceInfo.manufacturer_sku" v-model="marketplaceInfo.manufacturer_sku" label="Manufacturer SKU" placeholder="Please Enter Manufacturer SKU"></soludio-input>
    <soludio-input type="number" v-model="marketplaceInfo.threshold" label="Threshold" placeholder="Please Enter Threshold"></soludio-input>
    <soludio-input v-if="marketplaceInfo.type" v-model="marketplaceInfo.type" disabled label="Type" placeholder="Please Enter Type"></soludio-input>
    <soludio-input v-model="marketplaceInfo.upc" label="UPC" placeholder="Please Enter UPC"></soludio-input>
    <soludio-input v-model="marketplaceInfo.last_sent_quantity" disabled="" label="Last Sent Quantity"></soludio-input>
    <soludio-input v-model="marketplaceInfo.last_sent_quantity_date" disabled="" label="Last Sent Quantity Date"></soludio-input>

    <div class="flex">
      <pg-button @click="save" :disabled="!formValidation" color="green" class="w-32">Save</pg-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "EditMarketplaceInfo",
  props: {
    marketplaceInfoData: Object,
    currentItemData: Object
  },
  data() {
    return {
      marketplaceInfo: {
        id: null,
        sku: null,
        marketplace_id: null,
        manufacturer_sku: null,
        marketplace_name: null,
        store: null,
        upc: null,
        threshold: null,
        type: null,
        last_sent_quantity: null,
        last_sent_quantity_date: null
      }
    }
  },
  computed: {
    formValidation(){
      return !!this.marketplaceInfo?.sku
    }
  },
  mounted() {
    this.setData()
  },
  methods: {
    setData(){
      if(!this.marketplaceInfoData) return
      Object.entries(this.marketplaceInfo).forEach(([key, value]) => {
        this.marketplaceInfo[key] = this.marketplaceInfoData[key]
      })
    },
    save(){
      let data = {} 
      data.marketplace_item_info = this.marketplaceInfo;
      data.id = this.currentItemData.id;
      console.log(data);
      this.$store
        .dispatch("items/updateItem", data)
        .then(() => {
          this.$swal({
            icon: "success",
            title: "Marketplace Item Info Updated Successfully",
            text: "Successfully Updated",
          })
        })
        .catch((error) => {
          if (error.status && error.status === 205) {
            error = "Item Already Exist";
          }
          this.$swal({
            icon: "error",
            title: "Failed to update item info",
            text: error || "An error occured",
          });
        });
    }
  },
  watch: {
    marketplaceInfoData: {
      handler(){
        this.setData()
      },
      deep: true
    }
  }
}
</script>

<style scoped>

</style>
