<template>
  <div  class="h-20 w-80 bg-white w-full rounded select-none shadow flex justify-evenly hover:bg-gray-100 items-center cursor-pointer">
    <div class="w-1/2 flex flex-col items-center justify-center" >
      <div class=" flex flex-col items-center justify-center truncate ">
        <span class="font-middle w-24 text-center">{{ sku | title }}</span>
      </div>
      <div class="text-xs font-semibold">
        {{ type}}
      </div>
    </div>

    <div class="flex items-center justify-center">
      <soludio-image name="warehouse" class="h-12 w-12"></soludio-image>
    </div>
    <div class="w-1/2 flex flex-col items-center justify-center" >
      <div v-if="elapsedText(updated) !== 'Never updated'"><span class="font-semibold px-1">{{ quantity }}</span> <span class="text-xs">Quantity</span></div>

      <div class="text-xs">
        {{ elapsedText(updated) }}

      </div>

    </div>
  </div>

</template>

<script>
export default {
  name: "ExternalItemInventory",
  props: {
    updated: String,
    sku: String,
    type: String,
    quantity: [Number, String]
  },
  methods: {
    elapsedText(dateValue){
      if(!dateValue) return "Never updated"
      const date = new Date(dateValue);

      const now = new Date();
      const elapsedMilliseconds = now.getTime() - date.getTime();
      const elapsedSeconds = Math.floor(elapsedMilliseconds / 1000);

      let elapsedTimeText = "";
      if (elapsedSeconds < 60) {
        elapsedTimeText = `${elapsedSeconds} seconds ago`;
      } else if (elapsedSeconds < 60 * 60) {
        const minutes = Math.floor(elapsedSeconds / 60);
        elapsedTimeText = `${minutes} ${minutes === 1 ? 'minute' : 'minutes'} ago`;
      } else if (elapsedSeconds < 60 * 60 * 24) {
        const hours = Math.floor(elapsedSeconds / 60 / 60);
        elapsedTimeText = `${hours} ${hours === 1 ? 'hour' : 'hours'} ago`;
      } else if (elapsedSeconds < 60 * 60 * 24 * 7) {
        const days = Math.floor(elapsedSeconds / 60 / 60 / 24);
        elapsedTimeText = `${days} ${days === 1 ? 'day' : 'days'} ago`;
      } else {
        const weeks = Math.floor(elapsedSeconds / 60 / 60 / 24 / 7);
        elapsedTimeText = `${weeks} ${weeks === 1 ? 'week' : 'weeks'} ago`;
      }
      return elapsedTimeText
    },
  }
}
</script>

<style scoped>

</style>

