<template>
  <div class="pg-input-container" >
      <label v-if="label" :for="id">
          <span>
              {{label}}
          </span>
            <vs-tooltip
                v-if="required"
                class="cursor-pointer label-tooltip"
                text="This Field Required">
                <vs-icon icon="add_moderator" size="16px" :color="requiredColor"></vs-icon>
        </vs-tooltip>
          
      </label>
      <input class="pg-input" 
                :ref="id" 
                @focus="focusWarning = true; isFocus=true" 
                @input="(e)=>{$emit('input',e.target.value)}"
                v-click-outside="()=>{focusWarning = false}" 
                :disabled="disabled" 
                :name="name" 
                :id="id" 
                :type="type" 
                :placeholder="placeholder"
                :value="value" 
                :data-vv-as="label" 
                v-validate="validation">
      <span
        class="text-danger text-sm"
        v-show="errors.has(name)">
        {{ errors.first(name) }} 
        </span>
  </div>
</template>

<script>
export default {
    props:{
        type:{
            type:String,
            default:"text"
        },
        label:{
            type:String,
            default:""
        },
        name:{
            type:String,
            default:""
        },
        placeholder:{
            type:String,
            default:""
        },
        disabled:{
            type:Boolean,
            default:false
        },
        value:{},
        validate:{
            type:String,
            default:""
        },
        required:{
            type:Boolean,
            default:false
        }
    },
    data(){
        return{
            id:this.$helper.makeId(10),
            focusWarning:false,
            isFocus:false
        }
    },
    computed:{
        requiredColor(){
            return !this.value && this.isFocus ? 'var(--pg-danger-color)' : this.focusWarning ? 'var(--pg-warning-color)' : 'var(--pg-fourth-color)'
        },
        validation(){
            return this.required && this.validate ? 'required|'+this.validate : this.required  ? 'required' : this.validate
        },
        errorHandle(){
            return (!this.value || this.errors.has(this.name))
        }
    },
    mounted(){
           this.validation && (this.errorHandle && this.$emit('error',true))
           setTimeout(() => {
               if(this.validation && !this.value){
                   this.$refs[this.id] && this.$refs[this.id].focus()
               }
           }, 1500);
    },
    watch:{
        errorHandle(newV){
            this.validation && (newV ? this.$emit('error',true) : this.$emit('error',false))
        }
    }
}
</script>

<style lang="scss" scoped>
    @import url("https://fonts.googleapis.com/css?family=Poppins:400,500,600,700,800,900&display=swap");
    .pg-input-container{
        display: inline-block;
        outline: none;
        text-transform: none;
        text-decoration: none;
        label{
            font-size: .85rem;
            color: rgba(0,0,0,.7);
            padding-left: .7rem;
        }
    }
    .pg-input{
        border-radius: 5px;
        display: block;
        height: 36px;
        font-size: .9rem;
        font-family: 'Poppins','Sans Serif';
        box-sizing: border-box;
        color: rgba(0,0,0,0.7);
        border: 1px solid rgba(0,0,0,0.2);
        transition: all .3s ease;
        background-color: #fff;
        padding-left: .7rem;
        width: 100%;
        &:focus{
            border: 1px solid var(--pg-fourth-color);
            box-shadow: 0 1px 3px rgba(0,0,0,0.2);
        }
    }
</style>