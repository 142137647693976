import urljoin from "url-join";
import store from "@/store/store"
const baseWSURL = (process.env.VUE_APP_WS_URL !== undefined) ? process.env.VUE_APP_WS_URL : 'ws://localhost:6381/'
const websocketURL = urljoin(baseWSURL,'notifications')

if (!window.WebSocket) alert("WebSocket not supported by this browser");

export const websocket  = {
  connect() {
    const accessToken = localStorage.getItem("accessToken");
    this.incoming_ws = new WebSocket(`${websocketURL}?${accessToken}`);
    this.incoming_ws.onopen = this.onopen;
    this.incoming_ws.onmessage = this.onmessage;
    this.incoming_ws.onclose = this.onclose;
    this.incoming_ws.onerror = this.onerror;
  },

  onopen() {
    console.debug("WebSocket Connected");
  },

  onmessage(message) {
    let data = JSON.parse(message.data)

    store.dispatch('websocket/lastMessage',message).then(()=>{})
      switch (data.message.name){
        case "frontend_import_fetch_logs":
          store.dispatch('websocket/importFetchLogs',data.message).then(()=>{})
          break;
        case "frontend_upload_order_wizard":
          store.dispatch('websocket/uploadOrderWizard',data.message).then(()=>{})
          break;
      }
  },

  disconnect(){
    this.incoming_ws.close()
  },

  onclose() {
    console.debug("WebSocket Closed");
  },

  onerror(e) {
    console.debug("Error occured: " + e);
  },

  isOpen(){
    return this.incoming_ws.readyState === this.incoming_ws.OPEN
  }
}


export default websocket
