<template>
  <div class="order-timeline-container">
    <sticky-header title="Order">
      <template slot="icon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24"
          width="24"
          viewBox="0 0 512 512"
        >
          <title>Order</title>
          <path
            fill="none"
            stroke="currentColor"
            stroke-linejoin="round"
            stroke-width="32"
            d="M160 336V48l32 16 32-16 31.94 16 32.37-16L320 64l31.79-16 31.93 16L416 48l32.01 16L480 48v224"
          />
          <path
            d="M480 272v112a80 80 0 01-80 80h0a80 80 0 01-80-80v-48H48a15.86 15.86 0 00-16 16c0 64 6.74 112 80 112h288"
            fill="none"
            stroke="currentColor"
            stroke-linejoin="round"
            stroke-width="32"
          />
          <path
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="32"
            d="M224 144h192M288 224h128"
          />
        </svg>
      </template>
      <template slot="dropdown">
        <order-actions :order="order"></order-actions>
      </template>
      <template slot="progress">
        <order-progress
          :acknowledged-done="order.is_acknowledged_on_marketplace"
          :invoiced-done="order.is_invoiced_on_marketplace"
          :submit-tracking-done="order.is_submit_tracking_on_marketplace"
        ></order-progress>
      </template>
    </sticky-header>

    <tabs>
      <tab name="Order Lines" :selected="true">
        <efsane-table
          :data="lines"
          :default-columns="columns"
          table-name="order-detail-lines"
        >
          <template slot="__dimensions" slot-scope="slotData">
            <span
              >{{ slotData.slotScope.length }}x{{ slotData.slotScope.width }}x{{
                slotData.slotScope.depth
              }}</span
            >
          </template>
          <template slot="__item" slot-scope="slotData">
            <span v-if="slotData.slotScope.item">{{
              slotData.slotScope.item.sku || ""
            }}</span>
            <button
              v-else
              @click.stop="createItem(slotData.slotScope)"
              class="create-item-button"
            >
              Create Item
            </button>
          </template>
        </efsane-table>
      </tab>
      <tab :name="'Order Details (' + order.po_number + ')'">
        <OrderDetails :order="order" :orderSelectData="orderSelectData" />
      </tab>
    </tabs>


    <item-page v-if="showItemDetail" :create-with-sku="createItemSku" :show.sync="showItemDetail" process-type="create"></item-page>

  </div>
</template>

<script>
import OrderProgress from "@/components/pg-order-detail/partials/order-progress";
import StickyHeader from "@/components/pg-order-detail/partials/sticky-header";
import OrderDetails from "@/components/pg-order-detail/partials/order-details";
import OrderActions from "@/components/pg-order-detail/partials/order-actions";
import ItemPage from "@/views/items/item/Index.vue";

export default {
  name: "order-timeline",
  components: {
    StickyHeader,
    OrderProgress,
    OrderDetails,
    OrderActions,
    ItemPage
  },
  props: {
    order: Object,
    orderSelectData: {
      type: Function,
      default: () => {},
    },
    lines: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      showItemDetail: false,
      createItemSku: null,
      itemProcess: "add",
      itemId: null,
      columns: [
        {
          type: "row_number",
          header: "Line",
          size: "50",
        },
        {
          type: "data",
          header: "SKU",
          name: "sku",
          copyable: true,
          size: "2fr",
        },
        {
          type: "data",
          header: "Quantity",
          name: "quantity",
          size: "1fr",
        },
        {
          type: "data",
          header: "Pack Group",
          copyable: true,
          name: "pack_group_name",
          size: "1fr",
        },
        {
          type: "slot",
          header: "Dimensions(LxWxH)",
          name: "__dimensions",
          size: "1fr",
        },
        {
          type: "data",
          header: "Weight",
          name: "weight",
          size: "1fr",
        },
        {
          type: "data",
          header: "Unit Cost",
          name: "unit_cost",
          size: "1fr",
        },
        {
          type: "slot",
          header: "Item",
          name: "__item",
          size: "1fr",
        },
      ],
    };
  },
  methods: {
    changeItem() {
      this.orderSelectData();
    },
    createItem(item) {
      this.createItemSku = item?.sku;
      this.showItemDetail = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.order-timeline-container {
  padding-bottom: 15px;
  background-color: #fdfdfd;
  padding-block: 60px;
  padding-inline: 30px;
  scroll-margin-top: 60px;
  margin-inline: 50px;
  border: 1px dashed rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin-bottom: 50px;
}
.create-item-button {
  border-radius: 5px;
  background-color: #308c16;
  height: 26px;
  font-size: 12px;
  width: max-content;
  padding: 2px 5px;
  color: #fff;
}
.order-actions-container{
  margin-inline: 50px;
  display: flex;
  padding-block: 10px;
  gap: 10px;
  margin-top: -10px;
}
</style>
