<template>
<div class="pt-5 flex flex-col gap-3 justify-center items-center">
  <h3 class="text-sm text-gray-600">Not Found Transaction</h3>
  <div v-for="(history, key) in inventoryHistory" :key="key" class="h-fit border border-1 border-gray-200 border-solid gap-1 w-80 bg-white w-full rounded-lg shadow flex flex-col select-none justify-center items-center ">
    <div class="flex justify-between w-full text-xs px-5 py-1">
      <span class="font-semibold">Date:</span>
      <span class="text-sm">{{ history.created | formatDate }}</span>
    </div>
    <div class="flex justify-between w-full text-xs px-5 py-1 bg-gray-50">
      <span class="font-semibold">Quantity:</span>
      <span class="text-sm">{{ history.quantity }}</span>
    </div>
    <div class="flex justify-between w-full text-xs px-5 py-1">
      <span class="font-semibold">Post Processing Amount:</span>
      <span class="text-sm">{{ history.total_quantity }}</span>
    </div>
    <div class="flex justify-between w-full text-xs px-5 py-1 bg-gray-50">
      <span class="font-semibold">Affecting Item:</span>
      <span class="text-sm">{{ history.affecting_item_sku }}</span>
    </div>
    <div class="flex justify-between w-full text-xs px-5 py-1">
      <span class="font-semibold">Order:</span>
      <span class="text-sm">{{ history.po_number }}</span>
    </div>
    <div class="flex justify-between w-full text-xs px-5 py-1 bg-gray-50">
      <span class="font-semibold">Process Type:</span>
      <span class="text-sm">{{ history.process_type }}</span>
    </div>
  </div>
</div>
</template>
<script>
export default {
  name: "DefaultWarehouseInventoryHistory",
  props: {
    warehouseInventoryData: Object
  },
  data(){
    return {
      inventoryHistory: null
    }
  },
  created() {
    this.getHistory()
  },
  methods: {
    getHistory(key) {
      this.$store
        .dispatch("inventory/fetchInventoryHistory", {
          warehouse_id: this.warehouseInventoryData.warehouse_id,
          item_id: this.warehouseInventoryData.itemId,
        })
        .then((response) => {
          this.inventoryHistory = response.data;
        })
    }
  },
  watch: {
    warehouseInventoryData(){
      this.getHistory()
    }
  }
}
</script>

<style scoped>

</style>
