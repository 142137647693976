import state from "./state"

export const getSelectMarketplace=(state)=>{ return state.selectMarketplace}

export const getMarketplace=(state)=>{ return state.marketplaces}

export const getInvoiceData=(state)=>{ return state.invoiceData}

export const getStoreData=(state)=>{ return state.storeData}

export const getCompanyData=(state)=>{ return state.companyData}

export const getCarrierData=(state)=>{ return state.carrierData}

export const getQuantityData=(state)=>{ return state.quantityData}

export const getPaymentValues=(state)=>{ return state.paymentValues}

export const getChangeBadge=(state)=>{ return state.badge}

export const getQTYFile=(state)=>{ return state.qty_file}

export const getEditAction=(state)=>{ return state.editAction}















