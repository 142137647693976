<template>
  <img class="soludio-image" v-if="name in imageNames" :src="imageNames[name].imageSrc" v-bind="$attrs" />
</template>

<script>
import imageNames from "@/components/soludio-image/image-names";

export default {
  name: "soludio-icon",
  data() {
    return {
      imageNames: imageNames,
    };
  },
  props: {
    name: String,
  }
};
</script>

<style scoped></style>
