<template>
  <div class="left-side-container">
    <div class="before-order" >
      <abbr class="before-po-number" @click="selectOrder(beforeOrder)" :title="'Previous Order '+beforeOrder.po_number"  v-if="beforeOrder">
        <span><svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 512 512"><title>Previous Order {{beforeOrder.po_number}}</title><path d="M240 424v-96c116.4 0 159.39 33.76 208 96 0-119.23-39.57-240-208-240V88L64 256z" fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="32"/></svg></span>
        <span>{{ beforeOrder.po_number }}</span>
      </abbr>
    </div>
    <div class="space-div"></div>
    <aside class="main-menu" title="Order" >
      <abbr v-if="order.error" class="menu-item" title="Errors" @click="scrollView('.errors-timeline-container')">
        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 512 512"><title>Errors</title><path d="M85.57 446.25h340.86a32 32 0 0028.17-47.17L284.18 82.58c-12.09-22.44-44.27-22.44-56.36 0L57.4 399.08a32 32 0 0028.17 47.17z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/><path d="M250.26 195.39l5.74 122 5.73-121.95a5.74 5.74 0 00-5.79-6h0a5.74 5.74 0 00-5.68 5.95z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/><path d="M256 397.25a20 20 0 1120-20 20 20 0 01-20 20z"/></svg>
      </abbr>
      <div v-if="order.error" class="divider"></div>
      <abbr class="menu-item" title="Packages" @click="scrollView('.packages-timeline-container')">
        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 512 512"><title>Packages</title><path d="M448 341.37V170.61A32 32 0 00432.11 143l-152-88.46a47.94 47.94 0 00-48.24 0L79.89 143A32 32 0 0064 170.61v170.76A32 32 0 0079.89 369l152 88.46a48 48 0 0048.24 0l152-88.46A32 32 0 00448 341.37z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M69 153.99l187 110 187-110M256 463.99v-200"/></svg>
      </abbr>
      <div class="divider"></div>
      <abbr class="menu-item" @click="scrollView('.order-timeline-container')">
        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 512 512"><title>Order</title><path fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="32" d="M160 336V48l32 16 32-16 31.94 16 32.37-16L320 64l31.79-16 31.93 16L416 48l32.01 16L480 48v224"/><path d="M480 272v112a80 80 0 01-80 80h0a80 80 0 01-80-80v-48H48a15.86 15.86 0 00-16 16c0 64 6.74 112 80 112h288" fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="32"/><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M224 144h192M288 224h128"/></svg>
      </abbr>
      <div class="divider"></div>
      <abbr v-if="order.integrations.length" class="menu-item" title="Integrations" @click="scrollView('.integrations-timeline-container')">
        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 512 512"><title>Integrations</title><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M304 160l-64-64 64-64M207 352l64 64-64 64"/><circle cx="112" cy="96" r="48" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/><circle cx="400" cy="416" r="48" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/><path d="M256 96h84a60 60 0 0160 60v212M255 416h-84a60 60 0 01-60-60V144" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/></svg>
      </abbr>
      <div class="divider"></div>
      <abbr class="menu-item" title="Items" @click="scrollView('.items-timeline-container')">
        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 512 512"><title>Items</title><path fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="32" d="M336 320H32L184 48l152 272zM265.32 194.51A144 144 0 11192 320"/></svg>
      </abbr>
    </aside>
    <div class="space-div"></div>
    <div class="after-order">
      <abbr class="after-po-number" @click="selectOrder(afterOrder)"  :title="'Previous Order '+afterOrder.po_number" v-if="afterOrder">
        <span><svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 512 512"><title>Next Order {{afterOrder.po_number}}</title><path d="M448 256L272 88v96C103.57 184 64 304.77 64 424c48.61-62.24 91.6-96 208-96v96z" fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="32"/></svg></span>
        <span>{{ afterOrder.po_number }}</span>
      </abbr>
    </div>
  </div>
</template>

<script>
export default {
  name: "left-side",
  props:{
    order:Object,
    id:[Number,String],
    beforeOrder:Object,
    afterOrder:Object
  },
  data(){
    return{
    }
  },
  methods:{
    selectOrder(order){
      this.$emit('update:id',order.id)
    },
    scrollView(el){
      let currentElement = document.querySelector(el)
      currentElement.scrollIntoView({
        behavior:'smooth'
      })

    }
  }
}
</script>

<style lang="scss" scoped>

  .divider{
      height: 1px;
      background-color: rgba(0,0,0,.1);
      width: 30%;
      margin: auto;
  }
  .left-side-container{
    width: 100px;
    border-right: 1px solid rgba(0,0,0,.1);
    display: block;

    .before-order{
      height: 15%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      padding-top: 10px;
    }
    .space-div{
      height: 15%;
    }
    .main-menu{
      height: 40%;
      display: block;
      margin-left: auto;
      width: 70%;
      text-align: center;
      .menu-item{
        height: 20%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }
    .after-order{
      height: 15%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      padding-bottom: 10px;
    }


    .before-po-number, .after-po-number{
      color: #8d8d8d;
      margin-top: 0;
      display: block;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      padding: 5px;
      width: max-content;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      box-shadow: 0 3px 5px rgba(0,0,0,.1);
      cursor: pointer;
      font-weight: 600;
      font-size: .7em;
      user-select: none;
      letter-spacing: 1px;
      span{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
      }
      &:hover{
        background-color: #e5e5e4;
      }
    }
  }
</style>
