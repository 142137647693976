<template>
  <fieldset :id="elementId" class="efsane-fieldset" :style="styles">
    <slot></slot>
  </fieldset>
</template>

<script>
import Methods from "./methods"
export default {
  name: "efsane-fieldset",
  mixins:[Methods],
  props:{
    efsaneAttrs:Object,
    gridTemplateAreas:{
      type:String,
      default:""
    },
    fieldsetKey:{
      type:[String,Number],
      default:0
    }
  },
  data(){
    return{
      efsaneElements:[],
      elementId:this.makeId()
    }
  },
  computed:{
    styles(){
      return{
        "--efsane-fieldset-margin-top": this.setValidPixelValue([this.efsaneAttrs.marginTop, this.efsaneAttrs.margin]),
        "--efsane-fieldset-margin-left": this.setValidPixelValue([this.efsaneAttrs.marginLeft, this.efsaneAttrs.margin]),
        "--efsane-fieldset-margin-right": this.setValidPixelValue([this.efsaneAttrs.marginRight, this.efsaneAttrs.margin]),
        "--efsane-fieldset-margin-bottom": this.setValidPixelValue([this.efsaneAttrs.marginBottom, this.efsaneAttrs.margin]),
        "--efsane-fieldset-padding-top": this.efsaneAttrs.noBorder ? "none" : this.setValidPixelValue([this.efsaneAttrs.paddingTop, this.efsaneAttrs.padding, 10]),
        "--efsane-fieldset-padding-left": this.efsaneAttrs.noBorder ? "none" : this.setValidPixelValue([this.efsaneAttrs.paddingLeft, this.efsaneAttrs.padding, 10]),
        "--efsane-fieldset-padding-right": this.efsaneAttrs.noBorder ? "none" : this.setValidPixelValue([this.efsaneAttrs.paddingRight, this.efsaneAttrs.padding, 10]),
        "--efsane-fieldset-padding-bottom": this.efsaneAttrs.noBorder ? "none" : this.setValidPixelValue([this.efsaneAttrs.paddingBottom, this.efsaneAttrs.padding, 10]),
        "--efsane-fieldset-background-color": this.efsaneAttrs.color ? this.efsaneAttrs.color : this.efsaneAttrs.noBorder ? "transparent" : "rgba(245,245,245,.1)",
        "--efsane-fieldset-gap": this.setValidPixelValue([this.efsaneAttrs.gap, 10]),
        "--efsane-fieldset-border":this.efsaneAttrs.noBorder ? "none" : "1px solid rgba(0,0,0,.1)",
        [`--efsane-fielset-grid-template-${this.fieldsetKey}`]:this.gridTemplateAreas,
        "grid-template-areas":`var(--efsane-fielset-grid-template-${this.fieldsetKey})`,
        "order": this.efsaneAttrs.order
      }
    }
  },
  methods:{
    setValidPixelValue(values){
      if(!Array.isArray(values)){
        values = [values]
      }
      values.push(0)
      for (let i = 0; i < values.length; i++) {
        if(values[i]){
          return `${values[i]}px`
        }
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import "css/basic.scss";
</style>
