<template>
  <div class="order-actions-container">
    <pg-button-dropdown color="#fdfdfd" text-color="dark" border title="Select an action" width="180px">
      <pg-button click-lock dropdown-button @click.stop="acknowledgeOrder">Acknowledge order</pg-button>
      <pg-button click-lock dropdown-button @click.stop="submitTrackingNumber">Submit tracking</pg-button>
      <pg-button click-lock dropdown-button @click.stop="submitInvoice">Submit invoice</pg-button>
      <pg-button click-lock dropdown-button @click.stop="openCancelOrderDialogs">Cancel Full Order</pg-button>
      <pg-button v-if="isOttomanson" click-lock dropdown-button @click.stop="submitSameTrackingNumber">Submit Tracking With Same Tracking Number</pg-button>
    </pg-button-dropdown>
    <vs-prompt
      style="z-index: 55000;"
      title="Please Choose Cancel Reason"
      @accept="cancelOrder"
      accept-text="Apply"
      :active.sync="cancelReasonDialogVisibility"
    >
      <div class="flex justify-center">
          <efsane-form v-model="cancelReasonFormData">
            <select placeholder="Please Choose Cancel Reason" name="cancel_reason" label="Canceled Reason" :options="cancel_reasons" option-label="name" option-value="value" ></select>
          </efsane-form>
      </div>
    </vs-prompt>
  </div>
</template>

<script>
import watch from '@/components/efsane-table/watch';

export default {
  name: "order-actions",
  props:{
    order:Object,
    orderSelectData:{
      type:Function,
      default:()=>{}
    }
  },
  data(){
    return {
      cancelReasonFormData:{
        cancel_reason: "out_of_stock"
      },
      cancelReasonDialogVisibility:false,
      cancel_reasons: []
    }
  },
  mounted(){
    this.fetchCancelReasons();
  },
  
  computed:{
    isOttomanson(){
      return window.location.host.includes("ottomanson") || window.location.host.includes("beta") || window.location.host.includes("127") || window.location.host.includes("localhost")
    },
  },
  methods:{
    async acknowledgeOrder() {
      let obj = {ids:[this.order.id]};
      await this.$store
        .dispatch("orders/acknowledgedOrder", obj)
        .then(() => {
          this.$swal({
            icon: "success",
            title: "Acknowledge Order Request",
            text: "Succesfully Sent",
          }).then(()=>{
            this.orderSelectData()
          })
        })
        .catch((error) => {
          this.$swal({
            icon: "error",
            title: "Acknowledge Order Request",
            text: error,
          });
        });
    },
    async submitTrackingNumber() {
      let obj = {ids:[this.order.id]};
      await this.$store
        .dispatch("orders/submitTrackingNumber", obj)
        .then(() => {
          this.$swal({
            icon: "success",
            title: "Submit Tracking Number Request",
            text: "Succesfully Sent",
          }).then(()=>{
            this.orderSelectData()
          })
        })
        .catch((error) => {
          this.$swal({
            icon: "error",
            title: "Submit Tracking Number Request",
            text: error,
          });
        });
    },
    async submitSameTrackingNumber() {
      let obj = {ids:[this.order.id]};
      await this.$store
        .dispatch("orders/submitSameTrackingNumber", obj)
        .then(() => {
          this.$swal({
            icon: "success",
            title: "Submit Same Tracking Number Request",
            text: "Succesfully Sent",
          }).then(()=>{
            this.orderSelectData()
          })
        })
        .catch((error) => {
          this.$swal({
            icon: "error",
            title: "Submit Same Tracking Number Request",
            text: error,
          });
        });
    },
    async submitInvoice() {
      let obj = {ids:[this.order.id]};
      await this.$store
        .dispatch("orders/submitInvoice", obj)
        .then(() => {
          this.$swal({
            icon: "success",
            title: "Submit Invoice Request",
            text: "Succesfully Sent",
          }).then(()=>{
            this.orderSelectData()
          })
        })
        .catch((error) => {
          this.$swal({
            icon: "error",
            title: "Submit Invoice Request",
            text: error,
          });
        });
    },
    openCancelOrderDialogs(){
      this.$swal({
        title: "Confirm",
        icon: "warning",
        text: "This operation will cancel this order as well as delete it form all the available integrations if there are any?",
        buttons: {
          catch: {
            text: "Yes",
            value: true,
            className: "text-center",
          },
          cancel: "No",
        },
      })
        .then((value) => {
          if (value) {
            this.cancelReasonDialogVisibility = true
          }
        })

    },
    cancelOrder() {
      let obj = {order_id:this.order.id,
                  cancel_reason:this.cancelReasonFormData.cancel_reason};
      this.$store
        .dispatch("orders/cancelOrder", obj)
        .then(() => {
          this.$swal({
            icon: "success",
            title: "Cancel Order Request",
            text: "Cancel Order Request Succesfully Sent",
          }).then(()=>{
            this.orderSelectData();
          })
        })
        .catch((error) => {
          this.$swal({
            icon: "error",
            title: "Cancel Order Request",
            text: error,
          });
        });
    },
    async fetchCancelReasons() {
      let obj = {marketplace: this.order.marketplace};
      await this.$store
        .dispatch("orders/fetchCancelReasons", obj)
        .then((response) => {
          this.cancel_reasons = response.data.reasons;
        })
    },
  },
  watch:{
    cancel_reasons(){
      if (this.order.marketplace.store_apis_setting.store == 'target'){
        this.cancelReasonFormData.cancel_reason = 'ITEM_NOT_AVAILABLE'
      }
    }
  }
}
</script>


<style lang="scss" scoped>

.order-actions-container{
  margin-inline: 50px;
  display: flex;
  padding-block: 10px;
  gap: 10px;
}
</style>
