import axios from "@/axios";
import requestsHelper from "@/services/helpers/requestsHelper";

export default {
  addItem(ignore, item) {
    return new Promise((resolve, reject) => {
      axios
        .post("/item/pack_groups/", {
          name: item.name,
          quantity: item.quantity
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  fetchItems({ commit }, options) {
    let helpers = new requestsHelper(options);
    let queryset = helpers.createQueryset();

    return new Promise((resolve, reject) => {
      axios
        .get("/item/pack_groups/" + queryset)
        .then(response => {
          commit("SET_PACKGROUP", response.data.results);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  fetchSinglePackGroup({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get("/item/pack_groups/" + id + "/")
        .then(response => {
          commit("SET_EDIT_DATA", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  updateItem(ignore, item) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/item/pack_groups/` + item.id + "/", {
          name: item.name,
          quantity: item.quantity
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  removeItem(ignore, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/item/pack_groups/` + id + "/")
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },



};
