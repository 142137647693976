import axios from "axios";


const actions = {
  changePageVisibility({ commit }, value) {
    let status
    status = value !== 'hidden';
    commit("SET_PAGE_VISIBILITY", status);
  },
}

export default actions
