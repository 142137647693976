import axios from "@/axios";
import requestsHelper from "@/services/helpers/requestsHelper";

export default {


  addItem(ignore, item) {
    return new Promise((resolve, reject) => {
      axios
        .post("/item_inventory/adjustments/", item)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  fetchItems({ commit }, options) {
    let helpers = new requestsHelper(options);
    let queryset = helpers.createQueryset();

    return new Promise((resolve, reject) => {
      axios
        .get("/item_inventory/adjustments/" + queryset)
        .then(response => {
          commit("SET_ITEMS", response.data.results);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  fetchSelectData({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get("/item_inventory/adjustments/" + id + "/")
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  fetchLinesData(ignore, options) {
    let helpers = new requestsHelper(options);
    let queryset = helpers.createQueryset();
    return new Promise((resolve, reject) => {
      axios
        .get("/item_inventory/adjustment_lines/" +queryset)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  updateItem(ignore, item) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/item_inventory/adjustments/` + item.id + "/", item)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  adjustmentFileUpload(ignore, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/item_inventory/adjustments/adjustment_lines_bulk_upload/", payload, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  removeItem(ignore, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/item_inventory/adjustments/` + id + "/")
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }


};
