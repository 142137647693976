export default {
  directives:{
    clickOutsidePopup:{
      bind: function(el, binding) {
        const ourClickEventHandler = event => {
          if (!el.contains(event.target) && el !== event.target &&
            !event.target.classList.contains('table-detail-icon') &&
            !event.target.closest(".swal-overlay") &&
            !event.target.closest(".vs-component") &&
            !event.target.classList.contains('vs-popup--close--icon')){
            binding.value(event);
          }
        };
        el.__vueClickEventHandler__ = ourClickEventHandler;

        document.addEventListener("click", ourClickEventHandler);
      },
      unbind: function(el) {
        document.removeEventListener("click", el.__vueClickEventHandler__);
      }
    }
  },
}
