export default {
  SET_ORDERS(state, payload) {
    state.orders = payload
  },
  SET_SELECT_ORDER(state, payload) {
    state.selectOrder = payload
  },
  SET_LOG_DATA(state, payload) {
    state.log_details = payload
  },
  CHANGE_SELECT_ALL(state, payload) {
    state.isAllSelected = payload
  },
  PARTIAL_UPDATE_ORDER(state, payload) {
    state.selectOrder = payload;
  }
};
