<template>
  <div class="filter-box"  :style="cssVariables" v-click-outside="close">
      <div class="filter-content-box" @click="show=!show">
             <span class="filter-label">{{showSelected || label}}</span>
             <span class="filter-content-arrow" >
                <thin-arrow  :height="'12px'" :color="'#6E6B7B'" :rotate="true"></thin-arrow>
             </span>
      </div>
      <div class="filter-box-dropdown">
          <dropdown :value="value" :dropdown-show="dropdownShow" :dropdown-value="dropdownValue" :data="options" v-if="show" @input="modelChange"></dropdown>
      </div>
  </div>
</template>

<script>
import ThinArrow from "./svg/thin-arrow.vue";
import Dropdown from "./dropdown.vue"
export default {
    components:{
        ThinArrow,
        Dropdown
    },
    props:{
        value:{},
        dropdownShow:{
          type:String,
          default:"name"
        },
        dropdownValue:{
          type:String,
          default:"value"
        },
        label:{
          type:String,
          default:"Title"
        },
        options: {
          type:Array,
          default:function (){
            return []
          }
        }
    },
    data(){
        return{
            showSelected:"",
            show:false
        }
    },
    computed:{
        cssVariables(){
            return {

            }
        }
    },
    created(){
      let value = this.options.find(v => v[this.dropdownValue] == this.value)
      this.showSelected =value && value[this.dropdownShow] || ""
    },
    methods:{
        close(){
            this.show = false
        },
        modelChange(value){
            let selectValue = value && value[this.dropdownValue] || null
            this.showSelected =value && value[this.dropdownShow] || ""
            this.$emit('input', selectValue)
        }
    }
}
</script>

<style lang="scss" scoped>
.filter-box{
    position: relative;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-right: 8px;
    padding-bottom: 10px;
    cursor: pointer;
}

.filter-content-box{
    width: 200px;
    display: flex;
    justify-content: flex-start;
    padding-left: 15px !important;
    height: 33px;
    overflow: hidden;
    padding-right: 5px;
    border: 1px solid #d4d4d4;
    border-radius: 5px;
    position: relative;
    background: #fff;
    box-shadow: 0 3px 6px 0 rgba(214, 217, 218, 0.4);
    &:hover,
    &:focus {
      border-color: rgba(0,0,0,0.4);
    }
    }

.filter-label{
    display: inherit;
    color: #6E6B7B;
    font-weight: 500;
    line-height: 35px;
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
    margin-bottom: 0;
}

.filter-content-title{
    display: none;
    color: #B9B9C3;
    font-weight: 500;
    line-height: .65rem;
    padding-top: 5px;
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
    margin-bottom: 0;
}

.filter-select-values{
    font-weight: 600;
    font-size: .9rem;
    color: #6E6B7B;
    line-height: 15px;
    padding: 0;
    margin-bottom: 4px;
    margin-top: 10px;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 60px;
}

.filter-content-arrow{
        display: flex;
        justify-content: center;
        align-items: center;
        background: #fff;
        padding: 10px 10px 15px 10px;
        margin-left: auto;
        svg{
            margin-top: 50%;
        }
    }

.filter-box-dropdown{
        position: absolute;
        z-index: 4;
        min-width: 250px;
        display: block;
        left: 0;
        top: 37px;
        background-color: #fff;
        border-radius:  0 0 .35rem .35rem;
        box-shadow: 0 3px 7px 0 rgba(214, 217, 218, 0.9);
        animation: topslide 0.3s ease-in-out;

        @keyframes topslide {
                0%{
                    transform: translateY(25px);
                }
                100%{
                    transform: translateY(2px);
                }
            }
    }

</style>
