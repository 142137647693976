export default {
  computed:{
    lazyLoad(){
      return (this.id)
    },
    beforeOrder(){
      let indexCurrentOrder = this.orders.findIndex(v => v.id === this.value)
      let beforeIndex = indexCurrentOrder > 0 ? indexCurrentOrder - 1 : -1
      if(beforeIndex < 0) return null
      return this.orders[beforeIndex]
    },
    afterOrder(){
      let indexCurrentOrder = this.orders.findIndex(v => v.id === this.value)
      let afterIndex = indexCurrentOrder + 1 ===  this.orders.length ? -1 : indexCurrentOrder + 1
      if(afterIndex < 0) return null
      return this.orders[afterIndex]
    }
  }
}
