<template>
  <div class="filter-box"  :style="cssVariables" v-click-outside="close">
    <label class="filter-content-box" @click="show=!show" for="id">
      <input type="search" class="filter-search-input" :value="value" @keyup.enter="filterAction" @input="modelChange" id="id" :placeholder="label">
      <span class="filter-search-icon" @click="filterAction">
                <search-icon  height="18" width="18" color="#6E6B7B"> </search-icon>
             </span>
    </label>
  </div>
</template>

<script>
import SearchIcon from "./svg/search.vue";
export default {
  components:{
    SearchIcon
  },
  props:{
    value:{},
    dropdownShow:{
      type:String,
      default:"name"
    },
    filterAction:{
      default: function (){console.log("Applied")},
      type:Function
    },
    dropdownValue:{
      type:String,
      default:"value"
    },
    label:{
      type:String,
      default:"Title"
    },
    data: {
      type:Array,
      default:function (){
        return []
      }
    }
  },
  data(){
    return{
      showSelected:"",
      show:false,
      id:this.makeId(7)
    }
  },
  computed:{
    cssVariables(){
      return {

      }
    }
  },
  methods:{
    close(){
      this.show = false
    },
    makeId(length) {
      let result           = '';
      let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let charactersLength = characters.length;
      for ( let i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() *
          charactersLength));
      }
      return result;
    },
    modelChange(event){
      this.$emit('input', event.target.value)
      if(!event.target.value){
        this.filterAction()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.filter-box{
  position: relative;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 8px;
  padding-bottom: 10px;
  cursor: pointer;
}

.filter-content-box{
  width: var(--filter-width); //bu ayar pg-filters-containers'dan geliyor
  display: flex;
  justify-content: flex-start;
  padding-left: 15px !important;
  height: 33px;
  overflow: hidden;
  padding-right: 5px;
  border: 1px solid #d4d4d4;
  color: #6E6B7B;
  font-weight: 500;
  font-size: 14px;
  border-radius: 5px;
  position: relative;
  background: #fff;
  box-shadow: 0 3px 6px 0 rgba(214, 217, 218, 0.4);
  &:hover,
  &:focus {
    border-color: rgba(0,0,0,0.4);
  }
}

.filter-label{
  display: inherit;
  color: #6E6B7B;
  font-weight: 500;
  line-height: 35px;
  margin-bottom: 0;
}

.filter-content-title{
  display: none;
  color: #B9B9C3;
  font-weight: 500;
  line-height: .65rem;
  padding-top: 5px;
  margin-bottom: 0;
}

.filter-select-values{
  font-weight: 600;
  font-size: .9rem;
  color: #6E6B7B;
  line-height: 15px;
  padding: 0;
  margin-bottom: 4px;
  margin-top: 10px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 60px;
}

.filter-search-input{
  outline: none;
  border: none;
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  color: #6E6B7B;
  font-weight: 500;
  font-size: 14px;
}

.filter-search-icon{
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  padding: 10px 10px 15px 10px;
  cursor: pointer;
  margin-left: auto;
  svg{
    margin-top: 50%;
  }
}

</style>
