<template>
  <button :style="cssVariables" class="pg-action-button" data-cy="add-new" @click="actionBtn" :class="{'disabled':button.disabled}">      
        <vs-icon :icon="button.icon" ></vs-icon>
        <span>{{button.name}}</span>
  </button>
</template>

<script>
export default {
    props:{
        button:Object
    },
    computed:{
        cssVariables(){
            return{
                "--action-button-bg-color": this.button.bgcolor ? this.button.bgcolor : "#fff",
                "--action-button-color": this.button.color ? this.button.color : "#000"
            }
        }
    },
    methods:{
        actionBtn(event){
            if(!this.button.disabled){
                let x = event.clientX
                let y = event.clientY
                
                let ripples = document.createElement("span")
                ripples.classList.add("click-animate")

                if(window.innerWidth > 1200){
                    ripples.style.left = (x-260) + "px"
                    ripples.style.top = (y-85) + "px"
                }else{
                    ripples.style.left = x + "px"
                    ripples.style.top = (y-85) + "px"
                }

                event.target.appendChild(ripples)


                setTimeout(() => {
                    ripples.remove()
                }, 950); 
                
                this.$emit('process',this.button.value && this.button.value || 0)  

                this.button.disabled = true
                    setTimeout(() => {
                        this.button.disabled = false
                    }, 2000);
          }
        },
    }
}
</script>

<style lang="scss" >
@import url("https://fonts.googleapis.com/css?family=Poppins:400,500,600,700,800,900&display=swap");

    .pg-action-button{  
        z-index: 1;
        font-size: 1rem;
        display: inline-flex;
        font-family: 'Poppins','sans serif';
        font-weight: 200;
        border-radius: 3px;
        box-shadow: 0 0px 3px 0 rgba(0, 0, 0, 0.25);
        margin: 2px;
        height: 35px;
        overflow: hidden;
        justify-content: space-evenly;
        background-color: var(--action-button-bg-color);
        color: var(--action-button-color);
        align-items: center;
        position: relative;
        outline: none;
        border: none;
        -webkit-transition: all 0.5s;
        -moz-transition: all 0.5s;
        transition: all 0.5s;
        cursor: pointer;
        min-width: 150px;
        padding: 0 10px;
        &.disabled{
            opacity: .5;
            i{  
              opacity: .5;
            }
        }
        span{
          display: inline-flex;
        }
        i{
          display: inline-flex;
          font-size: 18px;
          width: auto;
          padding-right: 10px;
        }

        &:hover{
           box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.45);
           }
      }

      .click-animate{
        position: absolute;
        background:#000;
        transform: translate(-50%,-50%);
        pointer-events: none;
        border-radius: 50%;
        z-index: 99999;
        animation: clickanimate 1s linear infinite;  
        }
        @keyframes clickanimate{
        0%{
            width: 0px;
            height: 0px;
            opacity: .3;
        }
        100%{
            width: 1500px;
            height: 1500px;
            opacity: .1;
        }
        }
</style>