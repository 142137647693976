<template>
  <div>
    <div>
      <span
        class="p-4 block cursor-pointer no-underline text-white border-b-2 border-white flex justify-between"
        @click.prevent="active = !active"
      >
        <div class="flex justift-start">
          <vs-icon :icon="icon" size="small"></vs-icon>
          <strong class="ml-2">{{ title }}</strong>
        </div>
        <span v-if="showError" class="ml-auto flex">
          <vs-icon :icon="'feedback'" size="24px" color="warning"></vs-icon>
          <span class="text-warning ml-2 mr-4" style="font-weight:600;">{{errorHandleData}} field(s) in the form are missing or wrong</span>
        </span>
        <span class="flex">
          <span class="ml-auto inline-flex"> <slot name="default-carrier"></slot> </span>
          <span class="down-Arrow" v-show="!active">&#9660;</span>
          <span class="up-Arrow" v-show="active">&#9650;</span>
        </span>
      </span>
    </div>
    <div class="bg-white mb-2" v-show="active">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: ["title","icon","open","errorHandleData"],
  data() {
    return {
      active: false,
      showError:false
    };
  },
  created(){
    if(this.open){
      this.active = true
    }

    setTimeout(() => {
        if(this.errorHandleData){
          this.showError = true
        }
    }, 1500);
  },
  watch:{
    errorHandleData(newV){
      newV ? this.showError = true : this.showError = false
    }
  }
};
</script>
