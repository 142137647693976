<template>
  <div @click="$emit('click')"
    class="h-20 w-80 bg-white w-full rounded shadow flex select-none justify-evenly items-center  cursor-pointer">
    <div class="w-2/5 flex flex-col items-center justify-center truncate gap-2">

      <div class="w-1/5 flex items-center justify-center">
        <soludio-image :name="marketplaceInfo.store" class="h-8 w-8"></soludio-image>
      </div>
      <span class="font-middle w-24 text-xs text-center">{{ marketplaceInfo.marketplace_name | title }}</span>
    </div>
    <div class="w-2/5 flex flex-col items-center justify-center" >
      <div v-if="elapsedText(marketplaceInfo.last_sent_quantity_date) !== 'Never updated'"><span class="font-semibold px-1">{{ marketplaceInfo.last_sent_quantity }}</span> <span class="text-xs">QTY</span></div>

      <div class="text-xs">
        {{ elapsedText(marketplaceInfo.last_sent_quantity_date) }}

      </div>

    </div>
    <pg-icon class="arrow-up opacity-50 mr-2" style="transform: rotate(90deg);" name="arrow-up" height="18"></pg-icon>

  </div>

</template>

<script>
export default {
  name: "MarketplaceInfo",
  data() {
    return {
      marketplaceInfo: {
        id: null,
        sku: null,
        marketplace_id: null,
        manufacturer_sku: null,
        marketplace_name: null,
        store: null,
        upc: null,
        threshold: null,
        type: null,
        last_sent_quantity: null,
        last_sent_quantity_date: null
      }
    }
  },
  props: {
    marketplaceInfoData: Object
  },
  mounted() {
    this.setData()
  },
  methods: {
    setData(){
      if(!this.marketplaceInfoData) return
      Object.entries(this.marketplaceInfo).forEach(([key, value]) => {
        this.marketplaceInfo[key] = this.marketplaceInfoData[key]
      })
    },
    elapsedText(dateValue){
      if(!dateValue) return "Never updated"
      const date = new Date(dateValue);

      const now = new Date();
      const elapsedMilliseconds = now.getTime() - date.getTime();
      const elapsedSeconds = Math.floor(elapsedMilliseconds / 1000);

      let elapsedTimeText = "";
      if (elapsedSeconds < 60) {
        elapsedTimeText = `${elapsedSeconds} seconds ago`;
      } else if (elapsedSeconds < 60 * 60) {
        const minutes = Math.floor(elapsedSeconds / 60);
        elapsedTimeText = `${minutes} ${minutes === 1 ? 'minute' : 'minutes'} ago`;
      } else if (elapsedSeconds < 60 * 60 * 24) {
        const hours = Math.floor(elapsedSeconds / 60 / 60);
        elapsedTimeText = `${hours} ${hours === 1 ? 'hour' : 'hours'} ago`;
      } else if (elapsedSeconds < 60 * 60 * 24 * 7) {
        const days = Math.floor(elapsedSeconds / 60 / 60 / 24);
        elapsedTimeText = `${days} ${days === 1 ? 'day' : 'days'} ago`;
      } else {
        const weeks = Math.floor(elapsedSeconds / 60 / 60 / 24 / 7);
        elapsedTimeText = `${weeks} ${weeks === 1 ? 'week' : 'weeks'} ago`;
      }
      return elapsedTimeText
    },
    getItemData() {
      if (!this.itemId) return
      this.$store
        .dispatch("items/fetchSelectData", this.itemId)
        .then((response) => {
        })
    },
  }
}
</script>

<style scoped>

</style>

