<template>
  <div class="pg-input-container" :style="cssVariables">
      <label :for="id" v-if="label">
          <span  >
              {{label}}
          </span>
            <vs-tooltip
                v-if="required"
                class="cursor-pointer label-tooltip"
                text="This Field Required">
                <vs-icon icon="add_moderator" size="16px" :color="requiredColor"></vs-icon>
        </vs-tooltip>

      </label>
      <div class="pg-inputs-container">
             <input
                class="pg-select -select -background-select"
                :disabled="disabled"
                :ref="id"
                :placeholder="placeholder"
                :list="'search'+id"
                :id="'list'+id"
                :value="value && value.toString()"
                @focus="focusWarning = true; isFocus=true"
                @input="(e)=>{$emit('input',e.target.value)}"
                v-click-outside="()=>{focusWarning = false}"
          />
          <datalist :id="'search'+id">
            <slot name="firstOption"></slot>
            <slot name="secondOption"></slot>
            <option :data-value="setValue(item)" v-for="(item,key) in options" :key="key">{{labelProperty ? item[labelProperty] : item}}</option>
          </datalist>

           <input type="hidden" name="answer" :id="'list'+id+'-hidden'">
      </div>

      <span
        class="text-danger text-sm"
        v-show="errors.has(name)">
        {{ errors.first(name) }}
        </span>
  </div>
</template>

<script>
export default {
    props:{
        label:{
            type:String,
            default:""
        },
        name:{
            type:String,
            default:""
        },
        labelProperty:{
            type:String,
            default:""
        },
        valueProperty:{
            type:[String,Array],
            default:""
        },
        options:{
            type:Array,
            default:function(){
                return []
            }
        },
        placeholder:{
            type:String,
            default:""
        },
        height:{
            type:String,
            default:"36px"
        },
        disabled:{
            type:Boolean,
            default:false
        },
        value:{},
        validate:{
            type:String,
            default:""
        },
        required:{
            type:Boolean,
            default:false
        }
    },
    data(){
        return{
            id:this.$helper.makeId(10),
            focusWarning:false,
            isFocus:false
        }
    },
    computed:{
        requiredColor(){
            return !this.value && this.isFocus ? 'var(--pg-danger-color)' : this.focusWarning ? 'var(--pg-warning-color)' : '#7367F0'
        },
        validation(){
            return this.required && this.validate ? 'required|'+this.validate : this.required  ? 'required' : this.validate
        },
        errorHandle(){
            return (!this.value || this.errors.has(this.name))
        },
        cssVariables(){
            return{
                "--puffcity-input-height": this.height ? this.height : "36px"
            }
        },
    },
    methods:{
        setValue(value){
            if(this.valueProperty){
                if(Array.isArray(this.valueProperty)){
                    switch (this.valueProperty.length) {
                        case 1:
                            return value[this.valueProperty[0]].toString()
                        case 2:
                            return value[this.valueProperty[0]][this.valueProperty[1]].toString()
                        default:
                            return value;
                    }

                }
                return value[this.valueProperty].toString()
            }else{
                return value
            }
        },
        hiddenInputEvent(e){
             let input = e.target,
                    list = input.getAttribute('list'),
                    options = document.querySelectorAll('#' + list + ' option'),
                    hiddenInput = document.getElementById(input.getAttribute('id') + '-hidden'),
                    inputValue = input.value;
                hiddenInput.value = inputValue;
                for(var i = 0; i < options.length; i++) {
                    var option = options[i];

                    if(option.innerText === inputValue) {
                        hiddenInput.value = option.getAttribute('data-value');
                        break;
                    }
                }
        }
    },
    mounted(){
           this.validation && (this.errorHandle && this.$emit('error',true))
           setTimeout(() => {
               if(this.validation && !this.value){
                   this.$refs[this.id] && this.$refs[this.id].focus()
               }
           }, 1500);
           document.querySelector('input[list]').addEventListener('input', (e)=> {
               this.hiddenInputEvent(e)
            });

           document.querySelector('input[list]').addEventListener('focus', (e)=> {
               this.hiddenInputEvent(e)
            });
    },
    watch:{
        errorHandle(newV){
            this.validation && (newV ? this.$emit('error',true) : this.$emit('error',false))
        }
    }
}
</script>

<style lang="scss" scoped>
    @import url("https://fonts.googleapis.com/css?family=Poppins:400,500,600,700,800,900&display=swap");
    .pg-input-container{
        display: inline-block;
        outline: none;
        position: relative;
        text-transform: none;
        text-decoration: none;
        width: 100%;
        label{
            font-size: .85rem;
            color: rgba(0,0,0,.7);
            padding-left: .7rem;
        }
    }
    .pg-inputs-container{
        width: 100%;
        position: relative;
    }
    .pg-select{
        border-radius: 5px;
        z-index: 1;
        display: flex;
        height: var(--puffcity-input-height);
        transition: all 0.3s ease-in-out;
        font-size: .9rem;
        font-family: 'Poppins','Sans Serif';
        box-sizing: border-box;
        border: 1px solid rgba(0,0,0,0.2);
        transition: all .3s ease;
        background-color: #fff;
        padding-left: .7rem;
        width: 100%;

        &:hover, &:focus {

            box-shadow: 0px 10px 20px -13px rgba(0, 0, 0, 0.1);
        }
        &.-select {
            -webkit-appearance: none;
            background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz48c3ZnIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDUxMiA1MTIiIGlkPSJMYXllcl8xIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCA1MTIgNTEyIiB4bWw6c3BhY2U9InByZXNlcnZlIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48Zz48cGF0aCBkPSJNMjU2LDM5My42Yy0yLjgsMC01LjUtMS4xLTcuNi0zLjJMMywxMzYuM2MtNC00LjItMy45LTEwLjksMC4zLTE0LjljNC4yLTQsMTAuOS0zLjksMTQuOSwwLjNsMjQ1LjUsMjU0ICAgYzQsNC4yLDMuOSwxMC45LTAuMywxNC45QzI2MS4zLDM5Mi42LDI1OC42LDM5My42LDI1NiwzOTMuNnoiIGZpbGw9IiM2QTZFN0MiLz48cGF0aCBkPSJNMjU2LDM5My42Yy0yLjYsMC01LjMtMS03LjMtM2MtNC4yLTQtNC4zLTEwLjctMC4zLTE0LjlsMjQ1LjUtMjU0YzQtNC4yLDEwLjctNC4zLDE0LjktMC4zICAgYzQuMiw0LDQuMywxMC43LDAuMywxNC45bC0yNDUuNSwyNTRDMjYxLjUsMzkyLjUsMjU4LjgsMzkzLjYsMjU2LDM5My42eiIgZmlsbD0iIzZBNkU3QyIvPjwvZz48L3N2Zz4=');
            background-size: 18px;
            background-position: 95% center;
            background-repeat: no-repeat;
            padding-right: 5%;
        }


    }

    input::-webkit-calendar-picker-indicator {
        display: none !important;
    }

</style>
