<template>
  <div @click="clickItem" class="h-20 w-80 bg-white w-full rounded select-none shadow flex justify-evenly items-center cursor-pointer">
    <div class="w-2/5 text-xs flex flex-col items-center justify-center">
      <div>L:<span class="font-semibold px-1">{{ item.length }}
                </span> H:<span class="font-semibold px-1">{{ item.height }}</span> W:<span class="font-semibold px-1">{{ item.width }}</span></div>
      <div>Weight:<span class="font-semibold px-1">{{ item.weight }}</span> </div>
    </div>
    <div class="w-1/5 flex items-center justify-center">
      <soludio-image name="package" class="h-12 w-12"></soludio-image>
    </div>
    <div class="w-2/5 flex flex-col items-center justify-center">
      <div class="font-semibold truncate">{{ item.sku }}</div>
      <div>
        {{ item.type }}
      </div>
    </div>
    <pg-icon class="arrow-up opacity-50 mr-2" style="transform: rotate(90deg);" name="arrow-up" height="18"></pg-icon>

  </div>

</template>

<script>
export default {
  name: "OriginalItem",
  data(){
    return {
      itemData:null,
      item: {
        length: null,
        width: null,
        height: null,
        weight: null,
        sku: null,
        type: null,
      }
    }
  },
  props: {
    itemId: [Number, String]
  },
  mounted() {
    this.getItemData()
  },
  methods: {
    clickItem(){
      this.$emit('click', this.itemData)
    },
    getItemData() {
      if(!this.itemId) return
      this.$store
        .dispatch("items/fetchSelectData", this.itemId)
        .then((response) => {
          this.itemData = response.data
          Object.entries(this.item).forEach(([key, value])=> {
            this.item[key] = response.data[key]
          })
        })
    },
  }
}
</script>

<style scoped>

</style>

