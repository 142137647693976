import axios from "@/axios";

export default {
  getSeller({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/seller/profile/show")
        .then(response => {
          commit("SET_SELLER_INFO", response.data.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  editSeller({ commit }, editData) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/seller/profile/update", editData)
        .then(response => {
          commit("UPDATE_SELLER", response.data.data);
          commit("UPDATE_USER_INFO", response.data.data, { root: true });
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  changePasswordAction({ commit }, passData) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/seller/profile/update/password", passData)
        .then(response => {
          if (response.data.status == "ok") {
            commit("CHANGE_PASSWORD_RESULT", "ok");
          } else {
            commit("CHANGE_PASSWORD_RESULT", response.data.data);
          }
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

};
