<template>
  <div class="search-box" @click="clickBar" v-click-outside="mouseoutFunc" :style="cssVariables" >
        <search-icon ></search-icon>
        <span class="search-content-box"  >
            <transition name="fade">
              <search-label v-if="!inputView"  :label="label"></search-label>
            </transition>            
            <transition name="fade">
                <span class="search-select-values" v-if="inputView">
                    <input type="text" placeholder="Search" class="search-input2" ref="searchInput" :value="value" @input="inputChg">
                </span>
            </transition>
        </span>
  </div>
</template>

<script>
import SearchIcon from "./search-icon"
import SearchLabel from "./search-label"
export default {
    components:{
        SearchIcon,
        SearchLabel
    },
    props:{
        label:{
            required:false,
            type:String
        },
        value:String
    },
    data(){
        return{
            inputView:false,
            term:this.value
        }
    },
    computed:{
        cssVariables(){
            return{
                "--search-width":this.inputView ? "100%" : "90%"
            }
        }
    },
    methods:{   
        clickBar(){
            this.inputView = true
            setTimeout(() => {
                    this.$refs.searchInput.focus()
            }, 500);
        },    
        mouseoutFunc(){
              this.term ? this.inputView=true : this.inputView=false
        } ,
        inputChg(e){
            this.term = e.target.value
            this.$emit('input',e.target.value)
        }
    }

}
</script>

<style lang="scss" scoped>
.search-box{
    position: relative;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 240px;
    margin-right: 10px;
    padding-bottom: 10px;
    cursor: pointer;
}
    .search-content-title{
        display: inherit;
        color: #B9B9C3;
        font-weight: 500;
        line-height: .65rem;
        padding-top: 5px;
        margin-bottom: 0;
    }
    .search-content-box{
        display: flex;
        justify-content: flex-start;
        padding-left: 5px;
        height: 33px;
        border-radius: 0 .35rem .35rem 0 ;
        position: relative;
        background: #fff;
        width: var(--search-width);
        box-shadow: 0 3px 6px 0 rgba(214, 217, 218, 0.4);
        transition: width .3s ease-out;
    }
.search-select-values{
    font-weight: 600;
    line-height: 15px;
    padding: 0;
    margin-bottom: 4px;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: width .3s ease-out;
    width: 100%;
    text-align: center;
}

.search-input2{
    padding-top: 10px;
    outline: none;
    border: none;
    font-weight: 500;
    font-size: .9rem;
    height: 100%;
    line-height: 35px;
    width: 98%;
}

</style>