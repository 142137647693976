import axios from "@/axios";
import requestsHelper from "@/services/helpers/requestsHelper";

export default {
  addItem(ignore, item) {
    return new Promise((resolve, reject) => {
      axios
        .post("/integration/integrations/", item)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  fetchIntegrations({ commit }, options) {
    let helpers = new requestsHelper(options);
    let queryset = helpers.createQueryset();
    return new Promise((resolve, reject) => {
      axios
        .get("/integration/integrations/" + queryset)
        .then((response) => {
          commit("SET_INTEGRATION_NAMES", response.data.results);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  fetchIntegrationQuantities(ignore, options) {
    let helpers = new requestsHelper(options);
    let queryset = helpers.createQueryset();
    return new Promise((resolve, reject) => {
      axios
        .get("/integration/integrations/fetch_integration_quantities/" + queryset)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  fetchSingleIntegration(ignore, id) {
    return new Promise((resolve, reject) => {
      axios
        .get("/integration/integrations/" + id)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  fetchCompanies({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/integration/integrations/types/")
        .then((response) => {
          commit("SET_INTEGRATION_COMPANIES", response.data.results);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  removeItem(ignore, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/integration/integrations/` + id + "/")
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  createIntegration(ignore, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/integration/integrations/", payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  updateIntegration(ignore, item) {
    return new Promise((resolve, reject) => {
      axios
        .put("/integration/integrations/" + item.id + "/", item)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
