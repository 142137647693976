<template>
  <div class="item-actions-container">
    <pg-button-dropdown color="#fdfdfd" text-color="dark" border title="Select an action" width="180px">
      <pg-button click-lock dropdown-button @click="openItemPopup">New create item in System</pg-button>
    </pg-button-dropdown>
    <vs-popup fullscreen color="info" :title="itemProcess === 'add' ? 'ADD ITEM' : 'ITEM DETAIL'" :active.sync="showItemDetail">
      <pg-item-detail v-if="showItemDetail" :process="itemProcess" @change="changeItem" :item-id="itemId"></pg-item-detail>
    </vs-popup>
  </div>

</template>

<script>
export default {
  name: "item-actions",
  props:{
    orderSelectData:{
      type:Function,
      default:()=>{}
    }
  },
  data(){
    return{
      showItemDetail:false,
      itemProcess:'add',
      itemId:null,
    }
  },
  methods:{
    changeItem(){
      this.orderSelectData()
    },
    openItemPopup(){
      this.showItemDetail = true
    }
  }
}
</script>

<style lang="scss" scoped>

.item-actions-container{
  margin-inline: 50px;
  display: flex;
  padding-block: 10px;
  gap: 10px;
}
</style>
