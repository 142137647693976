<template>
<figure @click="clickEvent">
  <img class="icon-style" :src="icons.find((icon)=> icon.name === name).base64" v-bind="$attrs">
</figure>
</template>

<script>
import {icons} from "./icons"
export default {
  name: "index.vue",
  data(){
    return {
      icons: icons
    }
  },
  props:{
    name:String
  },
  methods:{
    clickEvent(event){
      this.$emit('click', event)
    }
  }
}
</script>

<style lang="scss" scoped>
  figure{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .icon-style{
    cursor: pointer;
  }
</style>
