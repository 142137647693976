<template>
  <div class="pg-action" :style="cssVariables">
    <div class="pg-action-process" @click="dropView =!dropView" v-click-outside="closeEvent" >
          <span >{{selected && selected.name ? selected.name : "Select Action"}}</span>
          <vs-icon :icon="'expand_more'" size="small" class="expand-more mr-2"></vs-icon>          
          <table class="process-dropdown"   v-show="dropView" >
              <tr v-for="(subbtn,ind) in button.subbuttons" :key="ind" @click="getValue(ind,subbtn)" v-bind:class="{'select-tr':selected && subbtn.value == selected.value, 'disabled':subbtn.disabled}"> 
                  <td>{{subbtn.name}}</td>
              </tr>
          </table>
    </div>
</div>
  
</template>

<script>
export default {
    data(){
        return{
            checkboxList:[],
            tdKey:0,
            dropView:false,
            selected:null,
            actionDisabled:false
        }
    }, 
    created(){
         this.checkboxList = []
            this.button.subbuttons.map((item,key) => {
                this.checkboxList.push({id:key,...item})
            })
    },
    props:{
        button:Object,
        buttons:Array
    },
    computed:{
        cssVariables(){
            return{
                "--action-button-bg-color": this.button.bgcolor ? this.button.bgcolor : "#fff",
                "--action-button-color": this.button.color ? this.button.color : "#000"
            }
        }
    },
    methods:{
        closeEvent(){
          this.dropView = false
        },
        actionBtn(){
            if(!this.actionDisabled){
              this.$emit('process',this.selected && this.selected.value || 0)  

               this.actionDisabled = true
                    setTimeout(() => {
                        this.actionDisabled = false
                    }, 2000);
            }
        },
        getValue(subKey,button){    
            if(!button.disabled){
                this.checkboxList.map((itm)=>{
                            itm.active = false
                })
                this.checkboxList[subKey].active = true
                this.selected = this.checkboxList[subKey]
                        
                //this.$emit('change',this.selected && this.selected.value || 0)  
                this.$emit('process',this.selected && this.selected.value || 0)  
            }   
        },
        trushData(durum){
            
            if(durum){
            if(this.checkboxList && this.checkboxList.length>0){
                this.checkboxList.map((item)=>{
                            item.active = null
                })
            this.selected = null
            this.tdKey +=1
        }
        }
        }
    }
}
</script>

<style lang="scss" >

    @import url("https://fonts.googleapis.com/css?family=Poppins:400,500,600,700,800,900&display=swap");

    .pg-action{
        font-family: 'Poppins','sans serif';
        -webkit-transition: all 0.5s;
        -moz-transition: all 0.5s;
        transition: all 0.5s;
        margin: 2px;
        background-color: transparent;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        display: flex;
        align-items: center;
        height: 35px;
        cursor: pointer;
    }
    
    .pg-action-process{    
        display: inline-flex;
        background-color: var(--action-button-bg-color);
        color: var(--action-button-color);
        justify-content: center;
        box-shadow: 0 0px 3px 0 rgba(0, 0, 0, 0.25);
        position: relative;
        align-items: center;
        border-radius: 5px;
        min-width: 190px;
        z-index: 99;
        height: 100%;
        padding-left: 10px;
        i{
            margin-left: auto;
        }
    }
    
    .pg-action-process-button{  
        z-index: 99999;
        font-size: 1rem;
        display: inline-flex;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        height: 100%;
        width: 100%;
        overflow: hidden;
        justify-content: space-evenly;
        background-color: var(--action-button-bg-color);
        color: var(--action-button-color);
        align-items: center;
        position: relative;
        cursor: pointer;
        outline: none;
        border: none;
        min-width: 75px;

        span{
          display: inline-flex;
        }
        
        i{
          display: inline-flex;
          font-size: 18px;
          width: auto;
        }

        &:hover{
           box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.45);
           }
      }

      .process-dropdown {
            display: table;
            top: 38px;
            left: 0;
            z-index: 99;
            right: 0;
            position: absolute;
            min-width: 190px;
            min-height: 35px;
            text-align: center;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
            background-color: #fff;
            box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
            animation: topslide 0.3s ease-in-out;

            @keyframes topslide {
                0%{
                    transform: translateY(25px);
                }
                100%{
                    transform: translateY(2px);
                }
            }
    }


    .process-dropdown tr{
        height: 45px;
        color: 	#000;
    }

    .process-dropdown tr:hover{
        background-color: 	#F0F0F0;
    }

    .select-tr{
        background-color: 	#F0F0F0;
    }

    .disabled{
        opacity: .2;
    }

    .process-dropdown tr td:nth-child(1){
        padding-left: 10px;
    }
</style>