import axios from "@/axios";
export default {
  fetchJobs() {
    return new Promise((resolve, reject) => {
      axios
        .get("/integration/integrations/jobs/")
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchPrinterJobs() {
    return new Promise((resolve, reject) => {
      axios
        .get("/printer/label_folder_jobs/")
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};
