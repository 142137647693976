<template>
  <div class="w-full pg-input-tag" 
       v-click-outside-app="closeHandle">
      <label for="pg-real-input-tag"  class="text-sm ml-2 d-inline" >{{label}}</label>
        <vs-tooltip
            class="mt-2 cursor-pointer label-tooltip"
            text="You can enter multiple values, separated by commas."
        >
            <vs-icon
            class="p-0 m-0"
            icon="help_center"
            size="small"
        ></vs-icon>
        </vs-tooltip>
       <div class="w-full view-input"
        @click="focusFunc"        
      >
        <span class="tag-input-item-container">
                <span class="tag-input-item" v-for="(value,key) in selected" :key="key">
                    {{value}} 
                    <span class="tag-close" @click="deleteKey(key)">&times;</span> 
                </span>
            </span> 
     
      </div>
      
      <div v-if="inputView" class="w-full pg-input-tag-input">          
         <vs-input  id="pg-real-input-tag" v-model="newTag" class="w-full" @keyup.enter="submitTag" placeholder="Please Enter External SKU Reference"></vs-input>
         <vs-icon icon="loupe" class="tag-input-icon" @click="submitTag" color="success" size="small"></vs-icon>
      </div>
  </div>
</template>

<script>
export default {
    props:{
        label:{
            default:"",
            type:String
        },
        value:{
            default:"",
            type:String
        }
    },
    data(){
        return{
            selected:[],
            inputView:false,
            newTag:null
        }
    },
    created(){
        this.setArray()
    },
    methods:{
        closeHandle(){
            setTimeout(() => {
                if(this.inputView){
                   this.inputView = false
                }
            }, 50);
        },
        focusFunc(){
          this.inputView = true
          setTimeout(() => {
          document.getElementById("pg-real-input-tag").focus()              
          }, 25);
        },
        deleteKey(key){
            this.selected.splice(key,1)
        },
        submitTag(){
            if(!this.selected.includes(this.newTag) && this.newTag && this.newTag.trim() != ""){
                let splitArray = this.newTag.split(",")
                splitArray.map((item)=>{
                  this.selected.push(item.trim())
                })
            }
            this.newTag=null
        },
        setArray(){
            if(this.value){
               this.selected = this.value.split(',')
               this.$emit('input',this.selected.join(','))
            }else{
                setTimeout(() => {
                    if(this.value){
                        this.selected = this.value.split(',')
                        this.$emit('input',this.selected.join(','))
                    }
            }, 1500);
            }
        },
    },
    watch:{
        selected(){            
            this.$emit('input',this.selected.join(','))
        }
    },
      directives: {
        /* v-click-outside-app="closeEvent" */
        "click-outside-app": {
        bind: function(el, binding) {
            const ourClickEventHandler = (event) => {
                if (!el.contains(event.target) && el !== event.target && el !== event.target.previousSibling) {
                    binding.value(event);
                }
            };
            el.__vueClickEventHandler__ = ourClickEventHandler;

            document.addEventListener("click", ourClickEventHandler);
        },
        unbind: function(el) {
            document.removeEventListener("click", el.__vueClickEventHandler__);
        },
        },
  },
}
</script>

<style lang="scss">
    .pg-input-tag{
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        position: relative;
        width: 100%;
    }
    .pg-input-tag-input{
        position: relative;
        width: 100%;
    }
    .label-tooltip{
        display: inline-block;
    }
    .tag-input-icon{
        position: absolute;
        right: 10px;
        cursor: pointer;
        top: 5px;
    }
    .view-input{   
        padding: 8px;     
        width: 100%;
        min-height: 50px;
        border:1px solid rgba(0, 0, 0, 0.2);
        border-radius: 5px;
    }
    .tag-input-item-container{
        line-height: 35px;
        width: 90%;
    }
    .tag-input-item{
        border: 2px solid #f0f0f0;
        font-weight: 500;
        padding-left: 5px;
        font-size: 13px;
        padding-right: 5px;
        margin-bottom: 5px;
        border-radius: .35rem;
        margin-right: 5px;
        display: inline-block;
        
    }
    .tag-close{
            cursor: pointer;
            padding-top: 10px;
            font-size: 18px;
        }
</style>