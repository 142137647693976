<template>
  <label class="efsane-basic-search-select-container" :for="listId" v-select-click-outside="clickOutside">
    <span v-if="label" class="flex items-center gap-1 pl-1 mb-1 font-semibold"
    >{{ label }}
          <soludio-tooltip v-if="tooltip" :type="tooltipType" :tooltip="tooltip"></soludio-tooltip>

    </span>
    <div class="input-search-select-container">
      <input
        class="input-search-select"
        :data-cy="dataCy"
        @focusOut="close"
        :id="inputId"
        :disabled="disabled"
        type="search"
        :placeholder="placeholder"
        v-model="labelPlaceholder"
        @input="inputValue"
        @focus="open"
        @mousedown="open"
      />
      <ul
        :id="listId"
        :value="value"
        @input="
          (e) => {
            $emit('input', e.target.value);
          }
        "
        v-if="show"
      >
        <li
          :data-value="setValue(option)"
          :value="setLabel(option)"
          :class="{'disabled': isDisabled(setValue(option))}"
          v-for="(option, key) in options"
          @click.stop="select(option)"
        >
          {{ setLabel(option) }}
        </li>
        <li disabled v-if="!options.length">No Match</li>
      </ul>
    </div>
  </label>
</template>

<script>
import SoludioTooltip from "@/components/soludio-input/partials/SoludioTooltip.vue";
export default {
  name: "efsane-search-select",
  components: {
    SoludioTooltip,
  },
  data() {
    return {
      listId: this.$helper.makeId(),
      inputId: this.$helper.makeId(),
      containerId: this.$helper.makeId(),
      show: false,
      labelPlaceholder: this.value || "",
    };
  },
  props: {
    tooltip:String,
    tooltipType:String,
    optionLabel: String,
    optionValue: String,
    placeholder:String,
    id: String,
    label: String,
    dataCy: String,
    disabled:Boolean,
    value:{},
    inputFeatures: {
      type: [Array, String],
    },
    options: {
      type: [Array, String],
    },
    size: {
      type: String,
      default: "base",
      options: ["large", "base", "small"],
    },
  },
  mounted() {
    this.labelControl();
  },
  watch: {
    value: "labelControl",
    options: "labelControl",
  },
  methods: {
    labelControl() {
      let item = this.options.find((v) => this.setValue(v) === this.value);
      if (item) {
        this.labelPlaceholder = this.setLabel(item);
      }
    },
    inputValue(e) {
      this.show = true
      this.$emit("input", e.target.value);
    },
    open() {
      this.show = true;
    },
    clickOutside() {
      this.$emit('focusout',true )
      this.close()
    },
    isDisabled(setValue){
      return this.disabledValues?.split(',').includes(setValue.toString())
    },
    close() {
      this.show = false;
    },
    select(option) {
      if(this.isDisabled(this.setValue(option))) return
      this.show = false;
      this.labelPlaceholder = this.setLabel(option);
      this.$emit("input", this.setValue(option));
      this.$emit("change", this.setValue(option));
    },
    setLabel(data) {
      if (!this.optionLabel) return data;
      let optionLabel = this.optionLabel || "label";
      return data[optionLabel];
    },
    setValue(data) {
      if (!this.optionValue) return data;
      let optionValue = this.optionValue || "value";
      return data[optionValue];
    },
  },
  directives: {
    selectClickOutside: {
      bind: function (el, binding) {
        const ourClickEventHandler = (event) => {
          if (
            !el.contains(event.target) &&
            el !== event.target
          ) {
            binding.value(event);
          }
        };
        el.__vueClickEventHandler__ = ourClickEventHandler;

        document.addEventListener("click", ourClickEventHandler);
      },
      unbind: function (el) {
        document.removeEventListener("click", el.__vueClickEventHandler__);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../css/basic.scss";
</style>
