const mutations = {

  // /////////////////////////////////////////////
  // User/Account
  // /////////////////////////////////////////////

  // Updates user info in state and localstorage
  UPDATE_USER_INFO(state, payload) {
    // Get Data localStorage
    let userInfo = JSON.parse(localStorage.getItem("userInfo")) || state.AppActiveUser

    for (const property of Object.keys(payload)) {

      if (payload[property] != null) {
        // If some of user property is null - user default property defined in state.AppActiveUser
        if(property.toString().search("_") > -1){
          state.AppActiveUser[property.replace("_","")] = payload[property]
        }else{
          state.AppActiveUser[property] = payload[property]
        }

        // Update key in localStorage
        if(property.toString().search("_") > -1){
          userInfo[property.replace("_","")] = payload[property]
        }else{
          userInfo[property] = payload[property]
        }
      }
    }

    // Store data in localStorage
    localStorage.setItem("userInfo", JSON.stringify(userInfo))
  },
  SET_PAGE_VISIBILITY(state, payload) {
    state.pageVisibility = payload

  }
}

export default mutations

