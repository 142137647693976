import Vue from 'vue'
import App from './App.vue'
import Vuesax from 'vuesax' // Vuesax Component Framework
import VuePapaParse from 'vue-papa-parse' // csv parser
import urljoin from 'url-join'
import PgSpinner from "@/components/pg-spinners" // loading
import VueSwal from 'vue-swal' // sweet-alert
import VueClipboard from 'vue-clipboard2' // copy text
import axios from "./axios" // axios
import helper from "./helper" // helper
import router from './router' // Vue Router
import store from './store/store' // Vuex Store
import { VueHammer } from 'vue2-hammer' // Vuejs - Vue wrapper for hammerjs
import EfsaneTable from "efsane-table"
import VeeValidate from 'vee-validate' // VeeValidate
import './globalComponents.js' // Globally Registered Components
import './directives.js' // Directives
import './filters.js' // Filters

require('./styles') // Style Imports

Vue.use(Vuesax)
Vue.use(VuePapaParse)
Vue.use(PgSpinner)
Vue.use(VueSwal)
Vue.use(VueClipboard)
Vue.use(EfsaneTable)
Vue.use(VueHammer)
Vue.use(VeeValidate)

Vue.prototype.$http = axios
Vue.prototype.$urlJoin = urljoin
Vue.prototype.$helper = helper
Vue.prototype.$appName = 'My App'

Vue.prototype.getCountries = [
  { value: "US", name: "USA" },
]
Vue.prototype.getStates = [
    { id: "AL", name: "Alabama"},
    { id: "AK", name: "Alaska" },
    { id: "AZ", name: "Arizona" },
    { id: "AR", name: "Arkansas" },
    { id: "CA", name: "California" },
    { id: "CO", name: "Colorado" },
    { id: "CT", name: "Connecticut" },
    { id: "DE", name: "Delaware" },
    { id: "DC", name: "District of Columbia" },
    { id: "FL", name: "Florida" },
    { id: "GA", name: "Georgia" },
    { id: "ID", name: "Idaho" },
    { id: "IL", name: "Illinois" },
    { id: "IN", name: "Indiana" },
    { id: "IA", name: "Iowa" },
    { id: "KS", name: "Kansas" },
    { id: "KY", name: "Kentucky" },
    { id: "LA", name: "Louisiana" },
    { id: "ME", name: "Maine" },
    { id: "MD", name: "Maryland" },
    { id: "MA", name: "Massachusetts" },
    { id: "MI", name: "Michigan" },
    { id: "MN", name: "Minnesota" },
    { id: "MS", name: "Mississippi" },
    { id: "MO", name: "Missouri" },
    { id: "MT", name: "Montana" },
    { id: "NE", name: "Nebraska" },
    { id: "NV", name: "Nevada" },
    { id: "NH", name: "New Hampshire" },
    { id: "NJ", name: "New Jersey" },
    { id: "NM", name: "New Mexico" },
    { id: "NY", name: "New York" },
    { id: "NC", name: "North Carolina" },
    { id: "ND", name: "North Dakota" },
    { id: "OH", name: "Ohio" },
    { id: "OK", name: "Oklahoma" },
    { id: "OR", name: "Oregon" },
    { id: "PA", name: "Pennsylvania" },
    { id: "RI", name: "Rhode Island" },
    { id: "SC", name: "South Carolina" },
    { id: "SD", name: "South Dakota" },
    { id: "TN", name: "Tennessee" },
    { id: "TX", name: "Texas" },
    { id: "UT", name: "Utah" },
    { id: "VT", name: "Vermont" },
    { id: "VA", name: "Virginia" },
    { id: "WA", name: "Washington" },
    { id: "WV", name: "West Virginia" },
    { id: "WI", name: "Wisconsin" },
    { id: "WY", name: "Wyoming" },
]
Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
