import axios from "@/axios";
import requestsHelper from "@/services/helpers/requestsHelper";

export default {


  addItem(ignore, item) {
    return new Promise((resolve, reject) => {
      axios
        .post("/warehouse/warehouses/", {
          name: item.name,
          address1: item.address1,
          address2: item.address2,
          city: item.city,
          state: item.state,
          zipcode: item.zipcode,
          country: item.country,
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  fetchWarehouses({ commit }, options) {
    return new Promise((resolve, reject) => {
      let helpers = new requestsHelper(options);
      let queryset = helpers.createQueryset();

      axios
        .get("/warehouse/warehouses/" + queryset)
        .then(response => {
          commit("SET_WAREHOUSES", response.data.results);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  fetchSingleWarehouse({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get("/warehouse/warehouses/" + id + "/")
        .then(response => {
          commit("SET_EDIT_DATA", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  updateItem(ignore, item) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/warehouse/warehouses/` + item.id + "/", { name: item.name, address1: item.address1, address2: item.address2, city: item.city, state: item.state, zipcode: item.zipcode, country: item.country })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  removeItem(ignore, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/warehouse/warehouses/` + id + "/")
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }


};
