<template>  
    <span class="filter-icon-box2" >
        <feather-icon
                    icon="SearchIcon"
                    svgClasses="w-5 h-5 stroke-current  "
                />
    </span>
</template>

<script>
export default {
}
</script>

<style lang="scss">
    .filter-icon-box2{
        width: 39px;
        height: 33px;
        background-color: #fff;
        border-radius: .35rem 0 0 .35rem ;
        color: #6E6B7B ;
        display: flex;
        margin-left: 5px;
        margin-right: 1px;
        justify-content: center;
        align-items: center;
        box-shadow: 0 3px 6px 0 rgba(214, 217, 218, 0.4);
    }
</style>