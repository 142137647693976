export default {
  SET_SELLER_INFO(state, sellerInfo) {
    state.sellerInfo = sellerInfo;
  },
  UPDATE_SELLER(state, editInfo) {
    
    for (const property of Object.keys(editInfo)) {
      if (state.sellerInfo[property] != null) {
        state.sellerInfo[property] = editInfo[property];
      }

    }
  },
  CHANGE_PASSWORD_RESULT(state, CHANGE_PASSWORD_RESULT){
    state.change_password_result= CHANGE_PASSWORD_RESULT
  }

};
