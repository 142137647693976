<template>
  <div class="checkmark-wrapper">

  <div class="checkmark">

    <svg class="star" height="19" viewBox="0 0 19 19" width="19" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.296.747c.532-.972 1.393-.973 1.925 0l2.665 4.872 4.876 2.66c.974.532.975 1.393 0 1.926l-4.875 2.666-2.664 4.876c-.53.972-1.39.973-1.924 0l-2.664-4.876L.76 10.206c-.972-.532-.973-1.393 0-1.925l4.872-2.66L8.296.746z" >
      </path></svg>
    <svg class="star" height="19" viewBox="0 0 19 19" width="19" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.296.747c.532-.972 1.393-.973 1.925 0l2.665 4.872 4.876 2.66c.974.532.975 1.393 0 1.926l-4.875 2.666-2.664 4.876c-.53.972-1.39.973-1.924 0l-2.664-4.876L.76 10.206c-.972-.532-.973-1.393 0-1.925l4.872-2.66L8.296.746z" >
      </path></svg>
    <svg class="star" height="19" viewBox="0 0 19 19" width="19" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.296.747c.532-.972 1.393-.973 1.925 0l2.665 4.872 4.876 2.66c.974.532.975 1.393 0 1.926l-4.875 2.666-2.664 4.876c-.53.972-1.39.973-1.924 0l-2.664-4.876L.76 10.206c-.972-.532-.973-1.393 0-1.925l4.872-2.66L8.296.746z" >
      </path></svg>
    <svg class="star" height="19" viewBox="0 0 19 19" width="19" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.296.747c.532-.972 1.393-.973 1.925 0l2.665 4.872 4.876 2.66c.974.532.975 1.393 0 1.926l-4.875 2.666-2.664 4.876c-.53.972-1.39.973-1.924 0l-2.664-4.876L.76 10.206c-.972-.532-.973-1.393 0-1.925l4.872-2.66L8.296.746z" >
      </path></svg>
    <svg class="star" height="19" viewBox="0 0 19 19" width="19" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.296.747c.532-.972 1.393-.973 1.925 0l2.665 4.872 4.876 2.66c.974.532.975 1.393 0 1.926l-4.875 2.666-2.664 4.876c-.53.972-1.39.973-1.924 0l-2.664-4.876L.76 10.206c-.972-.532-.973-1.393 0-1.925l4.872-2.66L8.296.746z" >
      </path></svg>
    <svg class="star" height="19" viewBox="0 0 19 19" width="19" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.296.747c.532-.972 1.393-.973 1.925 0l2.665 4.872 4.876 2.66c.974.532.975 1.393 0 1.926l-4.875 2.666-2.664 4.876c-.53.972-1.39.973-1.924 0l-2.664-4.876L.76 10.206c-.972-.532-.973-1.393 0-1.925l4.872-2.66L8.296.746z" >
      </path></svg>
    <svg class="checkmark__check" height="12" viewBox="0 0 48 36" width="48" xmlns="http://www.w3.org/2000/svg">
      <title>Order address is verified</title>
      <path d="M47.248 3.9L43.906.667a2.428 2.428 0 0 0-3.344 0l-23.63 23.09-9.554-9.338a2.432 2.432 0 0 0-3.345 0L.692 17.654a2.236 2.236 0 0 0 .002 3.233l14.567 14.175c.926.894 2.42.894 3.342.01L47.248 7.128c.922-.89.922-2.34 0-3.23">
      </path></svg>
    <svg class="checkmark__background" height="39" viewBox="0 0 120 115" width="120" xmlns="http://www.w3.org/2000/svg">
      <title>Order address is verified</title>
      <path d="M107.332 72.938c-1.798 5.557 4.564 15.334 1.21 19.96-3.387 4.674-14.646 1.605-19.298 5.003-4.61 3.368-5.163 15.074-10.695 16.878-5.344 1.743-12.628-7.35-18.545-7.35-5.922 0-13.206 9.088-18.543 7.345-5.538-1.804-6.09-13.515-10.696-16.877-4.657-3.398-15.91-.334-19.297-5.002-3.356-4.627 3.006-14.404 1.208-19.962C10.93 67.576 0 63.442 0 57.5c0-5.943 10.93-10.076 12.668-15.438 1.798-5.557-4.564-15.334-1.21-19.96 3.387-4.674 14.646-1.605 19.298-5.003C35.366 13.73 35.92 2.025 41.45.22c5.344-1.743 12.628 7.35 18.545 7.35 5.922 0 13.206-9.088 18.543-7.345 5.538 1.804 6.09 13.515 10.696 16.877 4.657 3.398 15.91.334 19.297 5.002 3.356 4.627-3.006 14.404-1.208 19.962C109.07 47.424 120 51.562 120 57.5c0 5.943-10.93 10.076-12.668 15.438z" >
      </path></svg>
  </div>
    Address verified
  </div>
</template>

<script>
export default {
  name: "address-checkmark"
}
</script>

<style lang="scss">
:root {
  --address-checkmark-color-blue: #308C16;
  --address-checkmark-color-white: #fff;
  --address-checkmark-curve: cubic-bezier(0.420, 0.000, 0.275, 1.155);
}


.checkmark-wrapper {
  @mixin size($size) {
    width: $size;
    height: $size;
  }

  *, *:before, *:after {
    box-sizing: border-box;
  }

  height: max-content;
  display: flex;
  margin-top: 50px;
  color: var(--address-checkmark-color-blue);
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--address-checkmark-color-white);

  .star {
    position: absolute;
    animation: grow 3s infinite;
    fill: var(--address-checkmark-color-blue);
    opacity: 0;

    &:nth-child(1) {
      @include size(4px);
      left: 4px;
      top: 5px;
    }

    &:nth-child(2) {
      @include size(6px);
      left: 83px;
      top: 28px;
    }

    &:nth-child(3) {
      @include size(3px);
      left: 11px;
      top: 54px;
    }

    &:nth-child(4) {
      @include size(6px);
      left: 26px;
      top: -4px;
    }

    &:nth-child(5) {
      @include size(5px);
      left: 41px;
      top: 18px;
    }

    &:nth-child(6) {
      @include size(3px);
      left: 5px;
      top: 5px;
    }

    @for $i from 1 through 6 {
      &:nth-child(#{$i}) {
        animation-delay: $i*( 3.0s/2 );
      }
    }
  }

  .checkmark {
    position: relative;
    cursor: pointer;
    padding: 10px;
    animation: checkmark 5m var(--address-checkmark-curve) both;

    &__check {
      position: absolute;
      top: 48%;
      left: 50%;
      z-index: 10;
      transform: translate3d(-50%, -50%, 0);
      fill: var(--address-checkmark-color-white);
    }

    &__background {
      fill: var(--address-checkmark-color-blue);
      animation: rotate 35s linear both infinite;
    }
  }


  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes grow {
    0%, 100% {
      transform: scale(0);
      opacity: 0;
    }

    50% {
      transform: scale(1);
      opacity: 1;
    }
  }

  @keyframes checkmark {
    0%, 100% {
      opacity: 0;
      transform: scale(0);
    }

    10%, 50%, 90% {
      opacity: 1;
      transform: scale(1);
    }
  }
}


</style>
